import {type ApolloError} from '@apollo/client';
import {captureMessage, withScope} from '@sentry/nextjs';
import {type GraphQLError} from 'graphql';

import {type Maybe} from '@/graphql/types';
import {SIGN_IN_PATH} from '@/paths';
import {compilePath} from '@/utils';

export type GraphQLErrorWithStatusCode = GraphQLError & {
  statusCode?: number;
};

export type RedirectableError = {
  path: string;
  message?: string;
};

// NOTE: Anonymous users will come back as 401s so we may not be able to use the 401 status for Auth
const isAuthError = (statusCode: number | undefined): boolean => {
  return statusCode === 403;
};

const isUnknownError = (statusCode: number | undefined): boolean => {
  return statusCode === undefined || statusCode > 499;
};

export const extractErrorWithExtensionRedirects = (
  errorsList: readonly GraphQLErrorWithStatusCode[],
): RedirectableError | null => {
  const redirectError = errorsList?.find(gqlError => Boolean(gqlError.extensions?.redirect_path));

  if (!redirectError) return null;

  return {
    path: redirectError.extensions.redirect_path as string,
    message: redirectError.extensions.redirect_message as string,
  };
};

export const reportUnexpectedGraphQLErrors = (
  errors?: readonly GraphQLErrorWithStatusCode[],
  data?: any,
): void => {
  if (errors && checkErrorStatusCode(errors, isUnknownError)) {
    withScope(scope => {
      scope.setExtras({errors, data});
      captureMessage('Unexpected GraphQL Error Occurred');
    });
  }
};

export const reportAllUnexpectedGraphQLErrors = (
  page: string,
  errors?: readonly GraphQLErrorWithStatusCode[],
  data?: any,
): void => {
  if (errors) {
    withScope(scope => {
      scope.setExtras({errors, data});
      captureMessage(`Some Unexpected GraphQL Error Occurred on ${page}`);
    });
  } else {
    withScope(scope => {
      scope.setExtras({data});
      captureMessage('Some Unexpected error with no extra information');
    });
  }
};

const checkErrorStatusCode = (
  errorsList: readonly GraphQLErrorWithStatusCode[],
  callback: (statusCode: number | undefined) => boolean,
): GraphQLErrorWithStatusCode | undefined => {
  return errorsList.find(gqlError => {
    if (gqlError.extensions?.code === 'DOWNSTREAM_SERVICE_ERROR') {
      // error code is being proxied through the federated gateway
      // read the status code off of the extension exception

      const proxiedStatusCode = (gqlError.extensions.exception as {statusCode?: number})
        ?.statusCode;
      return callback(proxiedStatusCode);
    }

    // error code is coming directly from the endpoint, either
    // the federated gateway or ez-rails direct
    return callback(gqlError.statusCode);
  });
};

export const extractAuthError = (
  errorsList: readonly GraphQLErrorWithStatusCode[],
  redirectUrl?: string,
): RedirectableError | null => {
  const redirectError = checkErrorStatusCode(errorsList, isAuthError);

  if (redirectError) {
    const queryParams = redirectUrl ? {redirect_url: redirectUrl} : {};

    return {
      path: compilePath(SIGN_IN_PATH, {}, queryParams),
    };
  }
  return null;
};

export type ExtractableErrorOptions = {
  errors?: Maybe<readonly GraphQLErrorWithStatusCode[]>;
  error?: Maybe<ApolloError>;
  redirectUrl?: string;
};
export const extractRedirectableError = ({
  errors: errorsArray,
  error: apolloError,
  redirectUrl,
}: ExtractableErrorOptions): RedirectableError | null => {
  const errorsList = apolloError?.graphQLErrors || errorsArray;

  if (!errorsList || errorsList.length === 0) return null;
  return (
    extractErrorWithExtensionRedirects(errorsList) || extractAuthError(errorsList, redirectUrl)
  );
};
