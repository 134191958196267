export const LoggedInComponentsEnum = {
  IssuesDetailSelection: 'IssuesDetailSelection',
  LoggedInOrderIssuesSelector: 'LoggedInOrderIssuesSelector',
  LoggedInIssuesReview: 'LoggedInIssuesReview',
  SubmissionConfirmation: 'SubmissionConfirmation',
} as const;

export const LoggedOutComponentsEnum = {
  IssuesDetailSelection: 'IssuesDetailSelection',
  LoggedOutOrderIssuesSelector: 'LoggedOutOrderIssuesSelector',
  LoggedOutCustomerIssuesReview: 'LoggedOutCustomerIssuesReview',
  SubmissionConfirmation: 'SubmissionConfirmation',
} as const;

export const AllComponentsEnum = {
  ...LoggedInComponentsEnum,
  ...LoggedOutComponentsEnum,
} as const;

export type AllComponentsEnum = (typeof AllComponentsEnum)[keyof typeof AllComponentsEnum];
