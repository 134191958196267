import {Portal} from '@mui/base';
import {twMerge} from 'tailwind-merge';

import useIdentity from '@/hooks/useIdentity';
import useMediaQuery from '@/hooks/useMediaQuery';
import recipeMq from '@/styles/recipeMq';
import {useLibertyWebchat} from '../LibertyWebchatProvider';

type LibertyWebchatWidgetProps = {className?: string};

const LibertyWebchatWidget: React.FC<LibertyWebchatWidgetProps> = ({className}) => {
  const {data, loading} = useIdentity();
  const isDesktop = useMediaQuery(recipeMq('large'));
  const {webchatEnabled, isWebchatWidgetOpen, iframeEl, host} = useLibertyWebchat();
  const shouldRenderWebchatWidget = webchatEnabled && isDesktop && !loading;

  const srcString = () => {
    const userName = encodeURIComponent(data?.me?.consumerAccount?.fullName ?? '');
    const userTrackingId = encodeURIComponent(data?.currentUserTrackingId ?? '');
    const originUrl = encodeURIComponent(window.location.href);
    const referrerUrl = encodeURIComponent(document.referrer);

    return `${host}?userName=${userName}&userTrackingId=${userTrackingId}&originUrl=${originUrl}&referrerUrl=${referrerUrl}&iframe=true`;
  };

  return shouldRenderWebchatWidget ? (
    <Portal>
      <div className={twMerge('fixed bottom-0 right-0 z-1350', className)}>
        <iframe
          ref={iframeEl}
          frameBorder={0}
          scrolling="no"
          title="open live support chat"
          id="liberty-webchat-iframe"
          src={srcString()}
          style={{
            width: isWebchatWidgetOpen ? 325 : 200,
            height: isWebchatWidgetOpen ? 650 : 80,
          }}
        />
      </div>
    </Portal>
  ) : null;
};

export default LibertyWebchatWidget;
