import Image from 'next/image';
import useTranslation from 'next-translate/useTranslation';

import cupcake from '@/assets/images/cupcake.svg';
import {ToastContentProps} from '../types';

const ToastContent = ({
  title,
  description,
  finePrint,
  termsAndConditionsUrl,
}: ToastContentProps) => {
  const {t} = useTranslation('common');
  return (
    <div className="relative flex overflow-hidden rounded-lg bg-ezgreen-400 tablet:max-w-[464px]">
      <div className="relative flex max-w-[358px] flex-col gap-2 p-6 pr-3 text-white">
        <div className="text-xl font-bold tablet:text-2xl">{title}</div>
        <div className="text-sm font-semibold tablet:text-base">{description}</div>
        <div className="text-xs tablet:text-sm">{finePrint}</div>
        {termsAndConditionsUrl && (
          <div className="text-xs tablet:text-sm">
            <a
              className="text-inherit hover:text-inherit"
              href={termsAndConditionsUrl}
              rel="noreferrer"
              target="_blank"
            >
              {t('components.ToastContent.termsAndConditions')}
            </a>
          </div>
        )}
      </div>
      <Image src={cupcake} alt="" className="hidden self-end tablet:block" />
    </div>
  );
};

export default ToastContent;
