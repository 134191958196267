import {createContext, useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDebounce, useLatest} from 'react-use';
import {useRouter} from 'next/router';
import queryString, {type ParsedQuery} from 'query-string';

import {useGlobalFulfillmentDetail} from '@/components/GlobalFulfillmentDetailProvider';
import {useStoreNextRouter} from '@/components/StoreNextRouter';
import {CatererSearchOrderType} from '@/graphql/types';
import useTracking from '@/hooks/useTracking';
import {
  areFiltersActive,
  extractFilters,
  extractFiltersForSaving,
  isFilterStateCleared,
  transformArrayParam,
} from '@/pageUtils/caterer-search/filter';
import {SEARCH_NEW_PATH, SEARCH_ORDER_ID_PATH} from '@/paths';
import {compilePath, gtm} from '@/utils';
import {safeLocalStorage} from '@/utils/storage';
import {useSearchPageData} from '../SearchPageProvider';
import {type FilterState, FiltersUpdateSource} from './utils/types';

const CLEARED_FILTERS = {
  budgetAmount: '',
  businessSizingTaxonomy: '',
  businessTypeTaxonomy: '',
  cuisineOption: '',
  cuisineTaxonomy: '',
  deiOwnedBusinessTaxonomy: '',
  deiOwnedFranchiseTaxonomy: '',
  deliveryCapabilitiesTaxonomy: '',
  deliveryFee: '',
  dietaryCapabilitiesTaxonomy: '',
  dietaryOptions: [],
  distance: '',
  individualPackaging: '',
  mealTypeTaxonomy: '',
  orderMinimum: '',
  packagingTaxonomy: '',
  promotionsTaxonomy: '',
  reliabilityRockstar: '',
  rewardsLevel: '',
  serviceCapabilitiesTaxonomy: '',
  starRating: '',
  suitabilityTaxonomy: '',
};

const sendGTMUpdate = (filters: Partial<FilterState>) => {
  Object.entries(filters).forEach(([filterKey, filterValue]) => {
    const filterLabel =
      Array.isArray(filterValue) || !filterValue || typeof filterValue === 'string'
        ? (transformArrayParam(filterValue) || []).map(s => s.toLowerCase()).join(',')
        : Object.values(filterValue).join(',');

    const payload = {
      event: 'caterer_filter',
      filter_type: filterKey,
      filter_value: filterLabel,
    };

    gtm.dataLayer(payload);
  });
};

const mergeQueryParams = (
  filters: FilterState,
  query: ParsedQuery,
  state: Partial<FilterState>,
) => {
  const newQuery: Record<string, string | string[]> = {
    ...filters,
    ...query,
    ...state,
    page: '',
    orderId: '',
  };
  const arrayParams = Object.keys(query).filter(param => param.endsWith('[]'));
  arrayParams.forEach(param => delete newQuery[param]); // delete so queryString can set from JSON
  const formatCurrency = (value: string) => (parseInt(value, 10) * 100).toString();
  if (state?.deliveryFee) newQuery.deliveryFee = formatCurrency(state.deliveryFee);
  if (state?.budgetAmount) newQuery.budgetAmount = formatCurrency(state.budgetAmount);
  return newQuery;
};

const FiltersProvider: React.FC<React.PropsWithChildren> = ({children}) => {
  const [analyticsSearchId, setAnalyticsSearchId] = useState<string | undefined>();
  const [debouncedFilters, setDebouncedFilters] = useState<Partial<FilterState>>({});
  const shouldResetDebouncedFiltersRef = useRef(false);
  const searchPageData = useSearchPageData();
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const address = searchPageData?.address ?? fulfillmentDetail?.address;
  const strategy = searchPageData?.strategy ?? fulfillmentDetail?.strategy;
  const {query, asPath, push: routerPush} = useRouter();
  const filters = useMemo(() => extractFilters(query), [query]);
  const shouldClearKeyword = useRef(false);
  const tracker = useTracking();
  const {isOnSearchPage} = useStoreNextRouter();

  const activeFilters = useMemo(
    () =>
      Object.fromEntries(
        Object.entries(filters).filter(
          ([, filterValue]) => filterValue != null && filterValue !== '' && filterValue?.length > 0,
        ),
      ),
    [filters],
  );

  const areFiltersSelected =
    Boolean(
      !Object.keys(activeFilters).length &&
        (filters.cuisineOption?.length ||
          filters.dietaryOptions?.length ||
          filters.individualPackaging?.length ||
          filters.reliabilityRockstar?.length),
    ) ||
    areFiltersActive(query as ParsedQuery<string>) ||
    false;

  const filtersCallbackRef = useLatest({
    analyticsSearchId,
    filters,
    fullAddress: address?.fullAddress,
    asPath,
    query,
  });

  const updateFilters = useCallback(
    (
      newState: Partial<FilterState>,
      initiator?: FiltersUpdateSource | null,
      debounce?: boolean,
    ) => {
      if (newState?.keyword === '') shouldClearKeyword.current = true;

      const mergedQueryParams = mergeQueryParams(
        filtersCallbackRef.current.filters,
        filtersCallbackRef.current.query as ParsedQuery,
        newState,
      );

      // Fire tracking events only when updateFilters is not called by useDebounce to avoid calling it twice
      if (initiator !== FiltersUpdateSource.Debounce) {
        sendGTMUpdate(newState);
        tracker.track('filters-updated', {
          page: window.location.pathname,
          misc_json: JSON.stringify({
            search_id: filtersCallbackRef.current.analyticsSearchId ?? null,
            key: initiator ?? null,
            filters: newState,
          }),
        });
      }

      if (debounce) {
        setDebouncedFilters(currentState => ({...currentState, ...newState}));
        return;
      } else {
        // Clear local storage if filters are cleared
        if (isFilterStateCleared(mergedQueryParams) && filtersCallbackRef.current.fullAddress)
          safeLocalStorage.removeItem(filtersCallbackRef.current.fullAddress);
      }

      // Update filters
      if (isOnSearchPage) {
        const newQueryString = queryString.stringify(mergedQueryParams, {
          arrayFormat: 'bracket',
          skipEmptyString: true,
          skipNull: true,
        });

        routerPush(
          `${filtersCallbackRef.current.asPath.split('?', 1)[0]}?${newQueryString}`,
          undefined,
          {
            shallow: true,
          },
        );
      } else if (fulfillmentDetail?.id) {
        routerPush(
          compilePath(SEARCH_ORDER_ID_PATH, {orderId: fulfillmentDetail?.id}, mergedQueryParams, {
            arrayFormat: 'bracket',
            skipEmptyString: true,
            skipNull: true,
          }),
        );
      } else {
        routerPush(
          compilePath(SEARCH_NEW_PATH, {}, mergedQueryParams, {
            arrayFormat: 'bracket',
            skipEmptyString: true,
            skipNull: true,
          }),
        );
      }
    },
    [filtersCallbackRef, fulfillmentDetail?.id, isOnSearchPage, routerPush, tracker],
  );

  const restoreFilters = useCallback(
    (queryStr: string | null) => {
      if (!queryStr) return;
      const parsedQueryString = queryString.parse(queryStr, {arrayFormat: 'bracket'});
      const mergedQueryParams = mergeQueryParams(
        parsedQueryString as FilterState,
        filtersCallbackRef.current.query as ParsedQuery,
        {},
      );

      // Fire tracking events
      sendGTMUpdate(parsedQueryString as FilterState);
      tracker.track('filters-updated', {
        page: window.location.pathname,
        misc_json: JSON.stringify({
          search_id: filtersCallbackRef.current.analyticsSearchId ?? null,
          key: FiltersUpdateSource.ReactivateLastFilters,
          filters: parsedQueryString,
        }),
      });

      // Update filters and clear keyword
      const newQueryString = queryString.stringify(mergedQueryParams, {
        skipNull: true,
        skipEmptyString: true,
        arrayFormat: 'bracket',
      });
      shouldClearKeyword.current = true;
      routerPush(
        `${filtersCallbackRef.current.asPath.split('?', 1)[0]}?${newQueryString}`,
        undefined,
        {shallow: true},
      );
    },
    [filtersCallbackRef, routerPush, tracker],
  );

  // Save filters to local storage
  useEffect(() => {
    if (!address?.fullAddress || !filters || !Object.values(filters).some(val => val?.length))
      return;

    const newQueryString = queryString.stringify(extractFiltersForSaving({query}), {
      arrayFormat: 'bracket',
      skipEmptyString: true,
      skipNull: true,
    });

    safeLocalStorage.setItem(address.fullAddress, newQueryString);
  }, [address?.fullAddress, filters, query]);

  // Clear delivery fee when switching to takeout
  useEffect(() => {
    if (
      analyticsSearchId &&
      strategy === CatererSearchOrderType.Takeout &&
      filters.deliveryFee !== ''
    ) {
      updateFilters({deliveryFee: ''}, FiltersUpdateSource.OrderType);
    }
  }, [analyticsSearchId, filters, strategy, updateFilters]);

  // Apply debounced filters
  useDebounce(
    () => {
      if (Object.keys(debouncedFilters).length > 0) {
        updateFilters(debouncedFilters, FiltersUpdateSource.Debounce);
        shouldResetDebouncedFiltersRef.current = true;
      }
    },
    750,
    [debouncedFilters],
  );

  // Reset debounced filters when filters change
  useEffect(() => {
    if (shouldResetDebouncedFiltersRef.current) {
      shouldResetDebouncedFiltersRef.current = false;
      setDebouncedFilters({});
    }
  }, [filters]);

  return (
    <FiltersContext.Provider
      value={useMemo(
        () => ({
          activeFilters,
          analyticsSearchId,
          areFiltersSelected,
          clearFilters() {
            updateFilters({...CLEARED_FILTERS});
          },
          clearFiltersAndKeyword() {
            shouldClearKeyword.current = true;
            updateFilters({...CLEARED_FILTERS, keyword: ''});
          },
          clearKeywordOnly() {
            shouldClearKeyword.current = true;
            updateFilters({keyword: ''});
          },
          filters: {...filters, ...debouncedFilters},
          restoreFilters,
          setAnalyticsSearchId,
          shouldClearKeyword,
          updateFilters,
        }),
        [
          activeFilters,
          analyticsSearchId,
          areFiltersSelected,
          debouncedFilters,
          filters,
          restoreFilters,
          setAnalyticsSearchId,
          shouldClearKeyword,
          updateFilters,
        ],
      )}
    >
      {children}
    </FiltersContext.Provider>
  );
};

export default FiltersProvider;

export const FiltersContext = createContext<{
  activeFilters: Partial<FilterState>;
  analyticsSearchId: string | undefined;
  areFiltersSelected: boolean;
  clearFilters: () => void;
  clearFiltersAndKeyword: () => void;
  clearKeywordOnly: () => void;
  filters: FilterState;
  restoreFilters: (queryStr: string | null) => void;
  setAnalyticsSearchId: (searchId: string) => void;
  shouldClearKeyword: React.MutableRefObject<boolean>;
  updateFilters: (
    newState: Partial<FilterState>,
    initiator?: FiltersUpdateSource | null,
    debounce?: boolean,
  ) => void;
} | null>(null);
