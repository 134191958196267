import {createContext, useMemo} from 'react';
import {useRouter} from 'next/router';
import invariant from 'tiny-invariant';

import {ConsumerCartExperience} from '@/graphql/types';

export type OrderingExperience = 'marketplace' | 'ezOrdering' | 'eater';
type OrderingExperienceContextPayload = {
  experience: OrderingExperience;
  isEzOrdering: boolean;
  isMarketplace: boolean;
  isEater: boolean;
  experienceForCustomerMessages: ConsumerCartExperience;
};

export const OrderingExperienceContext = createContext<OrderingExperienceContextPayload>({
  isEzOrdering: false,
  isMarketplace: true,
  experience: 'marketplace',
  isEater: false,
  experienceForCustomerMessages: ConsumerCartExperience.Marketplace,
});

export const orderingExperienceForRoute = (route: string): OrderingExperience => {
  switch (route) {
    case '/catering/pvt/[catererUrlId]/[[...date]]':
      return 'ezOrdering';

    case '/catering/eater/[catererUrlId]/[[...date]]':
      return 'eater';

    default:
      return 'marketplace';
  }
};

type Props = React.PropsWithChildren<{
  experience?: OrderingExperience;
}>;

const OrderingExperienceProvider: React.FC<Props> = ({children, experience: forcedExperience}) => {
  const router = useRouter();
  const providerValue = useMemo(() => {
    const experience = forcedExperience || orderingExperienceForRoute(router.pathname);
    return {
      experience,
      isEzOrdering: experience === 'ezOrdering',
      isMarketplace: experience === 'marketplace',
      isEater: experience === 'eater',
      experienceForCustomerMessages:
        experience === 'ezOrdering'
          ? ConsumerCartExperience.EzOrdering
          : ConsumerCartExperience.Marketplace,
    };
  }, [router.pathname, forcedExperience]);

  invariant(
    providerValue.isEzOrdering || providerValue.isMarketplace || providerValue.isEater,
    `Invalid experience "${providerValue.experience}". Must be either "marketplace", "ezOrdering", or "eater"`,
  );

  return (
    <OrderingExperienceContext.Provider value={providerValue}>
      {children}
    </OrderingExperienceContext.Provider>
  );
};

export default OrderingExperienceProvider;
