// @ts-strict-ignore
import {type ApolloQueryResult} from '@apollo/client';
import {getTimezoneOffset} from 'date-fns-tz';

import {
  type CatererSearchAddress,
  type CatererSearchOrderType,
  type Exact,
  type Scalars,
  type SearchPageFulfillmentDetailFragment,
  type SearchPageOrderQuery,
} from '@/graphql/types';
import {CATERING_MENU_PATH, MARKETPLACE_MENU_DATE_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';
import {convertIsoTimeToMilitaryTime} from '@/utils/dateFormat';

export type ExtractSearchQueryVariablesPayload = {
  address: CatererSearchAddress;
  eventDateTime: Scalars['DateTime']['output'];
  eventTime: Scalars['TimeWithoutZone']['output'];
  orderType: CatererSearchOrderType;
  trackingId: string;
};

export type SearchPageObject = {
  fulfillmentDetailId?: Scalars['ID']['output'];
  catererUrlId: string | null;
  isCartEmpty: boolean;
  address: SearchPageFulfillmentDetailFragment['address'];
  strategy: 'DELIVERY' | 'TAKEOUT';
  corpAccount?: SearchPageFulfillmentDetailFragment['corpAccount'];
  headcount?: number;
  eventOn?: string;
  eventLocalTime?: number;
  eventName?: string;
  editable: boolean;
  consumerAccount?: SearchPageOrderQuery['me']['consumerAccount'];
  isAdmin: boolean;
  nationwideCatererCount: number;
  trackingId: string;
  usingConsumerCart: boolean;
  utcOffsetMinutes: number;
  dietaryPollBannerData: SearchPageFulfillmentDetailFragment['dietaryPoll'];
  generateLinkToCaterer: (catererUrlId: string, queryParams?: Record<string, string>) => string;
  refetch?: (
    variables?: Partial<
      Exact<{
        orderId: string;
        trackOrderUsage?: boolean;
      }>
    >,
  ) => Promise<ApolloQueryResult<SearchPageOrderQuery>>;
};

export const transformSearchDataIntoSearchPageObject = ({
  data,
  refetch,
}: {
  data?: SearchPageOrderQuery | null;
  refetch?: SearchPageObject['refetch'];
}): SearchPageObject | null => {
  if (data == null) return null;
  if (data.fulfillmentDetail == null) return null;

  const {fulfillmentDetail} = data;
  const isAdmin = Boolean(data.me?.adminAccount?.id);
  const fulfillmentDetailId = fulfillmentDetail.id;
  const {eventOn} = fulfillmentDetail;

  return {
    catererUrlId: null, // TODO: Fix this object to remove the catererUrlId field
    fulfillmentDetailId,
    isCartEmpty: true,
    address: fulfillmentDetail.address,
    strategy: fulfillmentDetail.strategy,
    corpAccount: fulfillmentDetail.corpAccount,
    headcount: fulfillmentDetail.headcount,
    eventOn,
    eventLocalTime: fulfillmentDetail.eventLocalTime
      ? convertIsoTimeToMilitaryTime(fulfillmentDetail.eventLocalTime)
      : null,
    eventName: fulfillmentDetail.eventName,
    editable: fulfillmentDetail.editable,
    consumerAccount: data.me?.consumerAccount,
    isAdmin,
    nationwideCatererCount: data.nationwideCatererCount,
    trackingId: data?.currentUserTrackingId,
    usingConsumerCart: fulfillmentDetail.usingConsumerCart,
    utcOffsetMinutes: getTimezoneOffset(fulfillmentDetail.address.timeZoneIdentifier) / 60000,
    dietaryPollBannerData: fulfillmentDetail?.dietaryPoll ?? null,
    refetch,
    generateLinkToCaterer(catererUrlId: string, queryParams?: Record<string, string>) {
      if (eventOn) {
        return compilePath(
          MARKETPLACE_MENU_DATE_PATH,
          {date: eventOn, catererUrlId},
          {fulfillmentDetailId, ...queryParams},
        );
      }

      return compilePath(CATERING_MENU_PATH, {catererUrlId}, {fulfillmentDetailId, ...queryParams});
    },
  };
};

export const extractSearchQueryVariables = (
  data: SearchPageObject,
): ExtractSearchQueryVariablesPayload => ({
  address: {
    street: data.address.street,
    city: data.address.city,
    state: data.address.state,
    zip: data.address.zip,
    latitude: data.address.latitude,
    longitude: data.address.longitude,
  },
  eventDateTime: data.eventOn ? `${data.eventOn}T12:00:00Z` : null,
  eventTime: data.eventLocalTime,
  orderType: data.strategy as unknown as CatererSearchOrderType,
  trackingId: data.trackingId,
});
