import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from './useFeatureFlag';
import useIdentity from './useIdentity';
import useOrderingExperience from './useOrderingExperience';

const useNewAppBarExperience = () => {
  const {data} = useIdentity();
  const {isEzOrdering} = useOrderingExperience();
  const newAppBarEnabled = useFeatureFlag(FeatureFlags.NewAppBar);

  const currentUser = data?.me?.consumerAccount;
  const isMultiCorpAccount =
    currentUser?.corporateAccounts?.length && currentUser?.corporateAccounts?.length > 1;

  const shouldShowNewAppBar = newAppBarEnabled && !isMultiCorpAccount && !isEzOrdering;

  return {
    shouldShowNewAppBar,
  };
};

export default useNewAppBarExperience;
