import {Button, Link, Typography} from '@ezcater/tapas';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import {useLibertyWebchat} from '@/components/LibertyWebchatProvider';
import useEzCaterCompanyInfo from '@/hooks/useEzCaterCompanyInfo';
import useIsMobileCheck from '@/hooks/useIsMobileCheck';

type submissionConfirmationProps = {
  closeModal: () => void;
};

const SubmissionConfirmation: React.FC<submissionConfirmationProps> = ({closeModal}) => {
  const {t} = useTranslation('common');
  const {toggleWebchatVisibility} = useLibertyWebchat();
  const {supportPhoneNumber} = useEzCaterCompanyInfo();
  const isMobile = useIsMobileCheck();

  const toggleLibertyChat = () => {
    closeModal();
    toggleWebchatVisibility();
  };

  const chatWithUsLink = isMobile ? (
    <Link
      href="http://liberty-webchat.ezcater.com/?open=true&utm_channel=web&utm_campaign=my_account_orders"
      target="_blank"
      rel="noopener noreferrer"
    >
      {t('components.SelfServiceReportingModal.SubmissionConfirmation.chatWithUs')}
    </Link>
  ) : (
    <Link onClick={toggleLibertyChat}>
      {t('components.SelfServiceReportingModal.SubmissionConfirmation.chatWithUs')}
    </Link>
  );

  return (
    <div className="m-6 tablet:m-12" data-testid="submission-confirmation">
      <div className="mb-8 flex flex-col tablet:flex-row">
        <div className="flex w-full flex-col gap-4 tablet:w-5/6">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.SubmissionConfirmation.thanksForReporting')}
          </Typography>
          <div>
            <Trans
              i18nKey="common:components.SelfServiceReportingModal.SubmissionConfirmation.customerOutreach"
              components={{
                phoneNumberLink: (
                  <Link href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</Link>
                ),
                chatWithUsLink: chatWithUsLink,
              }}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={closeModal} size="medium" variant="primary">
          {t('components.SelfServiceReportingModal.close')}
        </Button>
      </div>
    </div>
  );
};

export default SubmissionConfirmation;
