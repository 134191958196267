import {useEffect} from 'react';

import {useStoreNextRouter} from '@/components/StoreNextRouter';
import Experiments from '@/Experiments';
import useExperiment from '@/hooks/useExperiment';
import useIsDesktopCheck from './useIsDesktopCheck';
import useNewAppBarExperience from './useNewAppBarExperience';

// ez486 - Remove WFH Search Bar & Recent Addresses
const useEz486RemoveWFHSearchBarAndRecentAddresses = () => {
  const {trackExposure: trackExposureEz486, variant} = useExperiment(
    Experiments.RemoveWFHSearchBarAndRecentAddresses,
  );

  const {shouldShowNewAppBar} = useNewAppBarExperience();
  const isDesktop = useIsDesktopCheck();
  const {isOnMenuPage, isOnSearchPage} = useStoreNextRouter();

  const isEligibleForEz486 = shouldShowNewAppBar && isDesktop && !isOnMenuPage && !isOnSearchPage;

  const removeWFHSearchBarAndRecentAddresses = variant === 'treatment' && isEligibleForEz486;

  useEffect(() => {
    if (isEligibleForEz486) {
      trackExposureEz486();
    }
  }, [isEligibleForEz486, trackExposureEz486]);

  return {
    removeWFHSearchBarAndRecentAddresses,
  };
};

export default useEz486RemoveWFHSearchBarAndRecentAddresses;
