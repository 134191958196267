import {useEffect} from 'react';
import {Alert, Button, Checkbox, Link, TextField, Typography} from '@ezcater/tapas';
import {Field, FormikErrors} from 'formik';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import {useLibertyWebchat} from '@/components/LibertyWebchatProvider';
import {
  ADDITIONAL_DETAILS_MAX_TEXT_LENGTH,
  FormValues,
} from '@/components/SelfServiceReportingModal/SelfServiceReportingModal';
import {AdditionalIssue, NatureOfIssue} from '@/graphql/types';
import useEzCaterCompanyInfo from '@/hooks/useEzCaterCompanyInfo';
import useIsMobileCheck from '@/hooks/useIsMobileCheck';
import useTracking from '@/hooks/useTracking';
import {AllComponentsEnum, LoggedInComponentsEnum} from '../componentNameEnums';
import {additionalIssuesOptions} from '../SelfServiceReportingConstants';

type LoggedInIssuesReviewProps = {
  additionalIssues: AdditionalIssue[];
  closeModal: () => void;
  errors: FormikErrors<FormValues>;
  handleSubmit: () => void;
  isSubmitting: boolean;
  loggedInIssuesReviewAdditionalDetails: string;
  natureOfIssue: NatureOfIssue | null;
  setFieldValue: (field: string, value: string) => void;
  setStep: React.Dispatch<React.SetStateAction<keyof typeof AllComponentsEnum>>;
  setSubmissionError: React.Dispatch<React.SetStateAction<boolean>>;
  submissionError: boolean;
};

const LoggedInIssuesReview: React.FC<LoggedInIssuesReviewProps> = ({
  additionalIssues,
  closeModal,
  errors,
  handleSubmit,
  isSubmitting,
  loggedInIssuesReviewAdditionalDetails,
  natureOfIssue,
  setFieldValue,
  setStep,
  setSubmissionError,
  submissionError,
}) => {
  const {t} = useTranslation('common');
  const {track} = useTracking();
  const {supportPhoneNumber} = useEzCaterCompanyInfo();
  const isMobile = useIsMobileCheck();
  const {toggleWebchatVisibility} = useLibertyWebchat();

  useEffect(() => {
    track('self-service-reporting-modal-logged-in-issues-review-viewed');
  }, [track]);

  const toggleLibertyChat = () => {
    setSubmissionError(false);
    closeModal();
    toggleWebchatVisibility();
  };

  const chatWithUsLink = isMobile ? (
    <Link
      href="http://liberty-webchat.ezcater.com/?open=true&utm_channel=web&utm_campaign=my_account_orders"
      rel="noopener noreferrer"
      target="_blank"
    >
      {t('components.SelfServiceReportingModal.chatWithUs')}
    </Link>
  ) : (
    <Link onClick={toggleLibertyChat}>{t('components.SelfServiceReportingModal.chatWithUs')}</Link>
  );

  const clickSubmit = () => {
    handleSubmit();
  };
  const clickPreviousStep = () => setStep(LoggedInComponentsEnum.IssuesDetailSelection);

  const additionalDetailsSuffix = (
    <div
      className={
        loggedInIssuesReviewAdditionalDetails.length > ADDITIONAL_DETAILS_MAX_TEXT_LENGTH
          ? 'text-guava-400'
          : 'text-peppercorn-400'
      }
      data-testid="additional-details-suffix"
    >
      {loggedInIssuesReviewAdditionalDetails.length}/{ADDITIONAL_DETAILS_MAX_TEXT_LENGTH}
    </div>
  );

  const handleAdditionalDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value.length <= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH) {
      setFieldValue('loggedInIssuesReviewAdditionalDetails', value);
    }
  };

  const isNatureOfIssue = (value: NatureOfIssue | AdditionalIssue): boolean => {
    return value === natureOfIssue;
  };

  const hasLoggedInIssuesReviewAdditionalDetailsErrors =
    Boolean(errors?.loggedInIssuesReviewAdditionalDetails) ||
    loggedInIssuesReviewAdditionalDetails.length >= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH;

  return (
    <div className="m-6 tablet:m-12" data-testid="logged-in-issues-review">
      <div className="mb-8">
        <div className="flex flex-col gap-2">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
          </Typography>
          <Typography variant="body-md">
            {t('components.SelfServiceReportingModal.LoggedInIssuesReview.otherIssues')}
          </Typography>
          <Typography className="font-bold" variant="body-sm">
            {t('components.SelfServiceReportingModal.LoggedInIssuesReview.chooseAllThatApply')}
          </Typography>

          {additionalIssuesOptions.map(({name, value}) => (
            <label
              key={value}
              className="flex w-fit cursor-pointer items-center gap-2 has-[:disabled]:cursor-default"
            >
              <Field type="checkbox" name="additionalIssues" value={value}>
                {({field}: {field: any}) => (
                  <Checkbox
                    {...field}
                    checked={additionalIssues.includes(value) || isNatureOfIssue(value)}
                    disabled={isNatureOfIssue(value)}
                  />
                )}
              </Field>
              {name}
            </label>
          ))}

          {additionalIssues.length > 0 && (
            <div className="my-4">
              <Typography className="mb-2" variant="body-md">
                {t('components.SelfServiceReportingModal.LoggedInIssuesReview.additionalDetails')}
              </Typography>
              <TextField
                aria-label="multiline textarea"
                as="textarea"
                error={
                  loggedInIssuesReviewAdditionalDetails.length >= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH
                }
                onInput={handleAdditionalDetailsChange}
                rows={3}
                suffix={additionalDetailsSuffix}
                value={loggedInIssuesReviewAdditionalDetails}
              />
              {hasLoggedInIssuesReviewAdditionalDetailsErrors && (
                <div className="mt-2 flex items-center text-guava-400">
                  <Typography variant="body-sm">
                    {errors?.loggedInIssuesReviewAdditionalDetails ||
                      t(
                        'components.SelfServiceReportingModal.validation.shortenAdditionalDetailsDescription',
                      )}
                  </Typography>
                </div>
              )}
            </div>
          )}

          {submissionError && (
            <Alert variant="error" data-testid="logged-in-issues-review-error-alert">
              <Typography variant="body-md">
                <Trans
                  i18nKey="common:components.SelfServiceReportingModal.somethingWentWrong"
                  components={{
                    phoneNumberLink: (
                      <Link href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</Link>
                    ),
                    chatWithUsLink,
                  }}
                />
              </Typography>
            </Alert>
          )}
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mr-4 leading-10 text-peppercorn-400">
          {t('components.SelfServiceReportingModal.stepThreeOfThree')}
        </div>
        <Button className="mr-2" onClick={clickPreviousStep} size="medium" variant="outlined">
          {t('components.SelfServiceReportingModal.back')}
        </Button>
        <Button
          disabled={submissionError || hasLoggedInIssuesReviewAdditionalDetailsErrors}
          loading={isSubmitting}
          onClick={clickSubmit}
          size="medium"
          type="submit"
          variant="primary"
        >
          {t('components.SelfServiceReportingModal.submit')}
        </Button>
      </div>
    </div>
  );
};

export default LoggedInIssuesReview;
