import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import invariant from 'tiny-invariant';

import {FulfillmentDetailAddressInput, type FulfillmentDetailFragment} from '@/graphql/types';
import useEz486RemoveWFHSearchBarAndRecentAddresses from '@/hooks/useEz486RemoveWFHSearchBarAndRecentAddresses';
import {safeLocalStorage} from '@/utils/storage';
import {useGlobalFulfillmentDetail} from '../GlobalFulfillmentDetailProvider/GlobalFulfillmentDetailProvider';

export type FulfillmentDetailContextValues = {
  fulfillmentDetailClickHandler: React.Dispatch<React.SetStateAction<FulfillmentDetailStates>>;
  fulfillmentDetail: FulfillmentDetailFragment | null;
  fulfillmentDetailState: FulfillmentDetailStates;
  FulfillmentDetailStates: typeof FulfillmentDetailStates;
  fallbackAddress?: FulfillmentDetailAddressInput;
  dismissCoachmark: boolean;
  setCoachmarkDismissed: () => void;
  setCoachmarkSeen: () => void;
};

enum FulfillmentDetailStates {
  LOCATION = 'LOCATION',
  DATETIME = 'DATETIME',
  DETAILS = 'DETAILS',
  COACHMARK = 'COACHMARK', // ez486
  NONE = 'NONE',
}

export const EVENT_BAR_COACHMARK_SEEN = 'eventBarCoachmarkSeen'; // ez486

export const FulfillmentDetailStateContext = createContext<FulfillmentDetailContextValues>({
  fulfillmentDetailClickHandler: state => state,
  fulfillmentDetail: null,
  fulfillmentDetailState: FulfillmentDetailStates.NONE,
  FulfillmentDetailStates,
  dismissCoachmark: false,
  setCoachmarkDismissed: () => {},
  setCoachmarkSeen: () => {},
});

export const useFulfillmentDetailState = () => useContext(FulfillmentDetailStateContext);

const FulfillmentDetailStateProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {fulfillmentDetail, fallbackAddress} = useGlobalFulfillmentDetail();
  invariant(
    FulfillmentDetailStateContext != null,
    'FulfillmentDetailStateProvider must be used within FulfillmentDetailStateContext',
  );

  const [fulfillmentDetailState, setFulfillmentDetailState] = useState(
    FulfillmentDetailStates.NONE,
  );

  // ez486
  const {removeWFHSearchBarAndRecentAddresses} = useEz486RemoveWFHSearchBarAndRecentAddresses();
  const [dismissCoachmark, setDismissCoachmark] = useState(false);

  useEffect(() => {
    const seen = safeLocalStorage.getItem(EVENT_BAR_COACHMARK_SEEN) === 'true';
    setDismissCoachmark(seen);
  }, []);

  const setCoachmarkDismissed = useCallback(() => {
    setDismissCoachmark(true);
  }, []);

  const setCoachmarkSeen = useCallback(() => {
    safeLocalStorage.setItem(EVENT_BAR_COACHMARK_SEEN, 'true');
  }, []);

  useEffect(() => {
    if (removeWFHSearchBarAndRecentAddresses && !dismissCoachmark) {
      setFulfillmentDetailState(FulfillmentDetailStates.COACHMARK);
    }
  }, [removeWFHSearchBarAndRecentAddresses, dismissCoachmark]);
  // end ez486

  const providerValue = useMemo(
    () => ({
      fulfillmentDetailClickHandler: setFulfillmentDetailState,
      fulfillmentDetail,
      fulfillmentDetailState,
      FulfillmentDetailStates,
      fallbackAddress,
      dismissCoachmark,
      setCoachmarkDismissed,
      setCoachmarkSeen,
    }),
    [
      setFulfillmentDetailState,
      fulfillmentDetail,
      fulfillmentDetailState,
      fallbackAddress,
      dismissCoachmark,
      setCoachmarkDismissed,
      setCoachmarkSeen,
    ],
  );

  return (
    <FulfillmentDetailStateContext.Provider value={providerValue}>
      {children}
    </FulfillmentDetailStateContext.Provider>
  );
};

export default FulfillmentDetailStateProvider;
