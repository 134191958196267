import {useEffect, useMemo} from 'react';
import {Button, Link, Radio, Select, SelectOption, Typography} from '@ezcater/tapas';
import {format} from 'date-fns';
import {FormikErrors} from 'formik';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import LoadingSpinner from '@/components/Loading/LoadingSpinner';
import {natureOfIssueOptions} from '@/components/SelfServiceReportingModal/SelfServiceReportingConstants';
import {FormValues} from '@/components/SelfServiceReportingModal/SelfServiceReportingModal';
import {NatureOfIssue, useSubmittedOrdersQuery} from '@/graphql/types';
import useEzCaterCompanyInfo from '@/hooks/useEzCaterCompanyInfo';
import useTracking from '@/hooks/useTracking';
import {convertIsoDateToDayMonthDate, DateTimeFormats} from '@/utils/dateFormat';
import eventDateInEventTimezone from '@/utils/eventDateInEventTimezone';
import {AllComponentsEnum, LoggedInComponentsEnum} from '../componentNameEnums';
import ContactCustomerSupport from './ContactCustomerSupport';

export type Order = {
  id: string;
  event: {
    id: string;
    timestamp: string;
    timeZoneOffset: string;
  };
  caterer: {
    id: string;
    name: string;
  };
  formattedOrderNumber: string;
};

type LoggedInOrderIssuesSelectorProps = {
  errors: FormikErrors<FormValues>;
  natureOfIssue: NatureOfIssue | null;
  order: Order | string | null;
  resetIssuesDetailSelectionFormValues: (
    setFieldValue: (field: string, value: any) => void,
  ) => void;
  setFieldValue: (field: string, value: any) => void;
  setNatureOfIssue: React.Dispatch<React.SetStateAction<string>>;
  setOrder: React.Dispatch<React.SetStateAction<Order | null>>;
  setStep: React.Dispatch<React.SetStateAction<keyof typeof AllComponentsEnum>>;
};

type OrdersFragmentProps = {
  loading: boolean;
  orders: Order[];
  ordersFilter: (timestamp: string) => boolean;
  title: string;
};

const businessDaysFromNow = (days: number) => {
  const date = new Date();
  let remainingDays = days;
  const direction = Math.sign(days);

  while (remainingDays !== 0) {
    // Move one day in the specified direction
    date.setDate(date.getDate() + direction);

    const dayOfWeek = date.getDay();
    if (dayOfWeek !== 0 && dayOfWeek !== 6) {
      // 0 is Sunday, 6 is Saturday
      remainingDays -= direction;
    }
  }

  return date.toISOString();
};

const OrdersFragment: React.FC<OrdersFragmentProps> = ({loading, orders, ordersFilter, title}) => {
  if (loading) return null;

  const filteredOrders = orders?.filter(order => order && ordersFilter(order.event.timestamp));

  if (!filteredOrders?.length) return null;

  return (
    <>
      <Typography role="option" variant="title-sm">
        {title}
      </Typography>
      {filteredOrders?.map(order => {
        const {timestamp, timeZoneOffset} = order.event;

        const localDateTime = eventDateInEventTimezone({
          timestamp,
          timeZoneOffset,
        });

        const date = localDateTime ? convertIsoDateToDayMonthDate(localDateTime.toISOString()) : '';
        const time = localDateTime ? format(localDateTime, DateTimeFormats.HourWithMinutes) : '';
        const catererName = order.caterer.name;

        return (
          <SelectOption
            className="whitespace-pre"
            key={order.id}
            label={`${date}, ${time}  |  ${catererName}  |  Order ${order.formattedOrderNumber}`}
            value={order.id}
          >
            <div className="flex flex-col">
              <div className="empty:hidden">{catererName.length >= 40 ? catererName : ''}</div>
              <div className="text-peppercorn-500">{`${date}, ${time}  ${catererName.length < 40 ? `|  ${catererName}` : ''}  |  Order ${order.formattedOrderNumber}`}</div>
            </div>
          </SelectOption>
        );
      })}
    </>
  );
};

const LoggedInOrderIssuesSelector: React.FC<LoggedInOrderIssuesSelectorProps> = ({
  errors,
  natureOfIssue,
  order = null,
  resetIssuesDetailSelectionFormValues,
  setFieldValue,
  setNatureOfIssue,
  setOrder,
  setStep,
}) => {
  const {t} = useTranslation('common');
  const {supportPhoneNumber} = useEzCaterCompanyInfo();
  const {track} = useTracking();
  const eventAfter = useMemo(() => businessDaysFromNow(-2), []);
  const eventBefore = useMemo(() => businessDaysFromNow(1), []);
  const {loading: loadingOrdersData, data: ordersData} = useSubmittedOrdersQuery({
    variables: {
      limit: 200,
      eventAfter,
      eventBefore,
    },
  });
  const orders = ordersData?.me?.consumerAccount?.orders?.nodes as Order[];

  useEffect(() => {
    if (!loadingOrdersData && !!orders?.length) {
      track('self-service-reporting-modal-logged-in-order-issues-selector-viewed');
    }
  }, [track, loadingOrdersData, orders]);

  const clickNextStep = () => {
    return setStep(LoggedInComponentsEnum.IssuesDetailSelection);
  };

  if (!loadingOrdersData && (!orders || orders?.length === 0)) {
    return <ContactCustomerSupport />;
  }

  return (
    <>
      {loadingOrdersData ? (
        <LoadingSpinner hideText className="h-80" />
      ) : (
        <div className="m-6 tablet:m-12" data-testid="logged-in-order-issues-selector">
          <div className="flex flex-col tablet:flex-row">
            <div className="flex flex-col gap-4">
              <Typography variant="heading-sm">
                {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
              </Typography>
              <div>
                <Trans
                  i18nKey="common:components.SelfServiceReportingModal.LoggedInOrderIssuesSelector.title"
                  components={{
                    phoneNumberLink: (
                      <Link href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</Link>
                    ),
                  }}
                />
              </div>
              <div className="flex flex-col">
                <label className="mb-1 font-bold" htmlFor="logged-in  -issue-selector-order">
                  {t('components.SelfServiceReportingModal.LoggedInOrderIssuesSelector.order')}
                </label>
                <Select<Order>
                  className={twJoin(
                    'w-full',
                    errors?.order &&
                      'border-guava-400 hover:border-guava-500 active:border-guava-500',
                  )}
                  id="logged-in-issue-selector-order"
                  onChange={(_event, newValue) => {
                    setOrder(newValue);
                  }}
                  value={order as Order}
                >
                  <OrdersFragment
                    key="active-orders"
                    title="Active orders"
                    loading={loadingOrdersData}
                    ordersFilter={eventTimestamp => new Date(eventTimestamp) >= new Date()}
                    orders={orders}
                  />
                  <OrdersFragment
                    key="past-orders"
                    title="Past orders"
                    loading={loadingOrdersData}
                    ordersFilter={eventTimestamp => new Date(eventTimestamp) < new Date()}
                    orders={orders}
                  />
                </Select>
                <div className="mt-2 flex items-center text-guava-400 empty:hidden">
                  <Typography variant="body-sm">{errors?.order}</Typography>
                </div>
              </div>
              <fieldset className="flex flex-col gap-2">
                <legend className="mb-2 font-bold">
                  {t(
                    'components.SelfServiceReportingModal.LoggedInOrderIssuesSelector.natureOfIssue',
                  )}
                </legend>
                {natureOfIssueOptions.map(({name, value}) => (
                  <label
                    key={name}
                    className="flex w-fit cursor-pointer items-center gap-3 has-[:disabled]:cursor-default"
                  >
                    <Radio
                      className="border-2 border-peppercorn-400"
                      checked={natureOfIssue === value}
                      value={value}
                      name="natureOfProblem"
                      onChange={() => {
                        resetIssuesDetailSelectionFormValues(setFieldValue);
                        setNatureOfIssue(value);
                      }}
                    />
                    {name}
                  </label>
                ))}
                <Typography variant="body-sm" className="text-guava-400 empty:hidden">
                  {errors?.natureOfIssue}
                </Typography>
              </fieldset>
            </div>
          </div>
          <div className="flex justify-end">
            <div className="mr-4 leading-10 text-peppercorn-400">
              {t('components.SelfServiceReportingModal.stepOneOfThree')}
            </div>
            <Button onClick={clickNextStep} size="medium" type="button" variant="primary">
              {t('components.SelfServiceReportingModal.next')}
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default LoggedInOrderIssuesSelector;
