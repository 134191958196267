import {createContext, useContext, useMemo} from 'react';

import {type StoreCampaignQuery, useStoreCampaignQuery} from '@/graphql/types';
import type RequiredPath from '@/utils/RequiredPath';
import {useStoreNextRouter} from '../StoreNextRouter';

type StoreCampaignFeaturesJson = {
  cta?: string;
  carousel_copy?: string;
  carousel_title?: string;
  enable_carousel?: boolean;
  fine_print?: string;
  menu_banner_copy?: string;
  order_placed_copy?: string;
  search_banner_copy?: string;
  use_dealicious_taxon?: boolean;
  toast_copy?: string;
};
type StoreCampaignFeatures = {
  cta?: string;
  carouselCopy?: string;
  carouselTitle?: string;
  enableCarousel?: boolean;
  finePrint?: string;
  menuBannerCopy?: string;
  orderPlacedCopy?: string;
  searchBannerCopy?: string;
  useDealiciousTaxon?: boolean;
  toastCopy?: string;
};
type StoreCampaign = Omit<RequiredPath<StoreCampaignQuery, ['activeStoreCampaign']>, 'features'> & {
  features?: StoreCampaignFeatures;
};

type StoreCampaignPromo = RequiredPath<StoreCampaign, ['promoCode', 'promo']>;

export const EzRewardsPromosContext = createContext<{
  promo: StoreCampaignPromo | null | undefined;
  campaign: StoreCampaign | null | undefined;
  toastData: StoreCampaign | StoreCampaignPromo | null | undefined;
}>({promo: null, campaign: null, toastData: null});

export const useEzRewardsUserAssignedPromo = () => useContext(EzRewardsPromosContext);

type EzRewardsPromosProviderProps = React.PropsWithChildren<unknown>;

const EzRewardsPromosProvider: React.FC<EzRewardsPromosProviderProps> = ({children}) => {
  const {isOnMenuPage} = useStoreNextRouter();

  const {data: storeCampaignData} = useStoreCampaignQuery({
    errorPolicy: 'all',
  });

  const promo = useMemo(() => {
    const assignedPromo = storeCampaignData?.me?.consumerAccount?.assignedPromoCode;
    if (assignedPromo?.promo?.active) return assignedPromo.promo;

    return null;
  }, [storeCampaignData]);

  const features = (storeCampaignData?.activeStoreCampaign?.features ||
    {}) as StoreCampaignFeaturesJson;

  const campaign: StoreCampaign | null = storeCampaignData?.activeStoreCampaign
    ? {
        ...storeCampaignData?.activeStoreCampaign,
        features: {
          cta: features.cta,
          carouselCopy: features.carousel_copy,
          carouselTitle: features.carousel_title,
          enableCarousel: features.enable_carousel,
          finePrint: features.fine_print,
          menuBannerCopy: features.menu_banner_copy,
          orderPlacedCopy: features.order_placed_copy,
          searchBannerCopy: features.search_banner_copy,
          useDealiciousTaxon: features.use_dealicious_taxon,
          toastCopy: features.toast_copy,
        },
      }
    : null;

  const toastData = campaign?.features?.toastCopy && !isOnMenuPage ? campaign : promo;

  return (
    <EzRewardsPromosContext.Provider value={{promo, campaign, toastData}}>
      {children}
    </EzRewardsPromosContext.Provider>
  );
};

export default EzRewardsPromosProvider;

export type {StoreCampaignFeaturesJson};
