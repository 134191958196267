import {Button, Typography} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import {AllComponentsEnum, LoggedOutComponentsEnum} from '../componentNameEnums';

type LoggedOutOrderIssuesSelectorProps = {
  setStep: React.Dispatch<React.SetStateAction<keyof typeof AllComponentsEnum>>;
};

const LoggedOutOrderIssuesSelector: React.FC<LoggedOutOrderIssuesSelectorProps> = ({setStep}) => {
  const {t} = useTranslation('common');

  const clickNextStep = () => setStep(LoggedOutComponentsEnum.IssuesDetailSelection);

  return (
    <div className="m-6 tablet:m-12" data-testid="logged-out-order-issues-selector">
      <div className="flex flex-col tablet:flex-row">
        <div className="flex flex-col gap-4">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
          </Typography>
          <div>{t('components.SelfServiceReportingModal.LoggedOutOrderIssuesSelector.title')}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mr-2 leading-10 text-peppercorn-400">
          {t('components.SelfServiceReportingModal.stepOneOfThree')}
        </div>
        <Button onClick={clickNextStep} size="medium" variant="primary">
          {t('components.SelfServiceReportingModal.next')}
        </Button>
      </div>
    </div>
  );
};

export default LoggedOutOrderIssuesSelector;
