import {format, isDate, parse, parseISO, subDays, subMinutes} from 'date-fns';
import {enUS} from 'date-fns/locale/en-US';
import {formatInTimeZone, fromZonedTime} from 'date-fns-tz';
import {type Translate} from 'next-translate';
import invariant from 'tiny-invariant';

import {type AdvanceNotice, type Maybe, type Scalars} from '@/graphql/types';

export enum DateTimeFormats {
  DateYear = 'd, yyyy',
  DayLongDate = 'EEE, LLLL do, yyyy',
  DayLongDateWithTime = 'EEE, LLLL do, yyyy, h:mma',
  DayMonthDate = 'iii, LLL d',
  EzFieldDateFormat = 'MM/dd/yyyy',
  EzFieldTimeFormat = 'hh:mm aaa',
  HourWithMinutes = 'h:mm a',
  IsoDateFormat = 'yyyy-MM-dd',
  IsoTimeFormat = "HH:mm':00'",
  LongDate = 'LLLL d, yyyy',
  LongDateNoYear = 'LLLL d',
  MilitaryTimeFormat = 'HHmm',
  MonthDate = 'MMMM d, yyyy',
  MonthDateNoYear = 'MMMM d',
  ShortDate = 'L/d/yyyy',
  ShortDateTime = 'EEE L/d, h:mm a',
  ShortDayDateTimeWithTimeZone = 'EEE. MM/dd, h:mm aa z',
  ShortDayNameDateTime = "iii, MM/dd/yyyy 'at' h:mm a",
  ShortMonthDate = 'MM/dd',
  VerboseDate = 'iiii, LLLL do',
  VerboseDateTime = "iiii, LLLL do 'at' h:mm a",
  VerboseLongDateTime = 'h:mm a L/dd/yyyy',
  VerboseShortDate = 'iii MM/dd/yyyy',
}

/**
 * Reference to a default identifier for UTC time offset
 */
export const UTC_TIMEZONE = 'Etc/UTC' as const;

const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

export const isValidDate = (target: unknown): target is Date => isDate(target);

// Fri, 5/13, 1:30 PM
export const convertEventToReadable = (
  date: string | null,
  time: number | null,
  t: Translate,
): string => {
  if (date == null) return t('components.EventBar.dateTime.header.dateAndTime');
  if (time == null) return format(parseISO(date), 'EEE, L/d');

  const timestampDate = parse(`${time}`.padStart(4, '0'), 'HHmm', parseISO(date));
  const formattedDate = format(timestampDate, 'EEE, L/d');

  if (timestampDate.getHours() === 12 && timestampDate.getMinutes() === 0) {
    return `${formattedDate}, ${t('components.EventBar.dateTime.noon')}`;
  }
  return `${formattedDate}, ${format(timestampDate, 'h:mm a')}`;
};

export const convertMilitaryTimeToEzFieldTime = (time: number): string | null => {
  const parsedTime = parse(
    `${time}`.padStart(4, '0'),
    DateTimeFormats.MilitaryTimeFormat,
    new Date(),
  );
  try {
    return format(parsedTime, DateTimeFormats.EzFieldTimeFormat);
  } catch (_RangeError) {
    return '';
  }
};

export const convertEzFieldTimeToMilitaryTime = (
  time?: string | null,
): Scalars['TimeWithoutZone']['output'] | null => {
  if (time == null) return null;

  try {
    const dateTime = parse(time, DateTimeFormats.EzFieldTimeFormat, new Date());
    return convertDateToMilitaryTime(dateTime);
  } catch (_RangeError) {
    return null;
  }
};

export const convertEzFieldTimeToIsoTime = (
  time?: string | null,
): Scalars['LocalTime']['output'] | null => {
  if (time == null || time === '') return null;

  try {
    const parsedTime = parse(time, DateTimeFormats.EzFieldTimeFormat, new Date());
    return format(parsedTime, DateTimeFormats.IsoTimeFormat);
  } catch (_e) {
    return null;
  }
};

export const convertIsoDateToEzFieldDate = (date: Scalars['Date']['output'] | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parse(date, DateTimeFormats.IsoDateFormat, new Date());
    return format(parsedDate, DateTimeFormats.EzFieldDateFormat);
  } catch (_RangeError) {
    return '';
  }
};

export const convertIsoDateToShortDate = (date: string | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, DateTimeFormats.ShortDate);
  } catch (_RangeError) {
    return '';
  }
};

export const convertIsoDateToDayMonthDate = (date: string | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parseISO(date);
    return format(parsedDate, DateTimeFormats.DayMonthDate);
  } catch (_RangeError) {
    return '';
  }
};

export const convertIsoDateToShortDayDateTimeWithTimeZone = (date?: string | null): string => {
  if (date == null) return '';

  try {
    const parsedDate = parseISO(date);

    return formatInTimeZone(
      parsedDate,
      localTimeZone,
      DateTimeFormats.ShortDayDateTimeWithTimeZone,
      {locale: enUS},
    );
  } catch (_RangeError) {
    return '';
  }
};

export const convertEzFieldDateToIso = (date: string | null): Scalars['Date']['output'] | null => {
  if (date == null) return null;

  try {
    const parsedDate = parse(date, DateTimeFormats.EzFieldDateFormat, new Date());
    return format(parsedDate, DateTimeFormats.IsoDateFormat);
  } catch (_RangeError) {
    return null;
  }
};

export function convertIsoTimeToMilitaryTime(time: string): Scalars['Int']['output'];
export function convertIsoTimeToMilitaryTime(time?: null): null;
export function convertIsoTimeToMilitaryTime(
  time?: string | null,
): Scalars['Int']['output'] | null {
  if (time == null) return null;

  const [hour, minute] = time.split(':');
  return parseInt(`${hour}${minute}`, 10);
}

export const convertMilitaryTimeToIsoTime = (
  time: number | string,
): Scalars['LocalTime']['output'] => {
  const paddedTime = `${time}`.padStart(4, '0');
  return `${paddedTime.slice(0, 2)}:${paddedTime.slice(2, 4)}:00`;
};

const convertDateToMilitaryTime = (date: Date): Scalars['Int']['output'] =>
  parseInt(format(date, DateTimeFormats.MilitaryTimeFormat), 10);

type IsPastEventArgs = {
  eventOn?: string;
  eventLocalTime?: number;
};

export const isEventPast = ({eventOn, eventLocalTime}: IsPastEventArgs): boolean => {
  if (!eventOn) {
    return false;
  }

  if (eventOn && eventLocalTime) {
    const eventLocalTimeInIsoFormat = convertMilitaryTimeToIsoTime(eventLocalTime);
    const parsed = parse(
      `${eventOn}${eventLocalTimeInIsoFormat}`,
      'yyyy-MM-ddHH:mm:ss',
      new Date(),
    );
    return parsed < new Date();
  }

  return parseISO(eventOn) < new Date();
};

type EventTimestamp = {
  eventOn: string;
  eventLocalTime?: Maybe<Scalars['LocalTime']['output']>;
};

export const convertLocalEventTimeToDate = (
  {eventOn, eventLocalTime}: EventTimestamp,
  tz?: string,
) => {
  return fromZonedTime(`${eventOn} ${eventLocalTime}`, tz ?? UTC_TIMEZONE);
};

/**
 * Returns a timestamp for the advance notice cutoff given some event date as a date object
 *
 * @param notice - the notice object
 * @param datetime - the date of the event, as a JavaScript Date object
 * @param timezone - the timezone offset, generally referenced via caterer
 */
export function convertAdvanceNoticeToDate(
  notice: AdvanceNotice | null,
  eventAt: Date | null,
  timezone?: string,
): Date | null {
  if (!notice || !eventAt || !timezone) return null;

  if (notice.__typename === 'LeadTime') {
    return subMinutes(eventAt, notice.leadTimeInMinutes);
  }

  invariant(notice.__typename === 'DayBeforeCutoffTime', 'Invalid notice type');

  // get the localized event date, this is necessary because the UTC date might be different if near midnight
  const dayBeforeDateString = formatInTimeZone(subDays(eventAt, 1), timezone, 'yyyy-MM-dd');
  // convert the cutoff time to a more easily parsable format, e.g. 1245 -> 12:45:00
  const cutoffTime = format(
    parse(
      notice.dayBeforeCutoffTime.toString().padStart(4, '0'),
      DateTimeFormats.MilitaryTimeFormat,
      new Date(),
    ),
    'HH:mm:00',
  );
  return fromZonedTime(`${dayBeforeDateString} ${cutoffTime}`, timezone);
}

export const convertTimestampToVerboseShortDate = (timestamp: string) =>
  format(new Date(timestamp), DateTimeFormats.VerboseShortDate);

// Converts a time in a date object to a string
// Ex. 2007-08-01T12:00:00.000Z -> '12:00:00'
export const convertTimeDateToString = (time?: Date | null) => {
  return time ? format(time, 'HH:mm:ss') : null;
};

// Converts a time string to a date object with today's date
// Ex. '12:00:00' -> 2007-08-01T12:00:00.000Z
export const convertTimeStringToDate = (time?: string | null, date?: Date | null) => {
  return time ? parse(time, 'HH:mm:ss', date || new Date()) : null;
};

// Converts a date in a date object to a string
// Ex. 2007-08-01T12:00:00.000Z -> '2007-08-01'
export const convertDateDateToString = (date?: Date | null) => {
  return date ? format(date, 'yyyy-MM-dd') : null;
};

// Converts a date string to a date object
// Ex. '2007-08-01' -> 2007-08-01T00:00:00.000Z
export const convertDateStringToDate = (date?: string | null) => {
  return date ? parseISO(date) : null;
};
