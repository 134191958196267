import {Button, Link, Typography} from '@ezcater/tapas';
import Trans from 'next-translate/Trans';
import useTranslation from 'next-translate/useTranslation';

import {useLibertyWebchat} from '@/components/LibertyWebchatProvider';
import useEzCaterCompanyInfo from '@/hooks/useEzCaterCompanyInfo';
import useIsMobileCheck from '@/hooks/useIsMobileCheck';
import useSelfServiceReportingModal from '@/hooks/useSelfServiceReportingModal';

const ContactCustomerSupport = () => {
  const {t} = useTranslation('common');
  const isMobile = useIsMobileCheck();
  const {supportPhoneNumber} = useEzCaterCompanyInfo();
  const {toggleSelfServiceProviderModal} = useSelfServiceReportingModal();
  const {toggleWebchatVisibility} = useLibertyWebchat();

  const toggleLibertyChat = () => {
    toggleSelfServiceProviderModal();
    toggleWebchatVisibility();
  };

  const chatWithUsLink = isMobile ? (
    <Link
      href="http://liberty-webchat.ezcater.com/?open=true&utm_channel=web&utm_campaign=my_account_orders"
      rel="noopener noreferrer"
      target="_blank"
    >
      {t('components.SelfServiceReportingModal.SubmissionConfirmation.chatWithUs')}
    </Link>
  ) : (
    <Link onClick={toggleLibertyChat}>
      {t('components.SelfServiceReportingModal.SubmissionConfirmation.chatWithUs')}
    </Link>
  );

  return (
    <div className="m-6 tablet:m-12" data-testid="contact-customer-support">
      <div className="flex flex-col tablet:flex-row">
        <div className="flex flex-col gap-4">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
          </Typography>
          <div>
            <p>
              <Trans
                i18nKey="common:components.SelfServiceReportingModal.reportAnIssue"
                components={{
                  link: chatWithUsLink,
                }}
              />
            </p>
            <p>
              <Trans
                i18nKey="common:components.SelfServiceReportingModal.immediateHelp"
                components={{
                  phoneNumberLink: (
                    <Link href={`tel:${supportPhoneNumber}`}>{supportPhoneNumber}</Link>
                  ),
                }}
              />
            </p>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={() => toggleSelfServiceProviderModal()} size="medium" variant="outlined">
          {t('components.SelfServiceReportingModal.close')}
        </Button>
      </div>
    </div>
  );
};

export default ContactCustomerSupport;
