import {useCallback, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import useTranslation from 'next-translate/useTranslation';
import invariant from 'tiny-invariant';

import {
  type UpdateSearchFulfillmentDetailMutationVariables,
  useUpdateSearchFulfillmentDetailMutation,
} from '@/graphql/types';
import {useSearchPageData} from '@/pageComponents/search-order-id/SearchPageProvider';
import extractUneditableFulfillmentDetailPath from '@/pageUtils/caterer-search/extractUneditableFulfillmentDetailPath';
import {STORE_FLASH_MESSAGES} from '../CookieMessages';
import useRedirect from '../RedirectionProvider/useRedirect';
import {
  GlobalFulfillmentDetailMutationContext,
  type GlobalFulfillmentDetailMutationContextType,
  useGlobalFulfillmentDetail,
} from './GlobalFulfillmentDetailProvider';

const SearchFulfillmentDetailMutationProvider: React.FC<
  React.PropsWithChildren<Partial<UpdateSearchFulfillmentDetailMutationVariables>>
> = ({children}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [, setCookie] = useCookies([STORE_FLASH_MESSAGES]);
  const {fulfillmentDetail} = useGlobalFulfillmentDetail();
  const searchPageData = useSearchPageData();
  const {t} = useTranslation('common');
  const redirect = useRedirect();

  const [searchFulfillmentDetailMutation] = useUpdateSearchFulfillmentDetailMutation();

  const fulfillmentDetailId = fulfillmentDetail?.id;
  const uneditablePath = extractUneditableFulfillmentDetailPath(searchPageData!);
  const updateFulfillmentDetail = useCallback<
    GlobalFulfillmentDetailMutationContextType['updateFulfillmentDetail']
  >(
    async input => {
      invariant(fulfillmentDetailId != null, 'Fulfillment detail must be defined');

      const {data, errors} = await searchFulfillmentDetailMutation({
        variables: {
          id: fulfillmentDetailId,
          input,
        },
      });

      const userErrors = data?.fulfillmentDetailUpdate?.userErrors;

      if (userErrors) {
        userErrors.forEach(dataError => {
          switch (dataError.__typename) {
            case 'EntityLocked':
            case 'EntityNotFound': {
              setCookie(STORE_FLASH_MESSAGES, `error=${t('errors.entityNotFound')}`);

              // Reroute to the canonical search page using the search address
              redirect(uneditablePath);

              throw new Error(dataError.__typename);
            }
            case 'ValidationError':
              setErrorMessage(dataError.message);
              throw new Error(`${dataError.__typename}: ${dataError.message}`);
            default:
              throw new Error(
                `Unknown error received: ${(dataError as {__typename: string}).__typename}`,
              );
          }
        });
      }

      return {
        fulfillmentDetail: data?.fulfillmentDetailUpdate?.fulfillmentDetail || null,
        userErrors,
        errors,
      };
    },
    [fulfillmentDetailId, redirect, searchFulfillmentDetailMutation, setCookie, t, uneditablePath],
  );

  return (
    <GlobalFulfillmentDetailMutationContext.Provider
      value={useMemo(
        () => ({errorMessage, updateFulfillmentDetail}),
        [errorMessage, updateFulfillmentDetail],
      )}
    >
      {children}
    </GlobalFulfillmentDetailMutationContext.Provider>
  );
};

export default SearchFulfillmentDetailMutationProvider;
