import {createContext, useEffect, useMemo, useState} from 'react';

import useNewAppBarExperience from '@/hooks/useNewAppBarExperience';
import {FiltersUpdateSource} from './utils/types';
import useFilters from './utils/useFilters';

// This exists so that multiple instance of <KeywordSearch /> can be on the page
// and stay in sync with each other
// (ie one is mobile, another is desktop, components hidden/shown with css)
const KeywordSearchProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {filters, updateFilters, shouldClearKeyword} = useFilters();
  const [workingValue, setWorkingValue] = useState(filters.keyword);
  const [debouncedValue, setDebouncedValue] = useState(filters.keyword);
  const {shouldShowNewAppBar} = useNewAppBarExperience();

  useEffect(() => {
    if (!shouldShowNewAppBar) {
      if (shouldClearKeyword.current) {
        setDebouncedValue(filters.keyword);
        setWorkingValue(filters.keyword);
        shouldClearKeyword.current = false;
      } else if (filters.keyword !== debouncedValue) {
        updateFilters({keyword: debouncedValue}, FiltersUpdateSource.Keyword);
      }
    }
  }, [
    debouncedValue,
    updateFilters,
    filters.keyword,
    setDebouncedValue,
    setWorkingValue,
    shouldClearKeyword,
    shouldShowNewAppBar,
  ]);

  return (
    <KeywordSearchContext.Provider
      value={useMemo(
        () => ({debouncedValue, setDebouncedValue, setWorkingValue, workingValue}),
        [debouncedValue, workingValue],
      )}
    >
      {children}
    </KeywordSearchContext.Provider>
  );
};

export default KeywordSearchProvider;

export const KeywordSearchContext = createContext<{
  debouncedValue: string;
  setDebouncedValue: (value: string) => void;
  setWorkingValue: (value: string) => void;
  workingValue: string;
} | null>(null);
