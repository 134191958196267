import {InMemoryCache} from '@apollo/client';

const createApolloCache = (): InMemoryCache =>
  new InMemoryCache({
    typePolicies: {
      Caterer: {
        fields: {
          publicCatererFulfillmentReportCard: {
            merge(existing, incoming, {mergeObjects}) {
              return mergeObjects(existing, incoming);
            },
          },
          advanceNotice: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      ConsumerCart: {
        fields: {
          customerMessages: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          items: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
          lineItems: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      CatererSearchResult: {
        keyFields: ['cacheKey'],
      },
      FulfillmentDetail: {
        fields: {
          customerMessages: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
      Image: {
        keyFields: ['uuid'],
      },
      OrderingMenu: {
        keyFields: ['cacheKey'],
      },
      OrderingMenuItem: {
        fields: {
          quantityRules: {
            merge(existing, incoming, {mergeObjects}) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
      Query: {
        fields: {
          constants: {
            merge(existing, incoming, {mergeObjects}) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      },
    },
  });

export default createApolloCache;
