import {useEffect} from 'react';
import {Button, Checkbox, Radio, Select, SelectOption, TextField, Typography} from '@ezcater/tapas';
import {Field, FormikErrors} from 'formik';
import useTranslation from 'next-translate/useTranslation';
import {twJoin} from 'tailwind-merge';

import {
  deliveryTimeOptions,
  deliveryTimeTooEarlyOptions,
  deliveryTimeTooLateOptions,
  foodQualityOrPreparationOptions,
  missingOrIncorrectItemsOptions,
  natureOfIssueCheckboxFields,
  problemWithDriverOptions,
} from '@/components/SelfServiceReportingModal/SelfServiceReportingConstants';
import {
  ADDITIONAL_DETAILS_MAX_TEXT_LENGTH,
  FormValues,
  SOMETHING_ELSE_MAX_TEXT_LENGTH,
} from '@/components/SelfServiceReportingModal/SelfServiceReportingModal';
import {DeliveryTimeSelection, IssueSelection, NatureOfIssue} from '@/graphql/types';
import useTracking from '@/hooks/useTracking';

export type DeliveryTimeSelectionOption = {name: string; value: DeliveryTimeSelection};

type IssuesDetailSelectionProps = {
  deliveryTimeSelection: DeliveryTimeSelection | null;
  errors: FormikErrors<FormValues>;
  issuesDetailSelectionAdditionalDetails: string;
  issuesSelected: IssueSelection[];
  natureOfIssue: NatureOfIssue | null;
  setFieldValue: (field: string, value: any) => void;
  setNextStep: () => void;
  setPreviousStep: () => void;
  somethingElseField: string;
};

const IssuesDetailSelection: React.FC<IssuesDetailSelectionProps> = ({
  deliveryTimeSelection,
  errors,
  issuesDetailSelectionAdditionalDetails,
  issuesSelected,
  natureOfIssue,
  setFieldValue,
  setNextStep,
  setPreviousStep,
  somethingElseField,
}) => {
  const {t} = useTranslation('common');
  const {track} = useTracking();

  useEffect(() => {
    track('self-service-reporting-modal-issues-detail-selection-viewed');
  }, [track]);

  const checkboxInputsMap: Record<string, any[]> = {
    [NatureOfIssue.MissingOrIncorrectItems]: missingOrIncorrectItemsOptions,
    [NatureOfIssue.FoodQualityOrPreparation]: foodQualityOrPreparationOptions,
    [NatureOfIssue.ProblemWithDriver]: problemWithDriverOptions,
  };

  const titlesMap: Record<string, string> = {
    [NatureOfIssue.MissingOrIncorrectItems]:
      'components.SelfServiceReportingModal.IssuesDetailSelection.missingOrIncorrectItemsTitle',
    [NatureOfIssue.FoodQualityOrPreparation]:
      'components.SelfServiceReportingModal.IssuesDetailSelection.foodQualityOrPreparationTitle',
    [NatureOfIssue.ProblemWithDriver]:
      'components.SelfServiceReportingModal.IssuesDetailSelection.problemWithDriverTitle',
    [NatureOfIssue.DeliveryTime]:
      'components.SelfServiceReportingModal.IssuesDetailSelection.deliveryTimeTitle',
    [NatureOfIssue.SomethingElse]:
      'components.SelfServiceReportingModal.IssuesDetailSelection.somethingElseTitle',
  };

  // all missingOrIncorrectItems values and 'something_else' are eligible for additional details TextField
  const allMissingOrIncorrectItemsValues = missingOrIncorrectItemsOptions.map(({value}) => value);
  const eligibleAdditionalDetails = allMissingOrIncorrectItemsValues.concat([
    IssueSelection.SomethingElse,
  ]);

  const somethingElseSuffix = (
    <div
      className={
        somethingElseField.length > SOMETHING_ELSE_MAX_TEXT_LENGTH
          ? 'text-guava-400'
          : 'text-peppercorn-400'
      }
      data-testid="something-else-suffix"
    >
      {somethingElseField.length}/{SOMETHING_ELSE_MAX_TEXT_LENGTH}
    </div>
  );

  const handleSomethingElseChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value.length <= SOMETHING_ELSE_MAX_TEXT_LENGTH) {
      setFieldValue('somethingElseField', value);
    }
  };

  const hasSomethingElseFieldErrors =
    Boolean(errors?.somethingElseField) ||
    somethingElseField.length >= SOMETHING_ELSE_MAX_TEXT_LENGTH;

  const additionalDetailsSuffix = (
    <div
      className={
        issuesDetailSelectionAdditionalDetails.length > ADDITIONAL_DETAILS_MAX_TEXT_LENGTH
          ? 'text-guava-400'
          : 'text-peppercorn-400'
      }
      data-testid="additional-details-suffix"
    >
      {issuesDetailSelectionAdditionalDetails.length}/{ADDITIONAL_DETAILS_MAX_TEXT_LENGTH}
    </div>
  );

  const handleAdditionalDetailsChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const value = event.target.value;
    if (value.length <= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH) {
      setFieldValue('issuesDetailSelectionAdditionalDetails', value);
    }
  };

  const hasIssuesDetailSelectionAdditionalDetailsErrors =
    Boolean(errors?.issuesDetailSelectionAdditionalDetails) ||
    issuesDetailSelectionAdditionalDetails.length >= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH;

  const tooLateOrEarly = (earlyOrLate: string) => {
    const options =
      earlyOrLate === 'early' ? deliveryTimeTooEarlyOptions : deliveryTimeTooLateOptions;
    return (
      <div className="ml-6 mt-2">
        <Typography className="mb-2" variant="title-sm">
          {t('components.SelfServiceReportingModal.IssuesDetailSelection.howWasYourDelivery', {
            earlyOrLate: earlyOrLate,
          })}
        </Typography>
        <Select<DeliveryTimeSelectionOption>
          className={twJoin(
            'w-full',
            errors?.deliveryTimeSelection &&
              'border-guava-400 hover:border-guava-500 active:border-guava-500',
          )}
          name="deliveryTimeSelection"
          onChange={(_event, newValue) => setFieldValue('deliveryTimeSelection', newValue)}
          value={deliveryTimeSelection as unknown as DeliveryTimeSelectionOption}
        >
          {options.map(({name, value}) => (
            <SelectOption key={value} value={value}>
              {name}
            </SelectOption>
          ))}
        </Select>
        {errors?.deliveryTimeSelection && (
          <div className="mt-2 flex items-center text-guava-400">
            <Typography variant="body-sm">
              {t('components.SelfServiceReportingModal.validation.selectionRequired')}
            </Typography>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="m-6 tablet:m-12" data-testid="issues-detail-selection">
      <div className="mb-8">
        <div className="flex flex-col gap-4">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
          </Typography>
          <div className="empty:hidden">{natureOfIssue && t(titlesMap[natureOfIssue])}</div>
          <div className="flex flex-col gap-2">
            {natureOfIssue && natureOfIssueCheckboxFields.includes(natureOfIssue) && (
              <>
                <Typography variant="body-sm" className="font-bold">
                  {t(
                    'components.SelfServiceReportingModal.IssuesDetailSelection.chooseAllThatApply',
                  )}
                </Typography>
                {checkboxInputsMap[natureOfIssue].map(({name, value}) => (
                  <label
                    key={value}
                    className="mb-1 flex w-fit cursor-pointer items-center gap-2 has-[:disabled]:cursor-default"
                  >
                    <Field type="checkbox" name="issuesSelected" value={value}>
                      {({
                        field,
                      }: {
                        field: {
                          name: string;
                          value: any;
                          onChange: (e: React.ChangeEvent<any>) => void;
                          onBlur: (e: React.FocusEvent<any>) => void;
                        };
                      }) => <Checkbox {...field} checked={issuesSelected.includes(value)} />}
                    </Field>
                    {name}
                  </label>
                ))}
                {eligibleAdditionalDetails.some(detail => issuesSelected.includes(detail)) && (
                  <div className="mt-2">
                    <Typography variant="body-md">
                      {t(
                        'components.SelfServiceReportingModal.IssuesDetailSelection.additionalDetails',
                      )}
                    </Typography>
                    <Typography className="my-2 font-bold" variant="body-sm">
                      {t(
                        'components.SelfServiceReportingModal.IssuesDetailSelection.moreInformation',
                      )}
                    </Typography>
                    <TextField
                      as="textarea"
                      error={hasIssuesDetailSelectionAdditionalDetailsErrors}
                      onInput={handleAdditionalDetailsChange}
                      rows={3}
                      suffix={additionalDetailsSuffix}
                      value={issuesDetailSelectionAdditionalDetails}
                    />
                    {hasIssuesDetailSelectionAdditionalDetailsErrors && (
                      <div className="mt-2 flex items-center text-guava-400">
                        <Typography variant="body-sm">
                          {errors?.issuesDetailSelectionAdditionalDetails ||
                            t(
                              'components.SelfServiceReportingModal.validation.shortenAdditionalDetailsDescription',
                            )}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
            {natureOfIssue === NatureOfIssue.DeliveryTime && (
              <>
                {deliveryTimeOptions.map(({name, value}) => (
                  <div key={value}>
                    <label className="flex w-fit cursor-pointer items-center gap-2 has-[:disabled]:cursor-default">
                      <Field type="radio" name="issuesSelected" value={value}>
                        {({
                          field,
                        }: {
                          field: {
                            name: string;
                            value: any;
                            onChange: (e: React.ChangeEvent<any>) => void;
                            onBlur: (e: React.FocusEvent<any>) => void;
                          };
                        }) => <Radio {...field} checked={issuesSelected.includes(value)} />}
                      </Field>
                      {name}
                    </label>
                    {value === IssueSelection.TooLate &&
                      issuesSelected.includes(value) &&
                      tooLateOrEarly('late')}
                    {value === IssueSelection.TooEarly &&
                      issuesSelected.includes(value) &&
                      tooLateOrEarly('early')}
                  </div>
                ))}
              </>
            )}
            {natureOfIssue === NatureOfIssue.SomethingElse && (
              <>
                <TextField
                  aria-label="multiline textarea"
                  as="textarea"
                  error={hasSomethingElseFieldErrors}
                  onInput={handleSomethingElseChange}
                  rows={3}
                  suffix={somethingElseSuffix}
                  value={somethingElseField}
                />
                {hasSomethingElseFieldErrors && (
                  <div className="flex items-center text-guava-400">
                    <Typography variant="body-sm">
                      {errors?.somethingElseField ||
                        t(
                          'components.SelfServiceReportingModal.validation.shortenIssueDescription',
                        )}
                    </Typography>
                  </div>
                )}
              </>
            )}
            <Typography variant="body-sm" className="text-guava-400 empty:hidden">
              {errors?.issuesSelected}
            </Typography>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mr-4 leading-10 text-peppercorn-400">
          {t('components.SelfServiceReportingModal.stepTwoOfThree')}
        </div>
        <Button className="mr-2" onClick={setPreviousStep} size="medium" variant="outlined">
          {t('components.SelfServiceReportingModal.back')}
        </Button>
        <Button onClick={setNextStep} size="medium" variant="primary">
          {t('components.SelfServiceReportingModal.next')}
        </Button>
      </div>
    </div>
  );
};

export default IssuesDetailSelection;
