import {type Address as SavedAddress, type GeocodedAddress} from '@/utils/googleAutocomplete';

type AddressInputProps = {
  fetchGeocodedAddress: (addressString: string) => Promise<GeocodedAddress[]>;
  values: {
    addressString: string;
    geocodedAddressResult: any;
    savedAddress: SavedAddress | null;
  };
};

const getAddressInput = async ({fetchGeocodedAddress, values}: AddressInputProps) => {
  if (values.savedAddress) {
    // Is a saved address
    return {addressId: values.savedAddress.placeId};
  } else if (values.geocodedAddressResult) {
    // Is a geocoded address
    return {
      address: {
        street1: values.geocodedAddressResult.street,
        street2: values.geocodedAddressResult.street2,
        city: values.geocodedAddressResult.city,
        state: values.geocodedAddressResult.state,
        zip: values.geocodedAddressResult.zip,
        latitude: values.geocodedAddressResult.latitude,
        longitude: values.geocodedAddressResult.longitude,
      },
    };
  } else {
    try {
      // Is an address string, geocode it first
      const geocodedAddress = await fetchGeocodedAddress(values?.addressString);
      if (geocodedAddress.length > 1) {
        return geocodedAddress;
      } else {
        // Return the geocoded address
        const [address] = geocodedAddress;
        return {
          address: {
            street1: address.street,
            street2: address.street2,
            city: address.city,
            state: address.state,
            zip: address.zip,
            latitude: address.latitude,
            longitude: address.longitude,
          },
        };
      }
    } catch (_error) {
      return null;
    }
  }
};

export default getAddressInput;
