// @ts-strict-ignore
import {EzFlashMessage} from '@ezcater/recipe';
import {Link} from '@ezcater/tapas';

import useFlashMessage from '@/hooks/useFlashMessage';
import useTracking from '@/hooks/useTracking';

const FlashMessage = () => {
  const {message, setMessage} = useFlashMessage();
  const {trackClick} = useTracking();

  if (!Object.keys(message).length || !message.text || !message.use) return null;

  const onAutohide = () => setMessage({});

  const kebabCase = (string: string) =>
    string
      .replace(/([a-z])([A-Z])/g, '$1-$2')
      .replace(/[\s_]+/g, '-')
      .toLowerCase();

  return (
    <div className="fixed bottom-5 left-3 z-50 tablet:left-5">
      <EzFlashMessage
        autohide
        autohideDuration={message.duration || 4000}
        onAutohide={onAutohide}
        use={message.use}
        headline={message.headline}
      >
        {message.text}

        {message.linkText && message.href && (
          <Link
            href={message.href}
            onClick={() => {
              trackClick(
                `flash-message-link-${kebabCase(message.linkText)}`,
                message.linkClickMiscJson
                  ? {misc_json: JSON.stringify(message.linkClickMiscJson)}
                  : null,
              );
            }}
            size="medium"
            className="block font-bold text-peppercorn-800 underline hover:font-extrabold hover:text-peppercorn-800 desktop:ml-6 desktop:mt-1 desktop:inline"
          >
            {message.linkText}
          </Link>
        )}
      </EzFlashMessage>
    </div>
  );
};

export default FlashMessage;
