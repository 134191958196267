import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import getConfig from 'next/config';

import useSelfServiceReportingModal from '@/hooks/useSelfServiceReportingModal';
import useTracking from '@/hooks/useTracking';

const {publicRuntimeConfig} = getConfig();
const libertyWebchatHost =
  publicRuntimeConfig.libertyWebchatHost || 'https://liberty-webchat.staging-ezcater.com';

export const LibertyWebchatContext = createContext<{
  webchatEnabled: boolean;
  toggleWebchatVisibility: () => void;
  isWebchatWidgetOpen: boolean;
  iframeEl: React.MutableRefObject<HTMLIFrameElement | null>;
  host: string;
}>({
  webchatEnabled: false,
  toggleWebchatVisibility() {},
  isWebchatWidgetOpen: false,
  iframeEl: {current: null},
  host: 'https://liberty-webchat.staging-ezcater.com',
});

export const useLibertyWebchat = () => useContext(LibertyWebchatContext);

type LibertyWebchatMessage =
  | {action: 'afterToggleChatVisibility'; isEntryPointExpanded: boolean}
  | {action: 'openFulfillmentIssueModal'};

type LibertyWebchatProviderProps = React.PropsWithChildren<{
  webchatEnabled?: boolean;
}>;

const LibertyWebchatProvider: React.FC<LibertyWebchatProviderProps> = ({
  webchatEnabled = false,
  children,
}) => {
  const iframeEl = useRef<HTMLIFrameElement | null>(null);
  const toggleWebchatVisibility = useCallback(() => {
    iframeEl?.current?.contentWindow?.postMessage(
      {
        action: 'ToggleChatVisibility',
      },
      '*',
    );
  }, []);
  const [isWebchatWidgetOpen, setIsWebchatWidgetOpen] = useState(false);
  const {toggleSelfServiceProviderModal} = useSelfServiceReportingModal();
  const {track} = useTracking();

  useEffect(() => {
    const trackSelfServiceIssueModal = () => {
      track('self-service-reporting-modal-opened', {
        page: window.location.pathname,
      });
    };

    const handleMessage = (event: MessageEvent<LibertyWebchatMessage>) => {
      if (event.origin === libertyWebchatHost) {
        if (event.data.action === 'afterToggleChatVisibility') {
          setIsWebchatWidgetOpen(event.data.isEntryPointExpanded);
        } else if (event.data.action === 'openFulfillmentIssueModal') {
          toggleSelfServiceProviderModal();
          toggleWebchatVisibility();
          trackSelfServiceIssueModal();
        }
      }
    };

    window.addEventListener('message', handleMessage, {passive: true});
    return () => window.removeEventListener('message', handleMessage);
  }, [track, toggleSelfServiceProviderModal, toggleWebchatVisibility]);

  const providerValue = useMemo(
    () => ({
      webchatEnabled,
      toggleWebchatVisibility,
      isWebchatWidgetOpen,
      iframeEl,
      host: libertyWebchatHost,
    }),
    [webchatEnabled, toggleWebchatVisibility, isWebchatWidgetOpen, iframeEl],
  );

  return (
    <LibertyWebchatContext.Provider value={providerValue}>
      {children}
    </LibertyWebchatContext.Provider>
  );
};

export default LibertyWebchatProvider;
