export enum CuisineTaxonEnum {
  African = 'AFRICAN',
  American = 'AMERICAN',
  Asian = 'ASIAN',
  Bagels = 'BAGELS',
  Bakery = 'BAKERY',
  Bbq = 'BBQ',
  Bowls = 'BOWLS',
  Breakfast = 'BREAKFAST',
  Burgers = 'BURGERS',
  Cajun = 'CAJUN',
  Caribbean = 'CARIBBEAN',
  Chicken = 'CHICKEN',
  Chinese = 'CHINESE',
  CoffeeTea = 'COFFEE_TEA',
  Cuban = 'CUBAN',
  Deli = 'DELI',
  Diner = 'DINER',
  EasternEuropeanRussian = 'EASTERN_EUROPEAN_RUSSIAN',
  Filipino = 'FILIPINO',
  French = 'FRENCH',
  German = 'GERMAN',
  Greek = 'GREEK',
  Hawaiian = 'HAWAIIAN',
  Healthy = 'HEALTHY',
  Indian = 'INDIAN',
  Irish = 'IRISH',
  Italian = 'ITALIAN',
  Jamaican = 'JAMAICAN',
  Japanese = 'JAPANESE',
  Korean = 'KOREAN',
  Mediterranean = 'MEDITERRANEAN',
  Mexican = 'MEXICAN',
  MiddleEastern = 'MIDDLE_EASTERN',
  Peruvian = 'PERUVIAN',
  Pizza = 'PIZZA',
  Poke = 'POKE',
  Salads = 'SALADS',
  Sandwiches = 'SANDWICHES',
  Seafood = 'SEAFOOD',
  SmoothiesFruitBowls = 'SMOOTHIES_FRUIT_BOWLS',
  Snacks = 'SNACKS',
  SoulFood = 'SOUL_FOOD',
  Soups = 'SOUPS',
  Southern = 'SOUTHERN',
  SouthLatinAmerican = 'SOUTH_LATIN_AMERICAN',
  SpanishTapas = 'SPANISH_TAPAS',
  Steakhouse = 'STEAKHOUSE',
  Sushi = 'SUSHI',
  Thai = 'THAI',
  Treats = 'TREATS',
  Vegan = 'VEGAN',
  Vegetarian = 'VEGETARIAN',
  Vietnamese = 'VIETNAMESE',
}

export const mostPopularCuisines: CuisineTaxonEnum[] = [
  CuisineTaxonEnum.Asian,
  CuisineTaxonEnum.Bbq,
  CuisineTaxonEnum.Breakfast,
  CuisineTaxonEnum.Italian,
  CuisineTaxonEnum.Mediterranean,
  CuisineTaxonEnum.Mexican,
  CuisineTaxonEnum.Pizza,
  CuisineTaxonEnum.Sandwiches,
];

export const allOtherCuisines: CuisineTaxonEnum[] = [
  CuisineTaxonEnum.African,
  CuisineTaxonEnum.American,
  CuisineTaxonEnum.Bagels,
  CuisineTaxonEnum.Bakery,
  CuisineTaxonEnum.Bowls,
  CuisineTaxonEnum.Burgers,
  CuisineTaxonEnum.Cajun,
  CuisineTaxonEnum.Caribbean,
  CuisineTaxonEnum.Chicken,
  CuisineTaxonEnum.Chinese,
  CuisineTaxonEnum.CoffeeTea,
  CuisineTaxonEnum.Cuban,
  CuisineTaxonEnum.Deli,
  CuisineTaxonEnum.Treats, // this reads "Desserts" in the UI
  CuisineTaxonEnum.Diner,
  CuisineTaxonEnum.EasternEuropeanRussian,
  CuisineTaxonEnum.French,
  CuisineTaxonEnum.German,
  CuisineTaxonEnum.Greek,
  CuisineTaxonEnum.Healthy,
  CuisineTaxonEnum.Indian,
  CuisineTaxonEnum.Korean,
  CuisineTaxonEnum.MiddleEastern,
  CuisineTaxonEnum.Vegan, // this reads "Plant-Based" in the UI
  CuisineTaxonEnum.Poke,
  CuisineTaxonEnum.Salads,
  CuisineTaxonEnum.Seafood,
  CuisineTaxonEnum.SmoothiesFruitBowls,
  CuisineTaxonEnum.Snacks,
  CuisineTaxonEnum.Soups,
  CuisineTaxonEnum.Southern,
  CuisineTaxonEnum.SouthLatinAmerican,
  CuisineTaxonEnum.SpanishTapas,
  CuisineTaxonEnum.Steakhouse,
  CuisineTaxonEnum.Sushi,
  CuisineTaxonEnum.Thai,
  CuisineTaxonEnum.Vietnamese,
];
