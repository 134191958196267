type TrackerTypeAtom = 'string' | 'number' | 'boolean' | 'null';
type EventDictionary = {
  namespace: 'Store';
  events: Record<
    string,
    {
      description: string;
      properties?: Record<string, {description: string; type: TrackerTypeAtom | TrackerTypeAtom[]}>;
    }
  >;
};

const eventDictionary = {
  namespace: 'Store',
  events: {
    'braintree-client-initialization-began': {
      description: 'This event fires when initialization of Braintree hosted fields begins',
    },
    'braintree-client-initialized': {
      description:
        'This event fires when Braintree hosted fields have been successfully initialized',
    },
    'braintree-client-initialization-timed-out': {
      description:
        'This event fires when Braintree hosted fields time out while trying to initialize',
    },
    'braintree-client-initialization-retry': {
      description: 'This event fires when Braintree hosted fields initialization is retried',
      properties: {
        status: {
          description: 'Displays the duration since first initialization attempt',
          type: 'string',
        },
      },
    },
    'braintree-client-error': {
      description: 'This event occurs when there is an error from the Braintree client API',
      properties: {
        source: {
          description: 'Error code',
          type: 'string',
        },
      },
    },
    'braintree-data-collector-error': {
      description: 'This event occurrs when the Braintree data collector fails to initialize',
      properties: {
        source: {
          description: 'Error code',
          type: 'string',
        },
        value: {
          description: 'Error message',
          type: 'string',
        },
      },
    },
    'confirmed-redeem-gift-card': {
      description: 'user elected to redeem a gift card',
      properties: {
        misc_json: {
          description: 'Contains information about points redeemed',
          type: 'string',
        },
      },
    },
    'canceled-redeem-gift-card': {
      description: 'user elected not to redeem a gift card',
      properties: {
        misc_json: {
          description: 'Contains information about points that could have been redeemed',
          type: 'string',
        },
      },
    },
    'page-view': {
      description: 'A user viewed a page',
      properties: {
        page: {
          description: 'The page that has been viewed',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'ui element clicked': {
      description: 'This is an event tracking when a ui element on the page is clicked',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        ui_element_name: {
          description: 'The item element that was clicked',
          type: 'string',
        },
        misc_json: {
          description: 'Contains any other properties relevant to the click',
          type: ['string', 'null'],
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'recommendation-shown': {
      description: 'User was shown a recommendation',
      properties: {
        misc_json: {
          description: 'Shown recommendation details',
          type: 'string',
        },
      },
    },
    'recommendation-clicked': {
      description: 'User clicked a recommendation',
      properties: {
        misc_json: {
          description: 'Clicked recommendation details',
          type: 'string',
        },
      },
    },
    'recommendation-dismissed': {
      description: 'User dismissed a recommendation',
      properties: {
        misc_json: {
          description: 'Dismissed recommendation details',
          type: 'string',
        },
      },
    },
    'recommended-search-clicked': {
      description: 'User clicked a recommended search',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Clicked on default search recommendations',
          type: 'string',
        },
      },
    },
    'cart-tip-component-unhidden': {
      description: 'User scrolled the cart after the tip became invisible',
      properties: {
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'User agent info',
          type: 'string',
        },
        misc_json: {
          description:
            'Indicates whether the tip component is visible when the event was triggered',
          type: 'string',
        },
      },
    },
    'location-details-clicked': {
      description: 'User clicked on the location dropdown in the event bar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and cart ID',
          type: 'string',
        },
      },
    },
    'location-details-dismissed': {
      description: 'The location dropdown in the event bar was dismissed',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and cart ID',
          type: 'string',
        },
      },
    },
    'location-details-search-clicked': {
      description:
        'The user clicked the submit button in the location details dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address and headcount, as well as the fulfillment detail ID and cart ID',
          type: 'string',
        },
      },
    },
    'address-clicked': {
      description: 'user opened the address dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'address-updated': {
      description: 'user changed the address in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'date-time-clicked': {
      description: 'user opened the date time dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and cart ID',
          type: 'string',
        },
      },
    },
    'date-time-dismissed': {
      description: 'The date/time dropdown in the eventbar was closed',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and cart ID',
          type: 'string',
        },
      },
    },
    'date-time-search-clicked': {
      description: 'The user clicked the submit button in the date/time dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and consumer cart ID',
          type: 'string',
        },
      },
    },
    'date-time-updated': {
      description: 'The user updated the date/time dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and consumer cart ID',
          type: 'string',
        },
      },
    },
    'headcount-clicked': {
      description: 'user opened the headcount dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'headcount-updated': {
      description: 'user changed the headcount in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'event-name-clicked': {
      description: 'user opened the Event Name dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, event name, and event date time',
          type: 'string',
        },
      },
    },
    'event-name-updated': {
      description: 'user changed the Event Name in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, event name, and event date time',
          type: 'string',
        },
      },
    },
    'event-details-clicked': {
      description: 'The user opened the event details dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and consumer cart ID',
          type: 'string',
        },
      },
    },
    'event-details-dismissed': {
      description: 'The event details dropdown was dismissed',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and consumer cart ID',
          type: 'string',
        },
      },
    },
    'event-details-search-clicked': {
      description: 'The user clicked the search/update button in the event details dropdown',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'Contains information about the address, headcount and event date time, as well as the fulfillment detail ID and consumer cart ID',
          type: 'string',
        },
      },
    },
    'event-bar-coachmark-seen': {
      description: 'user has seen the coachmark in the event bar',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
      },
    },
    'search-save-draft-clicked': {
      description: "user clicked 'save draft' in the search page",
      properties: {
        order_id: {
          description: 'id of order',
          type: ['string', 'null'],
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'search-button-clicked': {
      description: 'user clicked the search bar',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'search-icon-clicked': {
      description: 'user clicked the search icon in the search bar',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'search-icon-hovered': {
      description: 'user hovered over the search icon in the search bar',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'search-bar-clicked': {
      description: 'user clicked the search bar',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains recommended items & categories',
          type: 'string',
        },
      },
    },
    'order-type-clicked': {
      description: 'user opened the order type dropdown in the eventbar',
      properties: {
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'order-type-updated': {
      description: 'user changed the order type in the eventbar',
      properties: {
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'order-account-clicked': {
      description: 'user opened the order account dropdown in the eventbar',
      properties: {
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description:
            'Contains information about the address, headcount order account, and event date time',
          type: 'string',
        },
      },
    },
    'order-account-updated': {
      description: 'user changed the order account in the eventbar',
      properties: {
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description:
            'Contains information about the address, headcount, order account, and event date time',
          type: 'string',
        },
      },
    },
    'cart-button-hovered': {
      description: 'user hovered over the cart button in the navigation bar',
      properties: {
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
      },
    },
    'search-page-changed': {
      description: 'user goes to a different page of search results',
      properties: {
        search_id: {
          description: 'The analytics search ID',
          type: 'string',
        },
        misc_json: {
          description:
            'contains the page the user is going to as well as the fulfillment detail ID.',
          type: 'string',
        },
      },
    },
    'edit-event-details': {
      description: 'user opened the mobile event details modal',
      properties: {
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        page: {
          description: 'The page that the user is on',
          type: 'string',
        },
        sub_category: {
          description: 'The component that triggered the event',
          type: 'string',
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'event-details-form-submitted': {
      description: 'user submitted changes in the mobile event details modal',
      properties: {
        misc_json: {
          description:
            'contains the event address, time, date, headcount, and the fulfillment_detail_id and consumer_cart_id',
          type: 'string',
        },
      },
    },
    'search-filters-modal': {
      description: 'user opened the mobile search filters modal',
      properties: {
        search_id: {
          description: 'The analytics search ID',
          type: 'string',
        },
        misc_json: {
          description: 'contains the search ID',
          type: 'string',
        },
      },
    },
    'filter-clicked': {
      description: 'The budget input is visible. The name is for parity with ez-rails',
      properties: {
        misc_json: {
          description: "contains information about the budget filter's values",
          type: 'string',
        },
      },
    },
    'filters-updated': {
      description: 'The search filters were updated',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        misc_json: {
          description: "Contains information about the filters' values",
          type: 'string',
        },
      },
    },
    'sees-rr-carousel': {
      description: 'the user saw the reliability rockstars carousel at the top of the search page',
      properties: {
        misc_json: {
          description: 'contains the search id, order id, and fulfillment detail id',
          type: 'string',
        },
      },
    },
    'scroll-to-rr-carousel': {
      description: 'the user saw the reliability rockstars carousel among caterer results',
      properties: {
        misc_json: {
          description: 'contains the search id, order id, and fulfillment detail id',
          type: 'string',
        },
      },
    },
    'clicks-rr-carousel-third-row': {
      description:
        'reliability rockstars carousel rendered in the third row of search results, and user clicked on a caterer',
      properties: {
        caterer_id: {
          description: 'The caterer id',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'contains the search id, order id, and fulfillment detail id',
          type: 'string',
        },
      },
    },
    'status-code-modal-displayed': {
      description: 'a user sees the status code modal when all packages have a status code',
      properties: {
        order_id: {
          description: 'id of order',
          type: 'string',
        },
        misc_json: {
          description: 'contains the form Uuid',
          type: 'string',
        },
      },
    },
    'search-switch-to-takeout': {
      description: 'user changed an order to takeout from the search empty state',
      properties: {
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Contains information about the address, headcount and event date time',
          type: 'string',
        },
      },
    },
    'upcoming-orders-shown': {
      description:
        'User was shown the Upcoming Orders widget, which displays an order activity summary',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description:
            'Information about the number of drafts, receipts, and upcoming orders the user has',
          type: 'string',
        },
      },
    },
    'first-order-feedback-modal-viewed': {
      description:
        'User was shown the First Order Feedback Modal, which lets them rate their experience and send feedback.',
    },
    'first-order-feedback-submitted': {
      description: 'User submitted the First Order Feedback Modal.',
    },
    'first-order-feedback-dismissed': {
      description: 'User dismissed the First Order Feedback Modal.',
    },
    'clicked-poll-submit': {
      description: 'User submitted a response to a poll.',
      properties: {
        email: {
          description: 'The email of the user who responded to the poll.',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'began entering search keyword for address search': {
      description: 'User entered some value into the address search',
      properties: {
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        sub_category: {
          description: 'address search',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'The event details. Event name, address, etc',
          type: ['string', 'null'],
        },
      },
    },
    'shown saved addresses': {
      description:
        'When a user is shown at least one saved address from their address book in address result',
      properties: {
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        sub_category: {
          description: 'address search',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'The event details. Event name, address, etc',
          type: ['string', 'null'],
        },
      },
    },
    'saved address selected': {
      description: 'When a user selects a saved address from the event bar',
      properties: {
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        search_keyword: {
          description: 'address user submitted',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'The event details. Event name, address, etc',
          type: ['string', 'null'],
        },
      },
    },
    'address selected': {
      description: 'An address was selected to start a new order',
      properties: {
        caterer_id: {
          description: 'ID of the caterer for the order',
          type: ['string', 'null'],
        },
        sub_category: {
          description: 'address search',
          type: 'string',
        },
        search_keyword: {
          description: 'address user submitted',
          type: 'string',
        },
        value: {
          description:
            "type of address submitted: 'saved address' (show in address search results), 'autocomplete address' (google places search results), or 'address book entry' (not shown with address search results)",
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'The event details. Event name, address, etc',
          type: ['string', 'null'],
        },
      },
    },
    'address submitted to start new order': {
      description: 'User entered some value into the address search',
      properties: {
        sub_category: {
          description: 'address search',
          type: 'string',
        },
        search_keyword: {
          description: 'address user submitted',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description:
            'Contains the entered headcount, event date, and event time, as well as whether the address is a string to be geocoded or a saved address',
          type: 'string',
        },
      },
    },
    'caterer-card-clicked': {
      description: 'When a user selects a caterer on the search page',
      properties: {
        caterer_id: {
          description: "The caterer's id",
          type: 'string',
        },
        caterer_uuid: {
          description: "The caterer's uuid",
          type: 'string',
        },
        search_id: {
          description: 'The analytics search ID',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description:
            'Indicates if caterer is a favorite of the user and index of card in search results list',
          type: 'string',
        },
      },
    },
    'sno-suggested-caterers-loaded': {
      description: 'Suggested Caterers Carousel loaded with no suggested caterers.',
    },
    'clicked-sno-suggested-caterer': {
      description: 'User clicked caterer in SNO suggested caterers carousel',
      properties: {
        caterer_id: {
          description: 'The caterer id',
          type: 'string',
        },
        misc_json: {
          description: 'Contains the caterer 0-based rank in carousel',
          type: 'string',
        },
      },
    },
    'jump-back-in-carousel-shown': {
      description: 'Jump Back In Carousel shown',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
      },
    },
    // Search V1 event names
    'clicked-view-search-term': {
      description: 'User clicked the keyword search term from the suggestions popover',
      properties: {
        misc_json: {
          description: 'contains the keyword and the suggestions',
          type: 'string',
        },
      },
    },
    'clicked-view-cuisine-suggestion': {
      description: 'User clicked a cuisine suggestion from keyword search',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'clicked-caterer-suggestion': {
      description: 'User clicked a cuisine suggestion from keyword search',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'clicked-cuisine-suggestion': {
      description: 'User clicked a cuisine suggestion from keyword search',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'viewed-caterer-suggestion': {
      description: 'User was shown caterer suggestions for a keyword search',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'viewed-cuisine-suggestion': {
      description: 'User was shown cuisine suggestions for a keyword search',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    // Search V2 event names
    'clicked-typeahead-suggestion': {
      description: 'User clicked a typeahead suggestion from keyword search',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'viewed-typeahead-suggestion': {
      description: 'User was shown typeahead suggestions for a keyword search',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
        misc_json: {
          description: 'contains the keyword and all the suggestions shown to the user',
          type: 'string',
        },
      },
    },
    'clicked-brand-suggestion': {
      description: 'User clicked a brand suggestion from keyword search',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'contains the keyword, clickedValue, and all the suggestions shown to the user',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
      },
    },
    'viewed-brand-suggestion': {
      description: 'User was shown brand suggestions for a keyword search',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains the keyword and all the suggestions shown to the user',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        sub_category: {
          description: 'navigation bar',
          type: 'string',
        },
      },
    },
    'order from address book entry': {
      description: 'An order was created using the address book entry',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'Specific address order is being created from',
          type: 'string',
        },
      },
    },
    'recent-addresses-carousel-shown': {
      description:
        'When the user has recent addresses (address book entries) and is shown the recent addresses carousel',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'Recent addresses information shown',
          type: 'string',
        },
      },
    },
    'recent-addresses-carousel-view-address': {
      description:
        'When the user has clicked to view an address from the recent addresses carousel',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'Specific address clicked',
          type: 'string',
        },
      },
    },
    'viewed-dropoff-window-tooltip': {
      description: 'User views the Dropoff Window tooltip',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'viewed-low-delivery-fee-tooltip': {
      description: 'User views the Low Delivery Fee tooltip',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: 'string',
        },
        caterer_uuid: {
          description: 'The caterer UUID',
          type: 'string',
        },
      },
    },
    'viewed-price-rating-tooltip': {
      description: 'User was shown price rating tooltip',
    },
    'viewed-rockstar-tooltip': {
      description: 'User views the Rockstar tooltip',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: 'string',
        },
      },
    },
    'viewed-rockstar-learn-more-modal': {
      description: 'User views the Rockstar program Learn More modal',
      properties: {
        caterer_id: {
          description: 'The caterer ID',
          type: 'string',
        },
      },
    },
    'curated-list-card-clicked': {
      description: 'User clicks a caterer in a curated list',
      properties: {
        caterer_id: {
          description: "The caterer's id",
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description:
            'contains list_name (name of the curated list), and optionally the index of the card in the list when it was clicked (0 index)',
          type: 'string',
        },
      },
    },
    'curated-list-view-all-clicked': {
      description: 'User clicks the view all button in a curated list',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'contains list_name (name of the curated list)',
          type: 'string',
        },
      },
    },
    'viewed-curated-list': {
      description: 'User views a curated list',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description:
            'contains list_name (name of the curated list), as well as the count of the list ',
          type: 'string',
        },
      },
    },
    'curated-list-carousel-scroll': {
      description: 'User scrolls through a curated list',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'contains list_name (name of the curated list)',
          type: 'string',
        },
      },
    },
    'favorite-caterer-card-clicked': {
      description: 'user clicks a favorite caterer card',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer clicked',
          type: 'string',
        },
      },
    },
    'save-caterer-clicked': {
      description: 'user clicks a button to add a caterer to their saved caterers',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the saved caterer',
          type: 'string',
        },
        caterer_uuid: {
          description: "The saved caterer's uuid",
          type: 'string',
        },
        misc_json: {
          description: 'Describes user authentication status and brand uuid',
          type: ['string', 'null'],
        },
      },
    },
    'remove-saved-caterer-clicked': {
      description: 'user clicks a button to remove a caterer from their saved caterers',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the removed caterer',
          type: 'string',
        },
        caterer_uuid: {
          description: "The removed caterer's uuid",
          type: 'string',
        },
        misc_json: {
          description: 'Describes user authentication status and brand uuid',
          type: ['string', 'null'],
        },
      },
    },
    'viewed-previously-viewed-caterers-list': {
      description: 'User views the carousel of previously viewed caterers',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: "An array of previously viewed Caterer UUID's in the carousel",
          type: ['string', 'null'],
        },
      },
    },
    'previously-viewed-caterer-card-clicked': {
      description: 'User clicks on a previously viewed caterer card',
      properties: {
        caterer_id: {
          description: "The caterer's id",
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'The Caterer UUID that was clicked',
          type: ['string', 'null'],
        },
      },
    },
    'category-link-clicked': {
      description: 'User clicks a category link in the menu header',
      properties: {
        misc_json: {
          description: 'contains category_name, cart_id, and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'dietary-filter-clicked': {
      description: 'User cleared dietary filter',
      properties: {
        key: {
          description: 'The dietary filter type that is clicked',
          type: 'string',
        },
        value: {
          description: 'The new value of the dietary filter',
          type: 'string',
        },
      },
    },
    'dietary-filter-mobile-clicked': {
      description: 'User toggled a dietary filter',
      properties: {
        key: {
          description: 'The dietary filter type that is clicked',
          type: 'string',
        },
        value: {
          description: 'The new value of the dietary filter',
          type: 'string',
        },
      },
    },
    'dietary-filter-dropdown-clicked': {
      description: 'User toggled a dietary filter',
      properties: {
        value: {
          description: 'Whether the dropdown is opening or closing',
          type: 'string',
        },
      },
    },
    'dietary-filter-cleared': {
      description: 'User toggled a dietary filter',
      properties: {
        key: {
          description: 'The dietary filter type that is cleared',
          type: 'string',
        },
      },
    },
    'customer message shown': {
      description: 'A user was shown a customer message',
      properties: {
        type: {
          description:
            'The type of message that was triggered (e.g. caterer_closed, caterer_no_business_orders, etc)',
          type: 'string',
        },
        sub_category: {
          description: 'customer-message (for backwards compatibility)',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'more-categories-clicked': {
      description: 'User clicks the More categories dropdown',
      properties: {
        order_id: {
          description: 'ID of the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'contains category_name, cart_id, and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'consumer-cart-data-loaded': {
      description: 'when consumer cart fetches data',
      properties: {
        misc_json: {
          description: 'contains number of carts for user',
          type: 'string',
        },
      },
    },
    'consumer-cart-opened': {
      description: 'when consumer cart is opened',
      properties: {},
    },
    'consumer-cart-order-clicked': {
      description: 'when consumer cart is clicked',
      properties: {},
    },
    'reactivate-last-filters-clicked': {
      description: 'user clicked the button to reactivate last filters',
      properties: {},
    },
    'filters-cleared': {
      description: 'user cleared their filters',
      properties: {},
    },
    'navlink-clicked': {
      description: 'when the user clicks a my account nav link',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description: 'contains the link clicked',
          type: 'string',
        },
      },
    },
    'refer-a-friend-referrals-link-copied': {
      description: 'This event is triggered when you copy your referrals',
    },
    'new-credit-card-saved': {
      description: 'when a user adds a new credit card successfully',
    },
    'review-submitted': {
      description: 'when a user adds a new credit card successfully',
      properties: {
        url: {
          description: 'The page where user submitted the review',
          type: 'string',
        },
      },
    },
    'review clicked from receipts table': {
      description:
        'This event is triggered when a user navigates to the review page from the Receipts table',
    },
    'Corporate codes updated successfully': {
      description: 'when a user updates corp codes',
      properties: {
        page: {
          description: 'The pathname',
          type: 'string',
        },
        url: {
          description: 'The href',
          type: 'string',
        },
      },
    },
    'Corporate codes failed to update': {
      description: 'when a corp codes updates fails',
      properties: {
        page: {
          description: 'The pathname',
          type: 'string',
        },
        url: {
          description: 'The href',
          type: 'string',
        },
      },
    },
    'address book entry deleted': {
      description: 'This event is triggered when a user deletes an address from their address book',
      properties: {
        page: {
          description: 'The page on which the address was deleted',
          type: 'string',
        },
        id: {
          description: 'Deleted address id',
          type: 'string',
        },
      },
    },
    'less-than-three-holiday-caterers': {
      description: 'This event is triggered when there are less than three holiday caterers',
      properties: {
        misc_json: {
          description: 'Contains information about variant, metro, and holiday caterer supply',
          type: 'string',
        },
      },
    },
    'clicked-continue-draft-order': {
      description: 'User clicked continue order in order details',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        order_id: {
          description: 'The order id',
          type: 'string',
        },
      },
    },
    'item-added': {
      description: 'User added an item to their cart',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer from with the order',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
        misc_json: {
          description: 'Data related to menu item - imageShown, privateStore',
          type: 'string',
        },
      },
    },
    'item-added-failed': {
      description: 'User failed to add an item to their cart',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer from with the order',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
        misc_json: {
          description: 'Data related to menu item - imageShown, privateStore',
          type: 'string',
        },
      },
    },
    'item-updated': {
      description: 'User updated an item in their cart',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer from with the order',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
        misc_json: {
          description: 'Data related to menu item - imageShown, privateStore',
          type: 'string',
        },
      },
    },
    'item-removed': {
      description: 'User removed an item from their cart',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer from with the order',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
        misc_json: {
          description: 'Data related to menu item - imageShown, privateStore',
          type: 'string',
        },
      },
    },
    'item-viewed': {
      description: 'A user viewed the item menu modal on the menu page',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        caterer_id: {
          description: 'ID of the caterer from with the order',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
        misc_json: {
          description: 'Data related to menu item - imageShown, privateStore',
          type: 'string',
        },
      },
    },
    'menu-category-show-more': {
      description: 'User clicked the show more button to reveal hidden items in a menu category',
      properties: {
        sub_category: {
          description: 'menu',
          type: 'string',
        },
        misc_json: {
          description: 'Contains category name',
          type: 'string',
        },
      },
    },
    'menu-category-all-items-unavailable': {
      description:
        'This event is triggered when the menu page is loaded an a category has all items unavailable',
      properties: {
        caterer_id: {
          description: 'The caterer id for the menu',
          type: 'string',
        },
        misc_json: {
          description: 'The category name, caterer name',
          type: 'string',
        },
      },
    },
    'menu-item-unavailable': {
      description:
        'This event is triggered when the menu page is loaded and an item is unavailable, but not all items in the category',
      properties: {
        menu_item_id: {
          description: 'The id for the item that was unavailable',
          type: 'string',
        },
        caterer_id: {
          description: 'The caterer id for the menu',
          type: 'string',
        },
      },
    },
    'order-again-cards-shown': {
      description: 'User sees an order again card on the menu page',
      properties: {
        caterer_id: {
          description: 'The caterer id for the menu',
          type: 'string',
        },
        misc_json: {
          description: 'The fulfillment_detail_id, consumer_cart_id, and previousOrderCount',
          type: 'string',
        },
      },
    },
    'open-edit-order-modal': {
      description: 'User is attempting to order a recent order again, opening the edit order modal',
      properties: {
        key: {
          description: 'the ID of the recent order clicked',
          type: 'string',
        },
        misc_json: {
          description: 'The fulfillment_detail_id and consumer_cart_id',
          type: 'string',
        },
      },
    },
    'edit-order-modal-no-available-items': {
      description: 'A user viewed a recent order but none of the items are available',
      properties: {
        misc_json: {
          description: 'Contains all the order items',
          type: 'string',
        },
      },
    },
    'edit-order-modal-remove-item': {
      description:
        'A user is removing an item before adding a recent order to cart in the edit order modal',
      properties: {
        menu_item_id: {
          description: 'id of the item being deleted',
          type: 'string',
        },
      },
    },
    'edit-order-modal-edit-item-quantity': {
      description:
        'A user is editing the quantity of an item before adding a recent order to cart in the edit order modal',
      properties: {
        menu_item_id: {
          description: 'id of the item',
          type: 'string',
        },
        misc_json: {
          description: 'Contains the previous and new quantities',
          type: 'string',
        },
      },
    },
    'past order added to cart': {
      description: 'User added a recent order to the cart',
      properties: {
        key: {
          description: 'the recent order clicked',
          type: 'string',
        },
        misc_json: {
          description: 'The input details, fulfillment_detail_id and consumer_cart_id',
          type: 'string',
        },
      },
    },
    'delivery-minimum-not-met-modal-shown': {
      description: 'Delivery minimum not met modal shown to user',
      properties: {
        caterer_id: {
          description: 'The caterer id',
          type: 'string',
        },
        misc_json: {
          description: 'contains event headcount, order minimum, and cart total',
          type: 'string',
        },
      },
    },
    'takeout-minimum-not-met-modal-shown': {
      description: 'Takeout minimum not met modal shown to user',
      properties: {
        caterer_id: {
          description: 'The caterer id',
          type: 'string',
        },
        misc_json: {
          description: 'contains event headcount, takeout minimum, and cart total',
          type: 'string',
        },
      },
    },
    'connect-to-concur': {
      description: 'A user connects their ezCater account to Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'disconnect-from-concur': {
      description: 'User disconnects their account from Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'open disconnect from concur modal': {
      description: 'User opens a modal to disconnect their account from Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'close disconnect from concur modal': {
      description: 'User closes a modal to disconnect their account from Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'open concur info modal': {
      description: 'User opens a modal to learn more about Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'concur-popup-modal-shown': {
      description: 'A user sees the Concur popup modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
      },
    },
    'concur-connect-banner-shown': {
      description: 'A user sees a banner prompting them to connect to Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
      },
    },
    'connect-to-concur-cta-shown': {
      description: 'A user sees a CTA that allows them to connect to Concur',
    },
    'disconnect-from-concur-cta-shown': {
      description: 'A user sees a CTA that allows them to disconnect to Concur',
    },
    'Receipt posted to Concur successfully': {
      description: 'Receipt sent to concur',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
      },
    },
    'Receipt failed to post to Concur': {
      description: 'Receipt failed to send to concur',
      properties: {
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
      },
    },
    'turn-on-automated-receipts': {
      description: 'User turns on automated receipts from Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'turn-off-automated-receipts': {
      description: 'User turns off automated receipts from Concur',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'all-draft-orders-deleted': {
      description: 'This event is triggered when a user deletes all their draft orders',
    },
    'have-enough-food-modal-opened': {
      description: 'Have Enough Food modal is shown to user',
      properties: {
        misc_json: {
          description:
            'Contains order_items and whether each item quantity is too little or too much, as well as fulfillment_detail_id and consumer_cart_id',
          type: 'string',
        },
      },
    },
    'have-enough-food-modal-headcount-clicked': {
      description: 'User changes headcount in the Have Enough Food modal',
      properties: {
        misc_json: {
          description: 'Contains the consumer_cart_id, fulfillment_detail_id and headcount',
          type: ['string', 'null'],
        },
      },
    },
    'have-enough-food-modal-headcount-updated': {
      description: 'User changes headcount in the Have Enough Food modal',
      properties: {
        misc_json: {
          description: 'Contains the consumer_cart_id, fulfillment_detail_id and headcount',
          type: ['string', 'null'],
        },
      },
    },
    'have-enough-food-modal-quantity-changed': {
      description: 'User changes quantity of an item in the Have Enough Food modal',
      properties: {
        misc_json: {
          description:
            'Contains the orderItemId, previousQuantity, updatedQuantity, fulfillment_detail_id, and consumer_cart_id',
          type: 'string',
        },
      },
    },
    'have-enough-food-modal-add-drinks-and-desserts': {
      description: 'User clicked Back to Menu drinks and desserts prompt in Have Enough Food modal',
      properties: {
        misc_json: {
          description:
            'Contains missingDrinks and missingDesserts, as well as consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'have-enough-food-modal-dismissed': {
      description: 'User dismissed the Have Enough Food modal',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'have-enough-food-modal-proceed-to-checkout': {
      description: 'User clicks Proceed to Checkout in Have Enough Food modal',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id and fulfillment_detail_id',
          type: 'string',
        },
      },
    },
    'create-account-prompt-seen': {
      description: 'User sees prompt to create an account',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'tooltip-hover': {
      description: 'User hovers over a tooltip',
      properties: {
        misc_json: {
          description: 'The type of tooltip that was hovered over',
          type: 'string',
        },
      },
    },
    'address-book-changes-prompt-show': {
      description: 'user is shown the prompt to revise their saved address from a past order',
      properties: {
        misc_json: {
          description: 'Contains information about points redeemed',
          type: 'string',
        },
      },
    },
    'viewed-tableware-checkout-modal': {
      description: 'User was shown the tableware checkout modal',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'viewed-tableware-modal': {
      description: 'User was shown the tableware modal from cart',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'tableware-opt-in-via-cart': {
      description: 'User opted in to tableware via the cart selection',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'tableware-opt-in-via-modal': {
      description: 'User opted in to tableware via the modal selection',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'tableware-opt-out-via-cart': {
      description: 'User opted in to tableware via the cart selection',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'tableware-opt-out-via-modal': {
      description: 'User opted in to tableware via the modal selection',
      properties: {
        misc_json: {
          description: 'contains consumer_cart_id',
          type: 'string',
        },
      },
    },
    'saw-merchandising-slot': {
      description: 'User views a merchandising slot',
      properties: {
        order_id: {
          description: 'The order id',
          type: ['string', 'null'],
        },
        page: {
          description: 'Page event was triggered on',
          type: 'string',
        },
        url: {
          description: 'URL when event was triggered',
          type: 'string',
        },
        misc_json: {
          description: 'contains name (name of the merchandising slot)',
          type: 'string',
        },
      },
    },
    'change-receipts-table-filter': {
      description: 'User changed filter tabs on receipts table',
      properties: {
        filter_name: {
          description: 'Contains the filter value',
          type: 'string',
        },
      },
    },
    'event list viewed': {
      description: 'This event fires whenever a user views the list of messages/requests',
      properties: {
        account_type: {
          description: 'Type of account viewing the list.',
          type: 'string',
        },
        order_id: {
          description: 'The uuid of the order being viewed',
          type: 'string',
        },
      },
    },
    'change-receipts-table-filter-custom-start-date': {
      description: 'User changed custom start date filter on receipts table',
      properties: {
        filter_value: {
          description: 'Contains the new start date value',
          type: 'string',
        },
      },
    },
    'change-receipts-table-filter-custom-end-date': {
      description: 'User changed custom end date filter on receipts table',
      properties: {
        filter_value: {
          description: 'Contains the new end date value',
          type: 'string',
        },
      },
    },
    'expense report generated': {
      description:
        'This event is triggered when a user generates an expense report from the receipts page',
    },
    'expense-reporting-service-survey-response': {
      description: 'Different expensing service customer responded using other than Concur',
      properties: {
        misc_json: {
          description: 'Different expensing service used',
          type: 'string',
        },
      },
    },
    'expense-reporting-service-survey-shown': {
      description: 'A user sees the expense reporting survey',
    },
    'Message sent to caterer': {
      description: 'This event fires whenever a user sends a message to a caterer',
      properties: {
        account_type: {
          description: 'Type of account sending the message.',
          type: 'string',
        },
        order_id: {
          description: 'The uuid of the order being messaged about.',
          type: ['string', 'null'],
        },
      },
    },
    'Contact ezCater form opened': {
      description: 'This event fires whenever a user opens the contact ezCater modal',
      properties: {
        account_type: {
          description: 'Type of account opening the contact form.',
          type: 'string',
        },
        order_id: {
          description: "The uuid of the user's order.",
          type: ['string', 'null'],
        },
      },
    },
    'Contact ezCater form submitted': {
      description: 'This event fires whenever a user submits the contact ezCater modal form',
      properties: {
        account_type: {
          description: 'Type of account submitting the contact form.',
          type: 'string',
        },
        order_id: {
          description: 'The uuid of the order the user might need help with.',
          type: ['string', 'null'],
        },
      },
    },
    'admin continue modification button clicked': {
      description:
        'This tracks when an admin continues the modification in cart while performing a modification',
      properties: {
        order_id: {
          description: 'The order id',
          type: 'string',
        },
      },
    },
    'admin submit modification button clicked': {
      description:
        'This tracks when an admin submits the modification button in cart while performing a modification',
      properties: {
        order_id: {
          description: 'The order id',
          type: ['string', 'null'],
        },
      },
    },
    'build-a-food-program-section-scroll': {
      description: 'User views the Build a Food Program section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'customers-love-us-section-scroll': {
      description: 'User views the Customers Love Us section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'food-for-work-made-easy-section-scroll': {
      description: 'User views the Download Our App section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'food-that-works-section-scroll': {
      description: 'User views the Food that Works section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'grow-and-manage-section-scroll': {
      description: 'User views the Grow and Manage section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'home-start-order-address-search-bar-scroll': {
      description: 'User views the Start Order Search Bar section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'logos-section-scroll': {
      description: 'User views the Partner Logos section',
      properties: {
        category: {
          description: 'The category of the event',
          type: 'string',
        },
        event: {
          description: 'The event that ocurred',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        type: {
          description: 'The type of event',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'saw-polling-banner': {
      description: 'User viewed the start poll banner on a page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-polling-banner': {
      description: 'User clicked on the start poll button within the polling banner',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'saw-polling-modal-create': {
      description: 'User viewed the poll creation modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'saw-polling-modal-ready': {
      description: 'User viewed the poll creation success modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'saw-group-polls-empty': {
      description: 'User viewed the group polls page empty state',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-group-polls-empty-banner': {
      description: 'User clicked on the start poll button within the group polls page empty state',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-poll-details-results': {
      description: 'User clicked on the poll details results tab',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-poll-details-preview': {
      description: 'User clicked on the poll details preview tab',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-poll-clear': {
      description: 'User clicked on the poll details preview tab',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'saw-open-poll-banner': {
      description: 'User viewed poll status banner on the search page after poll creation',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'clicked-open-poll-banner': {
      description: 'User clicked on the view poll link within the poll status banner',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
      },
    },
    'recommended-order-clicked': {
      description: 'User clicks on a recommended order',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        search_id: {
          description: 'The analytics search ID',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'recommended order id, recommended order solver status',
          type: 'string',
        },
      },
    },
    'recommended-order-clicked-add-all': {
      description: 'User clicks on the add all to cart button for a recommended order',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'recommended order id, recommended order solver status',
          type: 'string',
        },
      },
    },
    'recommended-order-add-all-success': {
      description: 'User added all items from a recommended order to the cart',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'recommended order id, recommended order solver status, consumer cart id',
          type: 'string',
        },
      },
    },
    'recommended-order-tooltip': {
      description: 'User hovers over the tooltip',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        search_id: {
          description: 'The analytics search ID',
          type: ['string', 'null'],
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
      },
    },
    'recommended-order-error': {
      description: 'User encounters an error for recommended orders',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description:
            'If available: recommended order UUID, recommended order solver status, and referring recommended order UUID',
          type: 'string',
        },
      },
    },
    'recommended-orders-viewed': {
      description: 'User views the recommended order component',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'recommended order id',
          type: 'string',
        },
      },
    },
    'recommended-order-loading': {
      description: 'User encounters loading state for recommended orders',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'menu-group-order-copy-invitation-clicked': {
      description: 'User clicks on the menu page copy invitation link for a group order',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'menu-group-order-viewed': {
      description: 'User views the group order info banner on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'menu-group-order-start-clicked': {
      description: 'User clicks the start group order button on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'menu-group-order-start-button-clicked': {
      description: 'User clicks the start group order button on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'menu-group-order-edit-clicked': {
      description: 'User clicks the edit group order button on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'group-order-modal-viewed': {
      description: 'User sees the group order modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'group-order-modal-create-order-clicked': {
      description: 'User clicks the group order modal submit button on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, group order/owner status, and group order form values',
          type: 'string',
        },
      },
    },
    'group-order-modal-save-clicked': {
      description:
        'User clicks the group order modal submit button on the menu page when editing their group order',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, group order/owner status, and group order form values',
          type: 'string',
        },
      },
    },
    'group-order-modal-cancel-clicked': {
      description:
        'User clicks the group order modal cancel button (or dismisses the modal) on the menu page',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'group-order-invite-guests-modal-viewed': {
      description: 'User sees the invite guests modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'group-order-invite-guests-modal-copy-invite-clicked': {
      description: 'User clicks the invite copy link button on the invite guests modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'group-order-invite-guests-modal-done-clicked': {
      description: 'User submits or dismisses the invite guests modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'see-other-peoples-orders-clicked': {
      description: "User clicks the accordion in the group order cart to see other people's orders",
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'menu-group-order-item-over-budget-message-viewed': {
      description: 'User views the group order over budget message on a menu item',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains consumer_cart_id, fulfillment_detail_id, and group order/owner status',
          type: 'string',
        },
      },
    },
    'User clicked the Accept/Reject button on a request': {
      description:
        'This event fires whenever a user accepts or rejects a decision point on a request',
      properties: {
        account_type: {
          description: 'Type of account viewing the list.',
          type: 'string',
        },
        order_id: {
          description: 'The uuid of the order being viewed',
          type: ['string', 'null'],
        },
        value: {
          description: 'Whether they hit Accept or Reject',
          type: 'string',
        },
      },
    },
    'precise-location-granted': {
      description: 'User granted use of their location',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'precise-location-denied': {
      description: 'User denied use of their target',
      properties: {
        misc_json: {
          description: 'Contains error message',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'precise-location-address-filled': {
      description: 'Location (address) filled in based on user location',
      properties: {
        misc_json: {
          description: 'Contains address filled in',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
      },
    },
    'last-minute-order-filter-clicked': {
      description: 'User clicked the filter link in the Last Minute Order banner',
    },
    'menu-item-modal-error-start-chat': {
      description: 'User opened chat from menu item modal error',
      properties: {
        misc_json: {
          description: 'Contains network error message',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item being viewed',
          type: 'string',
        },
      },
    },
    'quick-add-to-cart-errored': {
      description: 'Quick add to cart error',
      properties: {
        misc_json: {
          description: 'Contains network or user error message',
          type: 'string',
        },
        menu_item_id: {
          description: 'ID of the menu item attempting to be added',
          type: 'string',
        },
      },
    },
    'navitem-clicked': {
      description: 'User clicked an item in the top nav',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description: 'Contains information about the clicked nav item',
          type: 'string',
        },
      },
    },
    'rewards-banner-shown': {
      description: 'Rewards banner was rendered',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        user_agent: {
          description: 'user agent info',
          type: 'string',
        },
        misc_json: {
          description: 'contains various details about the promo displayed',
          type: 'string',
        },
      },
    },
    'appbar-profile-menu-opened': {
      description: 'AppBar profile menu was viewed by the user',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description: 'Contains information about where the profile menu was viewed',
          type: 'string',
        },
      },
    },
    'recommended-order-modal-viewed': {
      description:
        'User clicked a order recommendation link on workflow hub or search and opened it on menu',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        caterer_id: {
          description: 'The id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Recommended order UUID and referring recommended order UUID',
          type: 'string',
        },
      },
    },
    'recommended-order-loaded': {
      description: 'Page with order recommendations was loaded',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        search_id: {
          description: 'The analytics search ID',
          type: ['string', 'null'],
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Order recommendation UUID and solver status.',
          type: 'string',
        },
      },
    },
    'recommended-order-inview': {
      description: 'Order recommendations are visible in the viewport',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        search_id: {
          description: 'The analytics search ID',
          type: ['string', 'null'],
        },
        caterer_id: {
          description: 'id of the caterer on the order',
          type: ['string', 'null'],
        },
        misc_json: {
          description: 'Order recommendation UUID.',
          type: 'string',
        },
      },
    },
    'similar-caterers-query-error': {
      description: 'Error running Similar Caterers query',
      properties: {
        misc_json: {
          description: 'Error message from SimilarCaterersQuery',
          type: 'string',
        },
      },
    },
    'no-similar-caterers-found': {
      description: 'No caterers returned by Similar Caterers query',
      properties: {
        misc_json: {
          description: 'Current caterer being run against',
          type: 'string',
        },
      },
    },
    'recommended-order-customize-open': {
      description: 'Customer clicks Customize button for a recommended order',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'recommended-order-customize-cancel': {
      description: 'Customer clicks cancel button for the recommended order customization modal',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'recommended-order-customize-done': {
      description:
        'Customer clicks done button submitting a customization for the recommended order customization modal',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description:
            'contains info regarding headcount and address info before and after submission',
          type: 'string',
        },
      },
    },
    'viewed-review-modal': {
      description:
        'User views the review modal in the workflow hub after clicking the review stars',
      properties: {
        misc_json: {
          description: 'The order ID',
          type: 'string',
        },
      },
    },
    'review-modal-submitted-successfully': {
      description: 'Review modal was submitted successfully on the first view',
      properties: {
        misc_json: {
          description: 'Contains order ID and rating',
          type: 'string',
        },
      },
    },
    'review-modal-updated-successfully': {
      description: 'Review modal was successfully submitted after an update',
      properties: {
        misc_json: {
          description: 'Contains order ID and rating',
          type: 'string',
        },
      },
    },
    'review-modal-errored': {
      description: 'Review modal contains error on unsuccessful attempt to save',
    },
    'review-modal-errored-on-update': {
      description: 'Review modal on update contains error on unsuccessful attempt to save',
    },
    'eater-error-page-viewed': {
      description:
        'User viewed the eater error page after clicking on an invalid group order invite link',
    },
    'self-service-reporting-modal-opened': {
      description: 'User opens the self-service reporting modal',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
      },
    },
    'self-service-reporting-modal-logged-in-order-issues-selector-viewed': {
      description:
        'User navigates to the IssuesDetailSelection component in the self-service reporting modal',
    },
    'self-service-reporting-modal-issues-detail-selection-viewed': {
      description:
        'User navigates to the IssuesDetailSelection component in the self-service reporting modal',
    },
    'self-service-reporting-modal-logged-in-issues-review-viewed': {
      description: 'Logged in user navigates to the issues review screen in the self-service modal',
    },
    'self-service-reporting-modal-logged-in-submission-confirmation-submitted': {
      description: "User submits the self-service reporting modal's submission confirmation",
    },
    'cancellations-modal-opened': {
      description: 'User opens the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID',
          type: 'string',
        },
      },
    },
    'cancellations-modal-parent-reason-viewed': {
      description: 'User navigates to the parent reason selection in the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID',
          type: 'string',
        },
      },
    },
    'cancellations-modal-child-reason-viewed': {
      description: 'User navigates to the child reason selection in the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID',
          type: 'string',
        },
      },
    },
    'cancellations-modal-submitted-success': {
      description: 'User successfully submitted in the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID and cancellation reason',
          type: 'string',
        },
      },
    },
    'cancellations-modal-submitted-error': {
      description: 'User experienced an error and was unable to submit in the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID and cancellation reason',
          type: 'string',
        },
      },
    },
    'cancellations-modal-closed-by-user': {
      description: 'User closed the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID',
          type: 'string',
        },
      },
    },
    'cancellations-modal-closed-by-form': {
      description: 'Form submission closed the cancellations modal',
      properties: {
        misc_json: {
          description: 'Contains order ID',
          type: 'string',
        },
      },
    },
    'recommended-order-feedback-submitted': {
      description: 'User submits feedback on a recommended order',
      properties: {
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        misc_json: {
          description: 'Contains feedback result and Order recommendation UUID',
          type: 'string',
        },
      },
    },
    'checkout-date-and-time-update': {
      description: 'User updates the date and/or time of their current FD',
      properties: {
        misc_json: {
          description:
            'Contains current date time as well as whether the date or time is actually getting updated',
          type: 'string',
        },
      },
    },
    'checkout-date-and-time-update-failed': {
      description: 'User attempts to update the date and/or time, but cannot',
      properties: {
        misc_json: {
          description: 'Contains the error from the update mutation',
          type: 'string',
        },
      },
    },
    'checkout-address-update': {
      description: 'User updates the address of their current FD',
      properties: {
        misc_json: {
          description: 'Contains the address information',
          type: 'string',
        },
      },
    },
    'checkout-address-update-failed': {
      description: 'User attempts to update the address, but cannot',
      properties: {
        misc_json: {
          description: 'Contains the error from the update mutation',
          type: 'string',
        },
      },
    },
    'checkout-address-verification-shown': {
      description: 'There exist multiple possible addresses',
      properties: {
        misc_json: {
          description: 'Contains the addresses causing the conflict',
          type: 'string',
        },
      },
    },
    'checkout-delivery-instructions-update': {
      description: 'User updates the delivery instructions of their current FD',
      properties: {
        misc_json: {
          description:
            'Contains the delivery instructions as well as whether the delivery instructions are actually getting updated',
          type: 'string',
        },
      },
    },
    'checkout-delivery-instructions-update-failed': {
      description: 'User attempts to update the delivery instructions, but cannot',
      properties: {
        misc_json: {
          description: 'Contains the error from the update mutation',
          type: 'string',
        },
      },
    },
    'checkout-ezrewards-applied': {
      description: 'User applies ezRewards to their checkout',
      properties: {
        misc_json: {
          description: 'Contains the points applied and amount applied',
          type: 'string',
        },
      },
    },
    'checkout-ezrewards-removed': {
      description: 'User removes ezRewards from their checkout',
      properties: {
        misc_json: {
          description: 'Contains the points removed and amount removed',
          type: 'string',
        },
      },
    },
    'checkout-ezrewards-apply-failed': {
      description: 'This event occurs when there is an error applying or removing EzRewards points',
      properties: {
        misc_json: {
          description:
            'Contains information about the error including type, message, and attempted points/amount',
          type: 'string',
        },
      },
    },
    'tip-submit-error': {
      description: 'User experiences an error and is unable to submit their tip',
      properties: {
        misc_json: {
          description: 'Contains the tip amount and tip fixed amount',
          type: 'string',
        },
      },
    },
    'checkout-promo-code-applied': {
      description: 'User applies a promo code to their checkout',
      properties: {
        misc_json: {
          description: 'Contains the promo code',
          type: 'string',
        },
      },
    },
    'checkout-promo-code-removed': {
      description: 'User removes a promo code from their checkout',
      properties: {
        misc_json: {
          description: 'Contains the promo code',
          type: 'string',
        },
      },
    },
    'checkout-promo-code-error': {
      description: 'User encounters an error when applying a promo code to their checkout',
      properties: {
        misc_json: {
          description: 'Contains the error from the apply mutation',
          type: 'string',
        },
      },
    },
    'checkout-landing-initial-data': {
      description: 'User lands on the checkout page',
      properties: {
        misc_json: {
          description: 'Contains the checkout data when the user lands on the checkout page',
          type: 'string',
        },
      },
    },
    'checkout-landing-data-failed': {
      description: 'User lands on the checkout page, but the checkout data landing event failed',
      properties: {
        misc_json: {
          description: 'Contains the error from the checkout data landing event attempt',
          type: 'string',
        },
      },
    },
    'checkout-tax-exemption-form-submitted': {
      description: 'User submits the tax exemption form',
      properties: {
        misc_json: {
          description: 'Contains the status of the form submission',
          type: 'string',
        },
      },
    },
    'checkout-order-tax-exempt-status-change-success': {
      description: 'User submits the tax exemption form',
      properties: {
        misc_json: {
          description: 'Contains the status that was successfully changed',
          type: 'string',
        },
      },
    },
    'checkout-order-tax-exempt-status-change-error': {
      description: 'User submits the tax exemption form',
      properties: {
        misc_json: {
          description: 'Contains the status that was attempted to change',
          type: 'string',
        },
      },
    },
    'checkout-order-submitted': {
      description: 'User submits their order',
      properties: {
        misc_json: {
          description: 'Contains the order ID',
          type: 'string',
        },
      },
    },
    'checkout-order-submission-failed': {
      description: 'User encounters an error when submitting their order',
      properties: {
        misc_json: {
          description: 'Contains the user errors from the submit mutation',
          type: 'string',
        },
      },
    },
    'checkout-card-added': {
      description: 'User adds a card to their checkout',
      properties: {
        misc_json: {
          description: 'Contains the ID of the card added',
          type: 'string',
        },
      },
    },
    'checkout-card-add-failed': {
      description: 'User encounters an error when adding a card to their checkout',
      properties: {
        misc_json: {
          description: 'Contains the error from the add card mutation',
          type: 'string',
        },
      },
    },
    'checkout-card-updated': {
      description: 'User updates a card on their checkout',
      properties: {
        misc_json: {
          description: 'Contains the ID of the card updated',
          type: 'string',
        },
      },
    },
    'checkout-card-update-failed': {
      description: 'User encounters an error when updating a card on their checkout',
      properties: {
        misc_json: {
          description: 'Contains the error from the update card mutation',
          type: 'string',
        },
      },
    },
    'checkout-card-update-current-payment-method': {
      description: 'User updates the current payment method on their checkout',
      properties: {
        misc_json: {
          description: 'Contains the ID of the card updated',
          type: 'string',
        },
      },
    },
    'checkout-card-update-current-payment-method-failed': {
      description:
        'User encounters an error when updating the current payment method on their checkout',
      properties: {
        misc_json: {
          description: 'Contains the error from the update card mutation',
          type: 'string',
        },
      },
    },
    'checkout-form-validation-error': {
      description: 'This event occurs when a checkout form fails validation',
      properties: {
        misc_json: {
          description:
            'Contains information about the validation error including form section, field errors, and form values',
          type: 'string',
        },
      },
    },
    'user-invited-to-connect': {
      description: 'User invites another user to connect',
      properties: {
        page: {
          description: 'The page that the user is currently on',
          type: 'string',
        },
        url: {
          description: 'The url that the user is currently on',
          type: 'string',
        },
        user_properties: {
          description: 'Information about the logged-in user',
          type: 'string',
        },
      },
    },
  },
} as const satisfies EventDictionary;

export default eventDictionary;
