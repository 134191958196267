// landings
export const CA_NOTICE_OF_COLLECTION_PATH = '/canoticeofcollection';
export const CONTACT_PATH = '/company/contact-us';
export const DO_NOT_SELL_INFO_PATH = '/donotsell';
export const VANITY_CATERER_SEARCH_PATH = '/catering/:state/:city'; // requires additional formatting of params

export const LANDINGS_PATHS = new Set([
  CA_NOTICE_OF_COLLECTION_PATH,
  CONTACT_PATH,
  DO_NOT_SELL_INFO_PATH,
  VANITY_CATERER_SEARCH_PATH,
]);

// ez-rails
export const ACCESSIBILITY_PATH = '/accessibility';
export const ADMIN_ORDER_PATH = '/admin/orders/:cartId';
export const ADMIN_PATH = '/admin';
export const BRAND_SHOW_EZO_PATH = '/brand/pvt/:urlId';
export const BRAND_SHOW_PATH = '/brand/:urlId';
export const CATERER_SHOW_PATH = '/store/caterer/:orderId';
export const CHECKOUT_PAYMENT_PATH = '/store/checkout/payment/:orderId';
export const CHECKOUT_START_PATH = '/store/checkout/checkout/:orderId';
export const CONCUR_AUTH_PATH = '/auth/concur4';
export const DOWNLOAD_RECEIPT_PATH = '/my_account/receipts/:orderId/show_pdf';
export const GENERATE_EXPENSE_REPORT_PATH = '/my_account/receipts/generate_expense_report';
export const ORDER_ICAL_PATH = '/my_account/orders/:orderId/ical';
export const ORDER_DATE_PATH = '/store/checkout/event_at/:orderId';
export const ORDER_PLACED_PATH = '/order_placed/:orderId';
export const PLACE_ORDER_PATH = '/store/checkout/place_order/:orderId';
export const PRIVACY_PATH = '/privacy_policy';
export const SIGN_IN_PATH = '/sign_in';
export const SIGN_UP_PATH = '/create_account';
export const TAX_CERTIFICATES_PATH = '/tax_exemption_certificates';
export const TAX_EXEMPTION_FORM_PATH = '/store/tax_exemptions_iframe';
export const TERMS_PATH = '/terms_of_use';

export const EZ_RAILS_PATHS = new Set([
  ACCESSIBILITY_PATH,
  ADMIN_ORDER_PATH,
  ADMIN_PATH,
  BRAND_SHOW_EZO_PATH,
  BRAND_SHOW_PATH,
  CATERER_SHOW_PATH,
  CHECKOUT_PAYMENT_PATH,
  CHECKOUT_START_PATH,
  CONCUR_AUTH_PATH,
  DOWNLOAD_RECEIPT_PATH,
  GENERATE_EXPENSE_REPORT_PATH,
  ORDER_ICAL_PATH,
  ORDER_PLACED_PATH,
  PLACE_ORDER_PATH,
  PRIVACY_PATH,
  SIGN_IN_PATH,
  SIGN_UP_PATH,
  TAX_CERTIFICATES_PATH,
  TAX_EXEMPTION_FORM_PATH,
  TERMS_PATH,
]);

// store-next
export type CATERING_MENU_PATH_VARIABLES = {
  experience?: 'pvt' | 'eater';
  catererUrlId: string;
  date?: string;
};

export const ADDRESS_PATH = '/my-account/addresses/:addressBookEntryId';
export const ADDRESSES_PATH = '/my-account/addresses';
export const CATERING_MENU_PATH = '/catering{/:experience}/:catererUrlId{/:date}';
export const CHANGE_PASSWORD_PATH = '/my-account/personal-information/change-password';
export const CHECKOUT_PATH = '/checkout/:consumerCartId';
export const CONNECTIONS_PATH = '/my-account/connections';
export const EDIT_ADDRESS_PATH = '/my-account/addresses/:addressId/edit';
export const EDIT_ORDER_PATH = '/my-account/orders/:orderId/edit';
export const EXPENSE_REPORTING_PATH = '/expense-reporting';
export const EZ_REWARDS_PATH = '/ez-rewards';
export const EZO_MENU_DATE_PATH = '/catering/pvt/:catererUrlId/:date';
export const EZO_MENU_PATH = '/catering/pvt/:catererUrlId';
export const GROUP_POLLS_PATH = '/my-account/group-polls';
export const MARKETPLACE_MENU_DATE_PATH = '/catering/:catererUrlId/:date';
export const MY_PROFILE_PATH = '/my-account/personal-information';
export const NEW_ADDRESS_PATH = '/my-account/addresses/new';
export const ORDER_VIEW_PATH = '/my-account/orders/:orderId';
export const ORDERS_CALENDAR_VIEW_PATH = '/my-account/orders/calendar-view';
export const ORDERS_PATH = '/my-account/orders';
export const PAYMENT_METHODS_PATH = '/my-account/payment-methods';
export const POLL_DETAILS_PATH = '/my-account/group-polls/:pollUuid';
export const POLL_RESULTS_PATH = '/my-account/group-polls/:pollUuid';
export const RECEIPTS_PATH = '/my-account/receipts';
export const REFER_A_FRIEND_PATH = '/refer-a-friend';
export const REVIEWS_PATH = '/my-account/reviews';
export const ROOT_PATH = '/';
export const SAVED_CATERERS_PATH = '/my-account/saved-caterers';
export const SEARCH_ORDER_ID_PATH = '/catering/search/:orderId';
export const SEARCH_NEW_PATH = '/catering/search/new';
export const START_NEW_ORDER_PATH = '/start-new-order';
export const SUGGEST_CATERER_PATH = '/my-account/suggest-caterer';
export const VIEW_ORDER_PATH = '/my-account/orders/:orderId';
export const VIEW_POLL_PATH = '/poll/:pollUuid';
export const VIEW_RECEIPT_PATH = '/my-account/receipts/:orderId';
export const WELCOME_BACK_PATH = '/welcome-back/start-new-order';

// store-next API routes
export const PDF_MENU_PATH = '/api/pdf/menu/:orderUuid';

// other / wordpress / external
export const ABOUT_US_PATH = '/company/about-us';
export const BLOG_PATH = '/lunchrush';

export const CAREERS_PATH = '/company/careers';
export const CATERER_PORTAL_PATH = '/caterer_portal';
export const CATERER_PARTNERS_PATH = '/company/grow-catering-business';
export const COMPANY_AFFILIATE_PROGRAM = '/company/lp/affiliate-program';
export const COMPANY_EZ_REWARDS_PATH = '/company/ezrewards';
export const COMPANY_NEWS_PATH = '/company/news';
export const COMPANY_STRATEGIC_PARTNERSHIPS = '/company/strategic-partnerships';
export const COMPANY_TEAM_PATH = '/company/team';
export const CONCIERGE_SERVICE_PATH = '/company/concierge';
export const CORPORATE_SOLUTIONS_PATH = '/company/corporate-solutions';
export const FAQ_PATH = '/company/faqs';

export const CATERER_COMMUNITY_RESOURCES_PATH = '/company/lp/caterer-community-resources';
export const CATERING_GROWTH_PLATFORM_PATH = '/company/catering-growth-platform';
export const CATERING_MANAGEMENT_SOLUTIONS_PATH =
  '/company/catering-growth-platform/catering-management-solutions';
export const DELIVERY_SOLUTIONS_PATH = '/company/catering-growth-platform/delivery-solutions';
export const GROWTH_SOLUTIONS_PATH = '/company/catering-growth-platform/growth-solutions';

export const CATERED_MEETINGS_EVENTS_PATH =
  '/company/corporate-solutions/catered-meetings-and-events';
export const CONCIERGE_ORDERING_PATH = '/company/corporate-solutions/concierge-ordering';
export const FOOD_SPEND_MANAGEMENT_PATH = '/company/corporate-solutions/food-spend-management';
export const RECURRING_EMPLOYEE_MEALS_PATH =
  '/company/corporate-solutions/recurring-employee-meals';
export const TAX_EXEMPT_PATH = '/company/corporate-solutions/tax-exempt';

export const SECURITY_PATH = 'https://security.ezcater.com/';
export const HELP_CENTER_PATH = 'https://catering.ezcater.com/en/help';

// corp portal
export const CORP_DASHBOARD_PATH = '/dashboard';
export const CORP_BILLING_PATH = '/billing/invoices';
export const CORP_PEOPLE_PATH = '/users';
export const CORP_REPORTS_PATH = '/reports';
export const CORP_SETTINGS_PATH = '/settings';

export const CORP_PORTAL_PATHS = new Set([
  CORP_DASHBOARD_PATH,
  CORP_BILLING_PATH,
  CORP_PEOPLE_PATH,
  CORP_REPORTS_PATH,
  CORP_SETTINGS_PATH,
]);
