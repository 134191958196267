import {SearchResultBrand} from '@/graphql/types';

export enum GoogleAnalyticsEventEnum {
  contactUsClick = 'contact_us',
  deliveryAddressEntered = 'delivery_address_entered',
  selectCaterer = 'select_caterer',
  chatStart = 'chat_start',
  saveCaterer = 'save_caterer',
  contactUsformSubmit = 'form_submit',
}

type DeliveryAddressEnteredPayload = {
  clickText?: string;
};

type ContactUsFormSubmitPayload = {
  formId: string;
  formHost: string;
  clickText?: string;
};

export type CatererPayload = {
  address?: {fullAddress?: string} | null;
  brand?: {uuid: string} | null;
  deliveryFee?: {subunits: number} | null;
  deliveryMinimum?: {subunits: number};
  distance?: number | null;
  id: string;
  minimumDeliveryFee?: {amount: {subunits: number}}; // Only from CateringMenuCatererFragment
  name?: string;
  orderMinimum?: {subunits: number};
  priceRating?: number | null;
  searchResultBrand?: SearchResultBrand;
  starRating?: number | null;
  takeoutMinimum?: {subunits: number} | null; // Only from CateringMenuCatererFragment
  uuid: string;
};

const formatCatererParams = (caterer: CatererPayload) => ({
  caterer_name: caterer.name ?? null,
  caterer_address: caterer.address?.fullAddress ?? null,
  caterer_rating: caterer.starRating ?? null,
  caterer_delivery_fee:
    caterer.deliveryFee?.subunits ?? caterer.minimumDeliveryFee?.amount?.subunits ?? null,
  caterer_order_minimum:
    caterer.orderMinimum?.subunits ??
    caterer.deliveryMinimum?.subunits ??
    caterer.takeoutMinimum?.subunits ??
    null,
  caterer_distance: caterer.distance ?? null,
  caterer_avg_cost: caterer.priceRating ?? null,
});

export const deliveryAddressEnteredPayload = ({clickText}: DeliveryAddressEnteredPayload) => {
  return {event: GoogleAnalyticsEventEnum.deliveryAddressEntered, click_text: clickText};
};

export const contactUsClickPayload = ({contactType}: {contactType?: string}) => {
  return {event: GoogleAnalyticsEventEnum.contactUsClick, contact_type: contactType};
};

export const selectCatererPayload = (caterer: CatererPayload) => {
  return {event: GoogleAnalyticsEventEnum.selectCaterer, ...formatCatererParams(caterer)};
};

export const saveCatererPayload = (caterer: CatererPayload) => {
  return {
    event: GoogleAnalyticsEventEnum.saveCaterer,
    ...formatCatererParams(caterer),
  };
};

export const contactUsFormSubmitPayload = ({
  formId,
  formHost,
  clickText,
}: ContactUsFormSubmitPayload) => {
  return {
    event: GoogleAnalyticsEventEnum.contactUsformSubmit,
    form_id: formId,
    form_host: formHost,
    click_text: clickText,
  };
};
