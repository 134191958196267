import {Icon} from '@ezcater/tapas';
import {faSpinnerThird} from '@fortawesome/pro-solid-svg-icons';
import useTranslation from 'next-translate/useTranslation';
import {twMerge} from 'tailwind-merge';

type LoadingSpinnerProps = {
  hideText?: boolean;
  className?: string;
  size?: 'small' | 'medium' | 'large';
};

const LoadingSpinner: React.FC<LoadingSpinnerProps> = ({
  hideText = false,
  className,
  size = 'medium',
}) => {
  const {t} = useTranslation('common');

  const sizeClasses = {
    small: 'text-sm',
    medium: 'text-md',
    large: 'text-lg',
  };

  return (
    <div className={twMerge('flex h-full w-full items-center justify-center', className)}>
      <div className="flex items-center gap-3">
        <Icon className="animate-spin" icon={faSpinnerThird} size={size} />

        {!hideText && <div className={sizeClasses[size]}>{t('components.Loading')}</div>}
      </div>
    </div>
  );
};

export default LoadingSpinner;
