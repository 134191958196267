import {
  AdditionalIssue,
  DeliveryTimeSelection,
  IssueSelection,
  NatureOfIssue,
} from '@/graphql/types';

export const natureOfIssueOptions = [
  {name: 'Missing or incorrect items', value: NatureOfIssue.MissingOrIncorrectItems},
  {name: 'Delivery time', value: NatureOfIssue.DeliveryTime},
  {name: 'Food quality or preparation', value: NatureOfIssue.FoodQualityOrPreparation},
  {name: 'Problem with driver', value: NatureOfIssue.ProblemWithDriver},
  {name: 'Something else', value: NatureOfIssue.SomethingElse},
];

export const additionalIssuesOptions = [
  {name: 'Missing or incorrect items', value: AdditionalIssue.MissingOrIncorrectItems},
  {name: 'Delivery time', value: AdditionalIssue.DeliveryTime},
  {name: 'Food quality or preparation', value: AdditionalIssue.FoodQualityOrPreparation},
  {name: 'Problem with driver', value: AdditionalIssue.ProblemWithDriver},
  {name: 'Something else', value: AdditionalIssue.SomethingElse},
];

// values are taken from ez-rails ( FulfillmentIssue.issue_codes found in packs/fulfillment_issues/public/models/fulfillment_issue.rb)
export const deliveryTimeOptions = [
  {name: 'My order never arrived', value: IssueSelection.OrderDidNotArrive},
  {name: 'Too late', value: IssueSelection.TooLate},
  {name: 'Too early', value: IssueSelection.TooEarly},
];

export const deliveryTimeTooEarlyOptions = [
  {name: 'More than 60 minutes early', value: DeliveryTimeSelection.MoreThanSixtyMinEarly},
  {name: '30 - 60 minutes early', value: DeliveryTimeSelection.ThirtyToSixtyMinEarly},
  {name: '15 - 30 minutes early', value: DeliveryTimeSelection.FifteenToThirtyMinEarly},
];

export const deliveryTimeTooLateOptions = [
  {name: 'More than 60 minutes late', value: DeliveryTimeSelection.MoreThanSixtyMinLate},
  {name: '30 - 60 minutes late', value: DeliveryTimeSelection.ThirtyToSixtyMinLate},
  {name: '15 - 30 minutes late', value: DeliveryTimeSelection.FifteenToThirtyMinLate},
  {name: '0 - 15 minutes late', value: DeliveryTimeSelection.ZeroToFifteenMinLate},
];

export const foodQualityOrPreparationOptions = [
  {name: 'Tasted bad or inedible', value: IssueSelection.FoodBadOrInedible},
  {name: 'Dietary requests not accomodated', value: IssueSelection.DietaryRequestsNotAccommodated},
  {name: 'Not prepared as requested', value: IssueSelection.IncorrectPreparation},
  {name: 'Not labeled as requested', value: IssueSelection.NotLabeledAsRequested},
  {name: 'Too hot or too cold', value: IssueSelection.TooHotOrTooCold},
  {name: 'Something else', value: IssueSelection.SomethingElse},
];

export const missingOrIncorrectItemsOptions = [
  {name: 'An entire food item was missing', value: IssueSelection.MissingFood},
  {name: 'A partial food item was missing', value: IssueSelection.MissingFoodPartial},
  {
    name: 'Missing utensils or other equipment',
    value: IssueSelection.MissingUtensilsOrOtherEquipment,
  },
];

export const problemWithDriverOptions = [
  {name: 'Dropped off order at wrong location', value: IssueSelection.DroppedOffToWrongLocation},
  {name: 'Delivered wrong order', value: IssueSelection.WrongOrderDelivered},
  {
    name: 'Delivery instructions not followed',
    value: IssueSelection.DeliveryInstructionsNotFollowed,
  },
  {name: 'Lacked delivery equipment', value: IssueSelection.DriverMissingDeliveryEquipment},
  {
    name: 'Food safety regulations not followed',
    value: IssueSelection.DriverDidNotAdhereFoodSafetyRegulations,
  },
  {name: 'Unprofessional behavior or attitude', value: IssueSelection.UnprofessionalDriver},
  {name: 'Something else', value: IssueSelection.SomethingElse},
];

// If the parent issue (selected in LoggedInOrderIssuesSelector) is one of these, we show checkboxes.
export const natureOfIssueCheckboxFields = [
  NatureOfIssue.MissingOrIncorrectItems,
  NatureOfIssue.FoodQualityOrPreparation,
  NatureOfIssue.ProblemWithDriver,
];
