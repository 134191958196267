// @ts-strict-ignore
import type React from 'react';
import {createContext, useMemo, useState} from 'react';

// same as EzFlashMessageUses in recipe (not exported)
export enum MessageType {
  Success = 'success',
  Info = 'info',
  Error = 'error',
  Warning = 'warning',
}

export type Message = {
  duration?: number;
  headline?: string;
  text?: React.ReactNode;
  linkText?: string;
  href?: string;
  use?: MessageType;
  linkClickMiscJson?: Record<string, unknown> | null;
};

export const FlashMessageContext = createContext({
  message: null,
  setMessage: message => message,
});

const FlashMessageProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const [message, setMessage] = useState<Message>({});

  const providerValue = useMemo(() => ({message, setMessage}), [message, setMessage]);

  return (
    <FlashMessageContext.Provider value={providerValue}>{children}</FlashMessageContext.Provider>
  );
};

export default FlashMessageProvider;
