import {Button, Typography} from '@ezcater/tapas';
import useTranslation from 'next-translate/useTranslation';

import {AllComponentsEnum, LoggedOutComponentsEnum} from '../componentNameEnums';

type LoggedOutCustomerIssuesReviewProps = {
  setStep: React.Dispatch<React.SetStateAction<keyof typeof AllComponentsEnum>>;
};

const LoggedOutCustomerIssuesReview: React.FC<LoggedOutCustomerIssuesReviewProps> = ({setStep}) => {
  const {t} = useTranslation('common');

  const clickNextStep = () => setStep(LoggedOutComponentsEnum.SubmissionConfirmation);
  const clickPreviousStep = () => setStep(LoggedOutComponentsEnum.IssuesDetailSelection);

  return (
    <div className="m-6 tablet:m-12" data-testid="logged-out-customer-issues-review">
      <div className="flex flex-col tablet:flex-row">
        <div className="flex flex-col gap-4">
          <Typography variant="heading-sm">
            {t('components.SelfServiceReportingModal.reportAnIssueHeader')}
          </Typography>
          <div>{t('components.SelfServiceReportingModal.LoggedOutCustomerIssuesReview.title')}</div>
        </div>
      </div>
      <div className="flex justify-end">
        <div className="mr-2 leading-10 text-peppercorn-400">
          {t('components.SelfServiceReportingModal.stepThreeOfThree')}
        </div>
        <Button className="mr-2" onClick={clickPreviousStep} size="medium" variant="outlined">
          {t('components.SelfServiceReportingModal.back')}
        </Button>
        <Button onClick={clickNextStep} size="medium" variant="primary">
          {t('components.SelfServiceReportingModal.submit')}
        </Button>
      </div>
    </div>
  );
};

export default LoggedOutCustomerIssuesReview;
