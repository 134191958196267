import {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import {FetchResult, useFragment} from '@apollo/client';
import {captureException} from '@sentry/nextjs';
import useTranslation from 'next-translate/useTranslation';
import invariant from 'tiny-invariant';

import {
  type CreateFulfillmentDetailMutation,
  type FulfillmentDetailAddressInput,
  type FulfillmentDetailFragment,
  FulfillmentDetailFragmentDoc,
  FulfillmentDetailStrategy,
  type FulfillmentDetailUpdateInput,
  type FulfillmentDetailUpdateMutation,
  useCreateFulfillmentDetailMutation,
  useFulfillmentDetailUpdateMutation,
} from '@/graphql/types';
import type RequiredPath from '@/utils/RequiredPath';
import {STORE_FLASH_MESSAGES} from '../CookieMessages';

type UserErrors = RequiredPath<
  FulfillmentDetailUpdateMutation,
  ['fulfillmentDetailUpdate', 'userErrors']
>;

export type GlobalFulfillmentDetailMutationContextType<FD = FulfillmentDetailFragment> = {
  errorMessage: string | null;
  updateFulfillmentDetail: (input: FulfillmentDetailUpdateInput) => Promise<{
    fulfillmentDetail: FD | null;
    userErrors: UserErrors | undefined;
    errors: FetchResult['errors'];
  }>;
};

export type GlobalFulfillmentDetailContextType = {
  fulfillmentDetail: FulfillmentDetailFragment | null;
  fallbackAddress?: FulfillmentDetailAddressInput;
};

export const GlobalFulfillmentDetailContext = createContext<GlobalFulfillmentDetailContextType>({
  fulfillmentDetail: null,
});

export const GlobalFulfillmentDetailMutationContext =
  createContext<GlobalFulfillmentDetailMutationContextType | null>(null);

export const useGlobalFulfillmentDetail = () => useContext(GlobalFulfillmentDetailContext);

export const useGlobalFulfillmentDetailMutation = <
  FD = FulfillmentDetailFragment,
>(): GlobalFulfillmentDetailMutationContextType<FD> => {
  const contextValue = useContext(
    GlobalFulfillmentDetailMutationContext,
  ) as GlobalFulfillmentDetailMutationContextType<FD>;

  invariant(
    contextValue != null,
    'useGlobalFulfillmentDetailMutation must be used within a GlobalFulfillmentDetailProvider',
  );

  return contextValue;
};

type Props = {fulfillmentDetailId?: string; fallbackAddress?: FulfillmentDetailAddressInput};

const GlobalFulfillmentDetailProvider: React.FC<React.PropsWithChildren<Props>> = ({
  fulfillmentDetailId,
  fallbackAddress,
  children,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [, setCookie] = useCookies([STORE_FLASH_MESSAGES]);
  const {t} = useTranslation('common');

  const [createFulfillmentDetailMutation] = useCreateFulfillmentDetailMutation();
  const [updateFulfillmentDetailMutation] = useFulfillmentDetailUpdateMutation();

  const {
    data: fulfillmentDetailData,
    complete,
    missing,
  } = useFragment<FulfillmentDetailFragment>({
    fragment: FulfillmentDetailFragmentDoc,
    from: {
      // FIXME: https://github.com/apollographql/apollo-client/issues/11600
      // we use `false` as a fallback to make updates work in React
      id: fulfillmentDetailId || false,
      __typename: 'FulfillmentDetail',
    },
    canonizeResults: true,
  });

  const missingParts = fulfillmentDetailId && missing ? JSON.stringify(missing) : null;
  useEffect(() => {
    try {
      if (missingParts)
        throw new Error(
          `Failed to load a complete Fulfillment Detail for id ${fulfillmentDetailId}, missing: ${missingParts}`,
        );
    } catch (e) {
      captureException(e);
      if (__DEV__) console.error(e);
    }
  }, [missingParts, fulfillmentDetailId]);

  const fdProviderValue = useMemo(
    () => ({
      fulfillmentDetail: complete && fulfillmentDetailData?.id ? fulfillmentDetailData : null,
      fallbackAddress,
    }),
    [complete, fulfillmentDetailData, fallbackAddress],
  );

  const updateFulfillmentDetail = useCallback<
    GlobalFulfillmentDetailMutationContextType['updateFulfillmentDetail']
  >(
    async input => {
      let data:
        | (CreateFulfillmentDetailMutation & FulfillmentDetailUpdateMutation)
        | null
        | undefined;
      let errors: FetchResult['errors'];

      if (fulfillmentDetailId)
        ({data, errors} = await updateFulfillmentDetailMutation({
          variables: {id: fulfillmentDetailId, input},
        }));
      else
        ({data, errors} = await createFulfillmentDetailMutation({
          variables: {
            input: {
              ...input,
              address: input.addressId ? input.address : input.address || fallbackAddress,
              strategy: input.strategy || FulfillmentDetailStrategy.Delivery,
            },
          },
        }));

      const fulfillmentDetail =
        data?.fulfillmentDetailCreate?.fulfillmentDetail ||
        data?.fulfillmentDetailUpdate?.fulfillmentDetail;
      const userErrors =
        data?.fulfillmentDetailCreate?.userErrors || data?.fulfillmentDetailUpdate?.userErrors;

      if (userErrors) {
        userErrors.forEach(dataError => {
          switch (dataError.__typename) {
            case 'EntityLocked':
            case 'EntityNotFound': {
              setCookie(STORE_FLASH_MESSAGES, `error=${t('errors.entityNotFound')}`);
              throw new Error(dataError.__typename);
            }
            case 'ValidationError':
              setErrorMessage(dataError.message);
              throw new Error(dataError.__typename);
            default:
              throw new Error(
                `Unknown error received: ${(dataError as {__typename: string}).__typename}`,
              );
          }
        });
      }

      return {
        fulfillmentDetail: fulfillmentDetail || null,
        userErrors,
        errors,
      };
    },
    [
      createFulfillmentDetailMutation,
      fulfillmentDetailId,
      setCookie,
      t,
      updateFulfillmentDetailMutation,
      fallbackAddress,
    ],
  );

  return (
    <GlobalFulfillmentDetailContext.Provider value={fdProviderValue}>
      <GlobalFulfillmentDetailMutationContext.Provider
        value={useMemo(
          () => ({errorMessage, updateFulfillmentDetail}),
          [errorMessage, updateFulfillmentDetail],
        )}
      >
        {children}
      </GlobalFulfillmentDetailMutationContext.Provider>
    </GlobalFulfillmentDetailContext.Provider>
  );
};

export default GlobalFulfillmentDetailProvider;
