import {useCallback} from 'react';

import {useEzRewardsUserAssignedPromo} from '@/components/EzRewardsPromosProvider';
import {useStoreNextRouter} from '@/components/StoreNextRouter';

const useLibertyWebchatPositioning = () => {
  const {isOnMenuPage} = useStoreNextRouter();
  const {toastData} = useEzRewardsUserAssignedPromo();

  const libertyWebchatPosition = useCallback(() => {
    if (toastData && !isOnMenuPage) {
      return 'right-[60px]';
    } else if (toastData) {
      return 'right-[405px]';
    } else if (isOnMenuPage) {
      return 'right-[345px]';
    }
  }, [toastData, isOnMenuPage]);

  return {libertyWebchatPosition};
};

export default useLibertyWebchatPositioning;
