import {LazyMotion as LazyFramerMotion} from 'motion/react';

const loadFeatures = async () => import('./features').then(mod => mod.default);

const LazyMotion: React.FC<React.PropsWithChildren<unknown>> = ({children}) => (
  <LazyFramerMotion features={loadFeatures} strict>
    {children}
  </LazyFramerMotion>
);

export default LazyMotion;
