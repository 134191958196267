import {useContext} from 'react';
import invariant from 'tiny-invariant';

import {FiltersContext} from '../FiltersProvider';

const useFilters = () => {
  const providerValue = useContext(FiltersContext);

  invariant(providerValue != null, 'useFilters must be used within a FiltersProvider');

  return providerValue;
};

export default useFilters;
