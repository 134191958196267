import {toZonedTime} from 'date-fns-tz';

type EventdateInEventTimezoneProps = {
  timestamp: string | Date | null;
  timeZoneOffset: string | null;
};

const eventDateInEventTimezone = ({
  timestamp,
  timeZoneOffset,
}: EventdateInEventTimezoneProps): Date | null =>
  timestamp && timeZoneOffset ? toZonedTime(timestamp, timeZoneOffset) : null;

export default eventDateInEventTimezone;
