import {useEffect, useState} from 'react';
import {Button, Icon, Modal} from '@ezcater/tapas';
import {faXmark} from '@fortawesome/pro-regular-svg-icons';
import {Form, Formik, type FormikErrors} from 'formik';
import useTranslation from 'next-translate/useTranslation';

import {natureOfIssueCheckboxFields} from '@/components/SelfServiceReportingModal/SelfServiceReportingConstants';
import FeatureFlags from '@/FeatureFlags';
import {
  AdditionalIssue,
  DeliveryTimeSelection,
  IssueSelection,
  NatureOfIssue,
  useSubmitSelfReportedFulfillmentIssueMutation,
} from '@/graphql/types';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import useSelfServiceReportingModal from '@/hooks/useSelfServiceReportingModal';
import useTracking from '@/hooks/useTracking';
import IssuesDetailSelection from './ModalScreens/IssuesDetailSelection';
import LoggedInIssuesReview from './ModalScreens/LoggedInIssuesReview';
import LoggedInOrderIssuesSelector, {Order} from './ModalScreens/LoggedInOrderIssuesSelector';
import LoggedOutCustomerIssuesReview from './ModalScreens/LoggedOutCustomerIssuesReview';
import LoggedOutOrderIssuesSelector from './ModalScreens/LoggedOutOrderIssuesSelector';
import SubmissionConfirmation from './ModalScreens/SubmissionConfirmation';
import {
  AllComponentsEnum,
  LoggedInComponentsEnum,
  LoggedOutComponentsEnum,
} from './componentNameEnums';

export const ADDITIONAL_DETAILS_MAX_TEXT_LENGTH = 500;
export const SOMETHING_ELSE_MAX_TEXT_LENGTH = 500;

export type FormValues = {
  additionalIssues: AdditionalIssue[];
  deliveryTimeSelection: DeliveryTimeSelection | null;
  issuesSelected: IssueSelection[];
  issuesDetailSelectionAdditionalDetails: string;
  loggedInIssuesReviewAdditionalDetails: string;
  natureOfIssue: NatureOfIssue | null;
  order: Order | string | null;
  somethingElseField: string;
};

const SelfServiceReportingModal = () => {
  const {t} = useTranslation('common');
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [step, setStep] = useState<AllComponentsEnum>(
    AllComponentsEnum.LoggedInOrderIssuesSelector,
  );
  const [submissionError, setSubmissionError] = useState<boolean>(false);
  const identity = useIdentity().data;
  const {isOpen, toggleSelfServiceProviderModal} = useSelfServiceReportingModal();
  const selfServiceReportingModalEnabled = useFeatureFlag(FeatureFlags.SelfServiceReportingModal);
  const {track} = useTracking();
  const [submitSelfReportedFulfillmentIssueMutation] =
    useSubmitSelfReportedFulfillmentIssueMutation();

  useEffect(() => {
    const {consumerAccount} = identity?.me ?? {};
    const loggedInStatus = Boolean(consumerAccount);
    setIsLoggedIn(loggedInStatus);
    if (loggedInStatus) {
      setStep(LoggedInComponentsEnum.LoggedInOrderIssuesSelector);
    } else {
      setStep(LoggedOutComponentsEnum.LoggedOutOrderIssuesSelector);
    }
  }, [identity?.me]);

  const closeModal = () => {
    toggleSelfServiceProviderModal();
    setSubmissionError(false);
    setTimeout(() => {
      setStep(
        isLoggedIn
          ? LoggedInComponentsEnum.LoggedInOrderIssuesSelector
          : LoggedOutComponentsEnum.LoggedOutOrderIssuesSelector,
      );
    }, 350);
  };

  const validateForm = (values: FormValues) => {
    const errors: FormikErrors<FormValues> = {};
    const atLeastOneSelectionRequired = t(
      'components.SelfServiceReportingModal.validation.atLeastOneSelectionRequired',
    );
    const descriptionRequired = t(
      'components.SelfServiceReportingModal.validation.descriptionRequired',
    );
    const selectionRequired = t(
      'components.SelfServiceReportingModal.validation.selectionRequired',
    );
    const shortenAdditionalDetailsDescription = t(
      'components.SelfServiceReportingModal.validation.shortenAdditionalDetailsDescription',
    );
    const shortenIssueDescription = t(
      'components.SelfServiceReportingModal.validation.shortenIssueDescription',
    );

    if (step === LoggedInComponentsEnum.LoggedInOrderIssuesSelector) {
      if (values.natureOfIssue === null) errors.natureOfIssue = selectionRequired;
      if (values.order === null) errors.order = selectionRequired;
    } else if (step === AllComponentsEnum.IssuesDetailSelection) {
      // The IssuesDetailSelection component renders one of three variants: checkboxes, radios/selects, or text inputs.
      // These variants are based on the natureOfIssue that is selected in LoggedInOrderIssuesSelector.

      if (!!values?.natureOfIssue && natureOfIssueCheckboxFields.includes(values.natureOfIssue)) {
        // Checkbox variants (natureOfIssue === 'MISSING_OR_INCORRECT_ITEMS', 'FOOD_QUALITY_OR_PREPARATION', or 'PROBLEM_WITH_DRIVER'):
        // Multiple checkboxes are shown to the user, and the user must select an issue(s). If none are selected an error is rendered.
        if (values.issuesSelected.length === 0) errors.issuesSelected = atLeastOneSelectionRequired;
        if (
          values.issuesDetailSelectionAdditionalDetails.length >= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH
        )
          errors.issuesDetailSelectionAdditionalDetails = shortenAdditionalDetailsDescription;
      } else if (values.natureOfIssue === NatureOfIssue.DeliveryTime) {
        // Radio variant (natureOfIssue === 'delivery_time'): Three radio buttons are shown to the user.
        // If the user selects "My order never arrived" radio button, they can proceed to the next step.
        if (values.issuesSelected.length === 0) errors.issuesSelected = selectionRequired;
        // If the user selects either the "Too late" or "Too early" radio buttons, a select input renders and a selection is required (deliveryTimeSelection).
        if (
          values.issuesSelected.includes(IssueSelection.TooEarly) ||
          values.issuesSelected.includes(IssueSelection.TooLate)
        ) {
          if (values.deliveryTimeSelection === null)
            errors.deliveryTimeSelection = selectionRequired;
        }
      } else if (values.natureOfIssue === NatureOfIssue.SomethingElse) {
        // TextBox variant (natureOfIssue === 'something_else'): If the user types over 500 characters, an error is rendered.
        if (values.somethingElseField === '') {
          errors.somethingElseField = descriptionRequired;
        } else if (values.somethingElseField.length >= SOMETHING_ELSE_MAX_TEXT_LENGTH) {
          errors.somethingElseField = shortenIssueDescription;
        }
      }
    } else if (step === LoggedInComponentsEnum.LoggedInIssuesReview) {
      if (values.loggedInIssuesReviewAdditionalDetails.length >= ADDITIONAL_DETAILS_MAX_TEXT_LENGTH)
        errors.loggedInIssuesReviewAdditionalDetails = shortenAdditionalDetailsDescription;
    }

    return errors;
  };

  const resetIssuesDetailSelectionFormValues = (
    setFieldValue: (field: string, value: any) => void,
  ) => {
    setFieldValue('deliveryTimeSelection', null);
    setFieldValue('issuesSelected', []);
    setFieldValue('somethingElseField', '');
    setFieldValue('issuesDetailSelectionAdditionalDetails', '');
  };

  const setLoggedInOrderIssuesSelectorNextStep = (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
  ) => {
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      setStep(LoggedInComponentsEnum.IssuesDetailSelection);
    } else {
      setErrors(errors);
    }
  };

  const setIssuesDetailSelectionNextStep = (
    values: FormValues,
    setErrors: (errors: FormikErrors<FormValues>) => void,
  ) => {
    const errors = validateForm(values);
    if (Object.keys(errors).length === 0) {
      setStep(
        isLoggedIn
          ? LoggedInComponentsEnum.LoggedInIssuesReview
          : LoggedOutComponentsEnum.LoggedOutCustomerIssuesReview,
      );
    } else {
      setErrors(errors);
    }
  };

  const setIssuesDetailSelectionPreviousStep = () => {
    setStep(
      isLoggedIn
        ? LoggedInComponentsEnum.LoggedInOrderIssuesSelector
        : LoggedOutComponentsEnum.LoggedOutOrderIssuesSelector,
    );
  };

  const submitForm = async (values: FormValues, {resetForm}: {resetForm: () => void}) => {
    try {
      const {data} = await submitSelfReportedFulfillmentIssueMutation({
        variables: {
          additionalIssues: values.additionalIssues,
          deliveryTimeSelection: values.deliveryTimeSelection,
          issuesDetailSelectionAdditionalDetails:
            values.issuesDetailSelectionAdditionalDetails.length > 0
              ? values.issuesDetailSelectionAdditionalDetails
              : null,
          issuesSelected: values.issuesSelected,
          loggedInIssuesReviewAdditionalDetails: values.loggedInIssuesReviewAdditionalDetails,
          natureOfIssue: values.natureOfIssue as NatureOfIssue,
          orderId: values.order as string,
          somethingElseField: values.somethingElseField,
        },
      });
      if (data?.submitSelfReportedFulfillmentIssue?.success) {
        track('self-service-reporting-modal-logged-in-submission-confirmation-submitted');
        resetForm();
        setStep(LoggedInComponentsEnum.SubmissionConfirmation);
      } else {
        setSubmissionError(true);
      }
    } catch (error) {
      setSubmissionError(true);
      console.error('SelfServiceReportingModal error submitting form', error);
    }
  };

  if (selfServiceReportingModalEnabled) {
    return (
      <Modal
        data-testid="self-service-reporting-modal"
        open={isOpen}
        size="medium"
        slotProps={{content: {className: 'p-0'}}}
      >
        <>
          <Button
            className="absolute right-6 top-6 rounded-full p-2"
            onClick={closeModal}
            variant="secondary"
          >
            <Icon icon={faXmark} size="medium" />
          </Button>
          <Formik<FormValues>
            initialValues={{
              additionalIssues: [],
              deliveryTimeSelection: null,
              issuesSelected: [],
              issuesDetailSelectionAdditionalDetails: '',
              loggedInIssuesReviewAdditionalDetails: '',
              natureOfIssue: null,
              order: null,
              somethingElseField: '',
            }}
            validate={values => {
              validateForm(values);
            }}
            onSubmit={async (values, {resetForm}) => {
              await submitForm(values, {resetForm});
            }}
          >
            {({errors, handleSubmit, isSubmitting, setErrors, setFieldValue, values}) => (
              <Form>
                {/* AllComponentsEnum represents two components that are shared for both logged in/out flows */}
                {
                  {
                    [LoggedInComponentsEnum.LoggedInOrderIssuesSelector]: (
                      <LoggedInOrderIssuesSelector
                        errors={errors}
                        natureOfIssue={values.natureOfIssue}
                        order={values.order}
                        resetIssuesDetailSelectionFormValues={() =>
                          resetIssuesDetailSelectionFormValues(setFieldValue)
                        }
                        setFieldValue={setFieldValue}
                        setNatureOfIssue={value => setFieldValue('natureOfIssue', value)}
                        setOrder={value => setFieldValue('order', value)}
                        setStep={() => setLoggedInOrderIssuesSelectorNextStep(values, setErrors)}
                      />
                    ),
                    [LoggedInComponentsEnum.LoggedInIssuesReview]: (
                      <LoggedInIssuesReview
                        additionalIssues={values.additionalIssues}
                        closeModal={closeModal}
                        errors={errors}
                        handleSubmit={handleSubmit}
                        isSubmitting={isSubmitting}
                        loggedInIssuesReviewAdditionalDetails={
                          values.loggedInIssuesReviewAdditionalDetails
                        }
                        natureOfIssue={values.natureOfIssue}
                        setFieldValue={setFieldValue}
                        setStep={setStep}
                        setSubmissionError={setSubmissionError}
                        submissionError={submissionError}
                      />
                    ),
                    [LoggedOutComponentsEnum.LoggedOutOrderIssuesSelector]: (
                      <LoggedOutOrderIssuesSelector setStep={setStep} />
                    ),
                    [LoggedOutComponentsEnum.LoggedOutCustomerIssuesReview]: (
                      <LoggedOutCustomerIssuesReview setStep={setStep} />
                    ),
                    [AllComponentsEnum.IssuesDetailSelection]: (
                      <IssuesDetailSelection
                        deliveryTimeSelection={values.deliveryTimeSelection}
                        errors={errors}
                        issuesDetailSelectionAdditionalDetails={
                          values.issuesDetailSelectionAdditionalDetails
                        }
                        issuesSelected={values.issuesSelected}
                        natureOfIssue={values.natureOfIssue}
                        setFieldValue={setFieldValue}
                        setNextStep={() => setIssuesDetailSelectionNextStep(values, setErrors)}
                        setPreviousStep={setIssuesDetailSelectionPreviousStep}
                        somethingElseField={values.somethingElseField}
                      />
                    ),
                    [AllComponentsEnum.SubmissionConfirmation]: (
                      <SubmissionConfirmation closeModal={closeModal} />
                    ),
                  }[step]
                }
              </Form>
            )}
          </Formik>
        </>
      </Modal>
    );
  }

  return;
};

export default SelfServiceReportingModal;
