import type React from 'react';
import {type IExperimentData} from '@ezcater/experiments-js/dist/types';
import {type IFeatureFlagData} from '@ezcater/feature-flags-js/dist/types';

import {ContactUsModalProvider} from '../ContactUsModal';
import {SelfServiceReportingModalProvider} from '../SelfServiceReportingModal';
import ExperimentsProvider from './ExperimentsProvider';
import FeatureFlagsProvider from './FeatureFlagsProvider';
import StylesProvider from './StylesProvider';

type RootProviderProps = React.PropsWithChildren<{
  experimentsCache?: IExperimentData | null;
  featureFlagsCache?: IFeatureFlagData | null;
}>;

const RootProvider: React.FC<RootProviderProps> = ({
  children,
  featureFlagsCache,
  experimentsCache,
}) => (
  <StylesProvider>
    <ContactUsModalProvider>
      <SelfServiceReportingModalProvider>
        <FeatureFlagsProvider cache={featureFlagsCache}>
          <ExperimentsProvider cache={experimentsCache}>{children}</ExperimentsProvider>
        </FeatureFlagsProvider>
      </SelfServiceReportingModalProvider>
    </ContactUsModalProvider>
  </StylesProvider>
);

export default RootProvider;
