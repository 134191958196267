import type React from 'react';
import {createContext, useCallback, useMemo, useState} from 'react';

import {type ViewOrderPageQuery} from '@/graphql/types';

export type SelfServiceReportingModalContextType = {
  isOpen: boolean;
  order: ViewOrderPageQuery['order'] | null;
  toggleSelfServiceProviderModal: (order?: ViewOrderPageQuery['order'] | null) => void;
};

export const SelfServiceReportingModalContext = createContext<SelfServiceReportingModalContextType>(
  {
    isOpen: false,
    order: null,
    toggleSelfServiceProviderModal: () => {},
  },
);

const SelfServiceReportingModalProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [order, setOrder] = useState<ViewOrderPageQuery['order'] | null>(null);

  const toggleSelfServiceProviderModal = useCallback(
    (order?: ViewOrderPageQuery['order'] | null) => {
      setIsOpen(isCurrentlyOpen => !isCurrentlyOpen);
      setOrder(order || null);
    },
    [setIsOpen, setOrder],
  );

  const providerValue = useMemo(
    () => ({isOpen, order, toggleSelfServiceProviderModal}),
    [isOpen, order, toggleSelfServiceProviderModal],
  );

  return (
    <SelfServiceReportingModalContext.Provider value={providerValue}>
      {children}
    </SelfServiceReportingModalContext.Provider>
  );
};

export default SelfServiceReportingModalProvider;
