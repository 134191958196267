import canonicalPath from '@/pageUtils/caterer-search/canonicalPath';
import {type SearchPageObject} from '@/pageUtils/caterer-search/cateringSearch';
import {ROOT_PATH} from '@/paths';
import {compilePath} from '@/utils';

const extractUneditableFulfillmentDetailPath = (data: SearchPageObject) => {
  const {address} = data || {};

  const vanityURLPath = canonicalPath({address});

  if (address && Boolean(vanityURLPath)) return vanityURLPath;

  // Used as a fallback path. However, if a FD is uneditable, we should always have city & state
  return compilePath(ROOT_PATH);
};

export default extractUneditableFulfillmentDetailPath;
