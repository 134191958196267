// @ts-strict-ignore
import {type ParsedUrlQuery} from 'querystring';

import {type Translate} from 'next-translate';
import {type ParsedQuery} from 'query-string';
import queryString from 'query-string';

import {
  type CatererSearchStarRating,
  DeiOwnedBusinessTaxonomy,
  type MarketplaceCatererSearchFilter,
  type Maybe,
  type MoneyInput,
  type SearchCuisineTaxonomy,
  SearchServiceCapabilitiesTaxonomy,
  type SearchTaxonomiesAndTaxonsInput,
  type TaxonFilterInput,
} from '@/graphql/types';
import {CatererSearchRewardsLevel, DietaryOptionEnum} from '@/graphql/types';
import {CuisineTaxonEnum} from '@/pageComponents/search-order-id/Filters/utils/cuisines';
import {
  type FilterState,
  type TaxonFiltersKeys,
} from '@/pageComponents/search-order-id/Filters/utils/types';
import {safeLocalStorage} from '@/utils/storage';

const KNOWN_FILTERS = new Set([
  'budgetAmount',
  'cuisineOption',
  'deliveryFee',
  'dietaryOptions[]',
  'distance',
  'individualPackaging',
  'keyword',
  'orderMinimum',
  'promotionsTaxonomy',
  'reliabilityRockstar',
  'rewardsLevel',
  'starRating',
  'keyword',
]);

const OMITTED_FILTERS = new Set([
  'address[street]',
  'address[street2]',
  'address[city]',
  'address[state]',
  'address[zip]',
  'address[latitude]',
  'address[longitude]',
  'addressId',
  'addressString',
  'headcount',
  'strategy',
  'eventOn',
  'eventLocalTime',
]);

export const TAXONOMIES = new Set<TaxonFiltersKeys>([
  'businessSizingTaxonomy',
  'businessTypeTaxonomy',
  'cuisineTaxonomy',
  'deiOwnedBusinessTaxonomy',
  'deiOwnedFranchiseTaxonomy',
  'deliveryCapabilitiesTaxonomy',
  'dietaryCapabilitiesTaxonomy',
  'mealTypeTaxonomy',
  'packagingTaxonomy',
  'promotionsTaxonomy',
  'serviceCapabilitiesTaxonomy',
  'suitabilityTaxonomy',
]);

export const transformArrayParam = (selections?: string | string[]): string[] | undefined => {
  if (!selections) {
    return undefined;
  }
  if (!Array.isArray(selections)) {
    return [selections];
  }

  return selections;
};

type BudgetInput = {
  budgetAmount: string;
};

type FiltersForSaving = Omit<
  MarketplaceCatererSearchFilter,
  'cuisineOption' | 'deliveryFee' | 'budgetPerPerson' | 'orderMinimum' | 'taxons'
> &
  TaxonFilterInput & {
    cuisineOption: CuisineTaxonEnum;
    deliveryFee: number;
    budgetAmount: number;
    orderMinimum: number;
  };

const transformBudget = ({budgetAmount}: BudgetInput): MoneyInput | undefined => {
  if (!budgetAmount) {
    return undefined;
  }

  const perPersonSubunits = parseInt(budgetAmount, 10);
  return transformToMoneyType(perPersonSubunits.toString());
};

const transformToMoneyType = (selection?: string): MoneyInput | undefined => {
  if (selection === undefined) {
    return undefined;
  }

  return {subunits: parseInt(selection as string, 10)};
};

const valueOrEmptyString = (value: string | boolean): string => {
  if (value === '0') {
    return value;
  }
  return value ? value.toString() : '';
};

const transformMoneyToValue = (subunits: string): string => {
  const value = parseInt(subunits as string, 10) / 100;

  return Number.isNaN(value) ? '' : value.toString();
};

const cuisineOptionValues = new Set(Object.values(CuisineTaxonEnum));
const ensureCuisineOptionEnumValue = (
  cuisineOption: Maybe<CuisineTaxonEnum>,
): CuisineTaxonEnum | undefined =>
  cuisineOption && cuisineOptionValues.has(cuisineOption) ? cuisineOption : undefined;

const dietaryOptionValues = new Set(Object.values(DietaryOptionEnum));
const ensureDietaryOptionEnumValues = (
  dietaryOptions: DietaryOptionEnum[],
): DietaryOptionEnum[] | undefined => {
  if (!dietaryOptions) return undefined;
  return dietaryOptions.filter(option => dietaryOptionValues.has(option));
};

const transformToTaxonInput = (
  query: ParsedQuery | ParsedUrlQuery,
): SearchTaxonomiesAndTaxonsInput => {
  const taxonFilters: SearchTaxonomiesAndTaxonsInput = Array.from(TAXONOMIES).reduce(
    (filterObj, taxonomy) => {
      const taxonomyValue = query[taxonomy];

      if (taxonomyValue && typeof taxonomyValue === 'string') {
        filterObj[taxonomy] = taxonomyValue.toUpperCase();
      }
      return filterObj;
    },
    {},
  );

  return Object.keys(taxonFilters).length === 0 ? {} : taxonFilters;
};

const filteredStarRating = (query: ParsedQuery | ParsedUrlQuery): CatererSearchStarRating =>
  query?.starRating === 'FOUR_POINT_FIVE'
    ? undefined
    : (query?.starRating as CatererSearchStarRating);

export const extractFilterForSearchQuery = ({
  query,
}: {
  query: ParsedQuery | ParsedUrlQuery;
}): MarketplaceCatererSearchFilter => {
  const cuisineOptionValue = ensureCuisineOptionEnumValue(query.cuisineOption as CuisineTaxonEnum);
  const taxons = transformToTaxonInput(query);

  if (cuisineOptionValue) {
    taxons.cuisineTaxonomy = cuisineOptionValue.toUpperCase() as SearchCuisineTaxonomy;
  }

  const reliabilityRockstar = query.reliabilityRockstar
    ? query.reliabilityRockstar === 'true'
    : undefined;

  return {
    keyword: query.keyword as string,
    individualPackaging: Boolean(query.individualPackaging && query.individualPackaging === 'true'),
    dietaryOptions: ensureDietaryOptionEnumValues(
      transformArrayParam(query['dietaryOptions[]']) as DietaryOptionEnum[],
    ),
    distance: query.distance && parseInt(query.distance as string, 10),
    rewardsLevel: query.rewardsLevel as CatererSearchRewardsLevel,
    starRating: filteredStarRating(query),
    deliveryFee: transformToMoneyType(query.deliveryFee as string),
    budgetPerPerson: transformBudget({
      budgetAmount: query.budgetAmount as string,
    }),
    orderMinimum: transformToMoneyType(query.orderMinimum as string),
    reliabilityRockstar,
    taxonomiesAndTaxons: taxons,
  };
};

export const extractFiltersForSaving = ({query}: {query: ParsedUrlQuery}): FiltersForSaving => {
  return {
    keyword: query?.keyword as string,
    individualPackaging: query?.individualPackaging === 'true' ? true : undefined,
    reliabilityRockstar: query?.reliabilityRockstar === 'true' ? true : undefined,
    dietaryOptions: ensureDietaryOptionEnumValues(
      transformArrayParam(query['dietaryOptions[]']) as DietaryOptionEnum[],
    ),
    cuisineOption: ensureCuisineOptionEnumValue(query?.cuisineOption as CuisineTaxonEnum),
    distance: query?.distance && parseInt(query?.distance as string, 10),
    rewardsLevel: query?.rewardsLevel as CatererSearchRewardsLevel,
    starRating: filteredStarRating(query),
    deliveryFee: transformToMoneyType(query?.deliveryFee as string)?.subunits,
    budgetAmount: transformToMoneyType(query?.budgetAmount as string)?.subunits,
    orderMinimum: transformToMoneyType(query?.orderMinimum as string)?.subunits,
    ...transformToTaxonInput(query),
  };
};

export const extractFilters = (query: ParsedQuery | ParsedUrlQuery): FilterState => {
  // In order of active filter chip preference (same order as in filter sidebar)
  const taxonFilters = transformToTaxonInput(query);
  return {
    keyword: valueOrEmptyString(query.keyword as string),
    reliabilityRockstar: valueOrEmptyString(Boolean(query.reliabilityRockstar === 'true')),
    individualPackaging: valueOrEmptyString(Boolean(query.individualPackaging === 'true')),
    serviceCapabilitiesTaxonomy: taxonFilters.serviceCapabilitiesTaxonomy,
    promotionsTaxonomy: taxonFilters.promotionsTaxonomy,
    cuisineOption: valueOrEmptyString(
      ensureCuisineOptionEnumValue(query.cuisineOption as CuisineTaxonEnum),
    ),
    dietaryOptions: ensureDietaryOptionEnumValues(
      (transformArrayParam(query['dietaryOptions[]']) as DietaryOptionEnum[]) || [],
    ),
    deiOwnedBusinessTaxonomy: taxonFilters.deiOwnedBusinessTaxonomy,
    businessSizingTaxonomy: taxonFilters.businessSizingTaxonomy,
    deliveryFee: transformMoneyToValue(query.deliveryFee as string),
    deliveryCapabilitiesTaxonomy: taxonFilters.deliveryCapabilitiesTaxonomy,
    budgetAmount: transformMoneyToValue(query.budgetAmount as string),
    starRating: valueOrEmptyString(filteredStarRating(query) as string),
    rewardsLevel: valueOrEmptyString(query.rewardsLevel as string),
    distance: valueOrEmptyString(query.distance as string),
    orderMinimum: valueOrEmptyString(query.orderMinimum as string),
    ...transformToTaxonInput(query),
  };
};

export const filterOmittedQueryParams = (query: ParsedQuery | ParsedUrlQuery) => {
  const results = {};
  Object.entries(query).forEach(([key, value]) => {
    if (!OMITTED_FILTERS.has(key) && value !== undefined) {
      results[key] = value;
    }
  });

  return results;
};

export const areFiltersActive = (query: ParsedQuery): boolean => {
  const allFilterParams = Array.from(KNOWN_FILTERS).concat(Array.from(TAXONOMIES));
  return allFilterParams.some(filter => query[filter]);
};

export const isFilterStateCleared = (filterState: Record<string, string | string[]>): boolean => {
  const keys = Object.keys(filterState);
  const hasFilter = keys.some(key => KNOWN_FILTERS.has(key) && filterState[key]?.length);
  if (hasFilter) return false;
  return true;
};

export const isKeywordSearchActive = (query: ParsedUrlQuery): boolean =>
  query.keyword !== undefined;

export const isReliabilityRockstarFilterActive = (query: ParsedUrlQuery): boolean =>
  query.reliabilityRockstar === 'true';

export const isSeasonalSpecialsFilterActive = (query: ParsedUrlQuery): boolean =>
  query.serviceCapabilitiesTaxonomy === SearchServiceCapabilitiesTaxonomy.SeasonalSpecials;

export const isBlackOwnedFilterActive = (query: ParsedUrlQuery): boolean =>
  query.deiOwnedBusinessTaxonomy === DeiOwnedBusinessTaxonomy.BlackOwnedBusiness;

export const isWomenOwnedFilterActive = (query: ParsedUrlQuery): boolean =>
  query.deiOwnedBusinessTaxonomy === DeiOwnedBusinessTaxonomy.WomanOwnedBusiness;

export const getFormattedPrice = (subunits: number) => `$${subunits / 100}`;

const getFormattedRewards = (rewardsLevel: CatererSearchRewardsLevel, t: Translate) => {
  switch (rewardsLevel) {
    case CatererSearchRewardsLevel.Five:
      return t('filters.rewardsLevel.maxActiveTitle', {rewardsLevel: '5'});
    case CatererSearchRewardsLevel.Four:
      return t('filters.rewardsLevel.activeTitle', {rewardsLevel: '4'});
    case CatererSearchRewardsLevel.Three:
      return t('filters.rewardsLevel.activeTitle', {rewardsLevel: '3'});
    case CatererSearchRewardsLevel.Two:
      return t('filters.rewardsLevel.activeTitle', {rewardsLevel: '2'});
    default:
      return t('filters.rewardsLevel.any');
  }
};

const getFormattedStarRating = (starRating: string, t: Translate) => {
  switch (starRating) {
    case 'FIVE':
      return t('filters.lastFilters.starRatingMax', {starRating: '5'});
    case 'FOUR':
      return t('filters.lastFilters.starRating', {starRating: '4'});
    case 'THREE':
      return t('filters.lastFilters.starRating', {starRating: '3'});
    default:
      return t('filters.starRating.any');
  }
};

export const parseSavedQueryString = (t: Translate, qs: string): string[] => {
  const parsed = queryString.parse(qs);
  const queryParams = extractFilterForSearchQuery({query: parsed});

  const lastFiltersArray = [];
  if (queryParams?.budgetPerPerson?.subunits)
    lastFiltersArray.push(
      t('filters.lastFilters.budgetPerPerson', {
        budgetPerPerson: getFormattedPrice(queryParams.budgetPerPerson.subunits),
      }),
    );

  if (queryParams?.taxonomiesAndTaxons?.cuisineTaxonomy) {
    const cuisineTaxon = t(
      `filters.taxons.cuisineTaxonomy.${queryParams.taxonomiesAndTaxons.cuisineTaxonomy}.label`,
    );
    lastFiltersArray.push(t(`filters.lastFilters.cuisine`, {cuisine: cuisineTaxon}));
  }

  if (queryParams?.deliveryFee?.subunits)
    lastFiltersArray.push(
      t('filters.lastFilters.deliveryFee', {
        deliveryFee: getFormattedPrice(queryParams.deliveryFee.subunits),
      }),
    );
  if (queryParams?.dietaryOptions) {
    queryParams.dietaryOptions.forEach(option => {
      const formattedOption = t(`filters.dietaryOptions.${option}`);
      lastFiltersArray.push(t('filters.lastFilters.dietary', {option: formattedOption}));
    });
  }
  if (queryParams?.distance)
    lastFiltersArray.push(
      t('filters.lastFilters.distance', {distanceInMiles: queryParams.distance}),
    );
  if (queryParams?.reliabilityRockstar)
    lastFiltersArray.push(t('filters.lastFilters.reliabilityRockstar'));
  if (queryParams?.individualPackaging)
    lastFiltersArray.push(t('filters.lastFilters.individualPackaging'));
  if (queryParams?.keyword)
    lastFiltersArray.push(t('filters.lastFilters.keywordSearch', {keyword: queryParams.keyword}));
  if (queryParams?.orderMinimum?.subunits)
    lastFiltersArray.push(
      t('filters.lastFilters.orderMinimum', {
        minimum: getFormattedPrice(queryParams.orderMinimum.subunits),
      }),
    );
  if (queryParams?.rewardsLevel)
    lastFiltersArray.push(getFormattedRewards(queryParams.rewardsLevel, t));
  if (queryParams?.starRating)
    lastFiltersArray.push(getFormattedStarRating(queryParams.starRating, t));
  if (queryParams?.taxonomiesAndTaxons?.deiOwnedBusinessTaxonomy)
    lastFiltersArray.push(
      t(
        `filters.taxons.deiOwnedBusinessTaxonomy.${queryParams?.taxonomiesAndTaxons?.deiOwnedBusinessTaxonomy}.label`,
      ),
    );
  if (queryParams?.taxonomiesAndTaxons?.serviceCapabilitiesTaxonomy)
    lastFiltersArray.push(
      t(
        `filters.taxons.serviceCapabilitiesTaxonomy.${queryParams?.taxonomiesAndTaxons?.serviceCapabilitiesTaxonomy}.label`,
      ),
    );
  if (queryParams?.taxonomiesAndTaxons?.businessSizingTaxonomy)
    lastFiltersArray.push(
      t(
        `filters.taxons.businessSizingTaxonomy.${queryParams?.taxonomiesAndTaxons?.businessSizingTaxonomy}.label`,
      ),
    );
  if (queryParams?.taxonomiesAndTaxons?.deliveryCapabilitiesTaxonomy)
    lastFiltersArray.push(
      t(
        `filters.taxons.deliveryCapabilitiesTaxonomy.${queryParams?.taxonomiesAndTaxons?.deliveryCapabilitiesTaxonomy}.label`,
      ),
    );
  if (queryParams?.taxonomiesAndTaxons?.promotionsTaxonomy)
    lastFiltersArray.push(
      t(
        `filters.taxons.promotionsTaxonomy.${queryParams?.taxonomiesAndTaxons?.promotionsTaxonomy}.label`,
      ),
    );

  return lastFiltersArray;
};

export const getSavedFilters = (fullAddress: string) => safeLocalStorage.getItem(fullAddress);
