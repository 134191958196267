import {type CatererSearchOrderType, type TaxonFilterInput} from '@/graphql/types';

export type Filter = {
  onChange: (x: string) => void;
  value: string;
  strategy?: string;
};

export type LastMinuteOrdersFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type PromotionsFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type ReliabilityRockstarFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type IndividualPackagingFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type PackagingTypeFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type DietaryOptionsFilterProps = {
  value: string[];
  onChange: (newValue: string[]) => void;
};

export type CuisineOptionFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type DeliveryFeeFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

type BudgetFilterPayload = {
  budgetAmount: string;
};

export type BudgetFilterProps = {
  value: BudgetFilterPayload;
  onChange: (newValue: BudgetFilterPayload) => void;
};

export type OrderMinimumFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
  orderType: 'DELIVERY' | 'TAKEOUT';
};

export type RewardsLevelFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type StarRatingFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type DistanceFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type GroupOrderingFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type NewBusinessFilterProps = {
  value: string;
  onChange: (newValue: string) => void;
};

export type MoreFilters = {
  distance: string;
  orderMinimum: string;
  rewardsLevel: string;
  starRating: string;
};
export type MoreFiltersProps = {
  onChange: (filters: MoreFilters) => void;
  value: MoreFilters;
  onClear: () => void;
  orderType: CatererSearchOrderType;
};

export type TaxonFiltersKeys = keyof TaxonFilterInput;
export type TaxonFilters = Partial<Record<TaxonFiltersKeys, string>>;

export type FilterState = {
  budgetAmount: string;
  cuisineOption: string;
  deliveryFee: string;
  dietaryOptions: string[];
  distance: string;
  individualPackaging: string;
  keyword: string;
  orderMinimum: string;
  reliabilityRockstar: string;
  rewardsLevel: string;
  starRating: string;
} & TaxonFilters;

export type FilterQueryParams = {
  keyword?: string;
  reliabilityRockstar?: string;
  individualPackaging?: string;
  'dietaryOptions[]'?: string[];
  cuisineOption?: string;
  distance?: string;
  rewardsLevel?: string;
  starRating?: string;
  deliveryFee?: string;
  budgetAmount?: string;
  orderMinimum?: string;
} & TaxonFilters;

export enum FiltersUpdateSource {
  Banner = 'banner',
  BrandSuggestion = 'brand',
  Budget = 'price_per_person',
  CuisineOption = 'food_type',
  CuisinePills = 'cuisine_pills',
  CuisineSuggestion = 'cuisine_suggestion',
  Dealicious = 'dealicious',
  Debounce = 'debounce_called',
  Dei = 'dei',
  DeliveryFee = 'delivery_fee',
  DietaryOptions = 'menu_dietary_offerings',
  GroupOrdering = 'group_ordering',
  HolidayGifting = 'holiday_gifting',
  IndividualPackaging = 'packaging',
  IndependentlyOwned = 'independent',
  Keyword = 'query_string',
  LastMinuteOrders = 'last_minute_orders',
  LeftNavActivePills = 'left_nav_active_pills',
  LeftNavDistance = 'distance',
  LeftNavOrderMinimum = 'order_minimum',
  LeftNavRewards = 'rewards',
  LeftNavStarRating = 'star_rating',
  MobileFilter = 'mobile_filter',
  MoreFilters = 'more_filters',
  NewBusiness = 'new_business',
  OrderType = 'order_type',
  OtherFilters = 'other_filters',
  ReactivateLastFilters = 'reactivate_last_filters',
  ReliabilityRockstar = 'reliability_rockstar',
  SeasonalSpecials = 'seasonal_specials',
  SmallBusiness = 'business_size',
  TwoXRewards = 'two_x_rewards',
}

export type KeywordSearchCuisine = {
  name: string;
  value: string;
};

export type KeywordSearchOption = {
  group: string;
  name: string;
  value: string;
};
