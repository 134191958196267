import {type CatererSearchAddress} from '@/graphql/types';
import {VANITY_CATERER_SEARCH_PATH} from '@/paths';
import compilePath from '@/utils/compilePath';

type CanonicalLinkProps = {
  address?: CatererSearchAddress;
};

const canonicalPath = ({address}: CanonicalLinkProps): string => {
  if (address?.city && address?.state) {
    return compilePath(VANITY_CATERER_SEARCH_PATH, {
      state: urlFormat(address.state),
      city: urlFormat(address.city),
    });
  }

  return '';
};

const urlFormat = (value: string): string => {
  return value.toLowerCase().replace(' ', '-');
};

export default canonicalPath;
