import {useCallback, useMemo, useState} from 'react';
import {useCookies} from 'react-cookie';
import {useLatest} from 'react-use';
import {type FetchResult} from '@apollo/client';
import {useRouter} from 'next/router';
import useTranslation from 'next-translate/useTranslation';

import {
  type CateringMenuFulfillmentDetailCreateMutation,
  type CateringMenuFulfillmentDetailUpdateMutation,
  FulfillmentDetailStrategy,
  useCateringMenuFulfillmentDetailCreateMutation,
  useCateringMenuFulfillmentDetailUpdateMutation,
} from '@/graphql/types';
import useOrderingExperience from '@/hooks/useOrderingExperience';
import {STORE_FLASH_MESSAGES} from '../CookieMessages';
import {
  GlobalFulfillmentDetailMutationContext,
  type GlobalFulfillmentDetailMutationContextType,
  useGlobalFulfillmentDetail,
} from './GlobalFulfillmentDetailProvider';

type Props = React.PropsWithChildren<{
  catererUrlId: string;
  consumerCartId?: string;
}>;

const MenuFulfillmentDetailMutationProvider: React.FC<Props> = ({
  children,
  catererUrlId,
  consumerCartId,
}) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const {fulfillmentDetail, fallbackAddress} = useGlobalFulfillmentDetail();
  const {replace: routerReplace} = useRouter();
  const routerReplaceRef = useLatest(routerReplace);
  const [, setCookie] = useCookies([STORE_FLASH_MESSAGES]);
  const {t} = useTranslation('common');
  const {experienceForCustomerMessages: experience} = useOrderingExperience();

  const [createFulfillmentDetailMutation] = useCateringMenuFulfillmentDetailCreateMutation();
  const [updateFulfillmentDetailMutation] = useCateringMenuFulfillmentDetailUpdateMutation();

  const fulfillmentDetailId = fulfillmentDetail?.id;
  const updateFulfillmentDetail = useCallback<
    GlobalFulfillmentDetailMutationContextType['updateFulfillmentDetail']
  >(
    async input => {
      let data:
        | (CateringMenuFulfillmentDetailCreateMutation &
            CateringMenuFulfillmentDetailUpdateMutation)
        | null
        | undefined;
      let errors: FetchResult['errors'];

      if (fulfillmentDetailId)
        ({data, errors} = await updateFulfillmentDetailMutation({
          variables: {
            catererUrlId,
            consumerCartId,
            experience,
            id: fulfillmentDetailId,
            input,
          },
        }));
      else
        ({data, errors} = await createFulfillmentDetailMutation({
          variables: {
            input: {
              ...input,
              address: input.addressId ? input.address : input.address || fallbackAddress,
              strategy: input.strategy || FulfillmentDetailStrategy.Delivery,
            },
            catererUrlId,
            experience,
          },
        }));

      const fulfillmentDetail =
        data?.fulfillmentDetailCreate?.fulfillmentDetail ||
        data?.fulfillmentDetailUpdate?.fulfillmentDetail;
      const userErrors =
        data?.fulfillmentDetailCreate?.userErrors || data?.fulfillmentDetailUpdate?.userErrors;

      if (userErrors) {
        userErrors.forEach(dataError => {
          switch (dataError.__typename) {
            case 'EntityLocked':
            case 'EntityNotFound': {
              setCookie(STORE_FLASH_MESSAGES, `error=${t('errors.entityNotFound')}`);

              // strip fulfillmentDetailId query param
              const url = new URL(window.location.href, window.location.origin);
              url.searchParams.delete('fulfillmentDetailId');
              routerReplaceRef.current(url);

              throw new Error(dataError.__typename);
            }
            case 'ValidationError':
              setErrorMessage(dataError.message);
              throw new Error(`${dataError.__typename}: ${dataError.message}`);
            default:
              throw new Error(
                `Unknown error received: ${(dataError as {__typename: string}).__typename}`,
              );
          }
        });
      }

      // Update URL with fulfillmentDetailId
      // WARN: This is duplicated code from the MenuPageProvider
      if (!fulfillmentDetailId && fulfillmentDetail?.id) {
        const url = new URL(window.location.href, window.location.origin);
        url.searchParams.set('fulfillmentDetailId', fulfillmentDetail.id);
        routerReplaceRef.current(url);
      }

      return {
        fulfillmentDetail: fulfillmentDetail || null,
        userErrors,
        errors,
      };
    },
    [
      catererUrlId,
      consumerCartId,
      createFulfillmentDetailMutation,
      experience,
      fallbackAddress,
      fulfillmentDetailId,
      routerReplaceRef,
      setCookie,
      t,
      updateFulfillmentDetailMutation,
    ],
  );

  return (
    <GlobalFulfillmentDetailMutationContext.Provider
      value={useMemo(
        () => ({errorMessage, updateFulfillmentDetail}),
        [errorMessage, updateFulfillmentDetail],
      )}
    >
      {children}
    </GlobalFulfillmentDetailMutationContext.Provider>
  );
};

export default MenuFulfillmentDetailMutationProvider;
