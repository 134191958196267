/** @type {import('next-translate').I18nConfig} */
module.exports = {
  locales: ['en'],
  defaultLocale: 'en',
  pages: {
    '*': ['common', 'app-bar'],
    '/': ['home', 'home-contentful'],
    '/catering/[catererUrlId]/[[...date]]': ['catering-menu', 'recommended-orders', 'promotions'],
    '/catering/eater/[catererUrlId]/[[...date]]': ['catering-menu'],
    '/catering/pvt/[catererUrlId]/[[...date]]': ['catering-menu', 'recommended-orders'],
    '/catering/search/[orderId]': ['search', 'group-polls', 'recommended-orders', 'promotions'],
    '/checkout/[consumerCartId]': ['checkout', 'customer-messages', 'payment-methods'],
    '/expense-reporting': ['expense-reporting'],
    '/ez-rewards': ['ez-rewards'],
    '/invitations/[consumerCartInvitationId]/accept-invitation/[accessCode]': ['invitations'],
    '/my-account/addresses': ['address-book'],
    '/my-account/addresses/[addressId]': ['address-show'],
    '/my-account/addresses/[addressId]/edit': ['address-form'],
    '/my-account/addresses/new': ['address-form'],
    '/my-account/connections': ['connections'],
    '/my-account/group-polls': ['group-polls', 'common'],
    '/my-account/group-polls/[groupPollId]': ['group-polls', 'polling'],
    '/my-account/orders': ['orders', 'orders-calendar', 'view-order'],
    '/my-account/orders/[orderId]': ['view-order'],
    '/my-account/orders/[orderId]/review': ['review-order'],
    '/my-account/orders/calendar-view': ['orders-calendar'],
    '/my-account/payment-methods': ['payment-methods'],
    '/my-account/personal-information': ['personal-information'],
    '/my-account/personal-information/change-password': ['personal-information'],
    '/my-account/receipts': ['receipts', 'orders-calendar', 'common'],
    '/my-account/reviews': ['reviews'],
    '/my-account/saved-caterers': ['saved-caterers', 'search'],
    '/my-account/suggest-caterer': ['suggest-caterer'],
    '/poll/[pollUuid]': ['polling'],
    '/refer-a-friend': ['refer-a-friend'],
    '/start-new-order': ['start-new-order', 'search', 'recommended-orders'],
  },
  logBuild: process.env.NODE_ENV !== 'production',
};
