// 👋
// This file contains a mapping of experiments to their feature flag.
//
// By pairing an experiment to a feature flag it will force
// the <ExperimentExperience /> component to act as if the user
// is in the "control" group when that feature flag is disabled
//
// This is optional, experiments that are not gated behind a
// feature flag will work as normal without an entry in this object!
//

import Experiments from './Experiments';
import FeatureFlags from './FeatureFlags';

export type ExperimentsConfig = Partial<Record<Experiments, FeatureFlags>>;

const experimentsConfig: ExperimentsConfig = {
  // add experimentName: featureFlagName mapping here, e.g.,
  // [Experiments.EXP_NAME]: FeatureFlags.FF_NAME
  [Experiments.TypeaheadSearch]: FeatureFlags.BrandNamesInSearchTypeahead,
  [Experiments.NewCheckout]: FeatureFlags.NewCheckout,
  [Experiments.SavedCaterersCarouselOnSearch]: FeatureFlags.SavedCaterersCarouselOnSearch,
  [Experiments.EzRewardsForSavedCaterer]: FeatureFlags.EzRewardsForSavedCaterer,
  [Experiments.SearchFilterRedesignV2]: FeatureFlags.SearchFilterRedesign,
  [Experiments.WorkflowHubUpdatesV2]: FeatureFlags.WorkflowHubUpdatesV2,
  [Experiments.RemoveWFHSearchBarAndRecentAddresses]:
    FeatureFlags.RemoveWFHSearchBarAndRecentAddresses,
  [Experiments.WFHJumpBackIn]: FeatureFlags.WFHJumpBackIn,
  [Experiments.OrderRecommendationsMenuPageOverhaul]:
    FeatureFlags.OrderRecommendationsMenuPageOverhaul,
  [Experiments.DraftCartStyleUpdates]: FeatureFlags.DraftCartStyleUpdates,
  [Experiments.PreviouslyEngagedCPs]: FeatureFlags.PreviouslyEngagedCPs,
  [Experiments.AppBarStepThrough]: FeatureFlags.AppBarStepThrough,
};

export default experimentsConfig;
