import {useCallback, useEffect, useState} from 'react';

import {
  MOBILE_VIEW_CART_BUTTON_HIDDEN,
  MOBILE_VIEW_CART_BUTTON_SHOWN,
} from '@/components/EzRewardsPromo/constants';
import {
  DESKTOP_TABLET_BOTTOM_POSITION,
  DESKTOP_TABLET_RIGHT_POSITION,
  DESKTOP_TABLET_RIGHT_POSITION_MENU_PAGE,
  MOBILE_BOTTOM_POSITION,
  MOBILE_MENU_BOTTOM_POSITION,
  MOBILE_RIGHT_POSITION,
} from '@/components/EzRewardsPromo/styles';
import {useStoreNextRouter} from '@/components/StoreNextRouter';
import useIsMobileCheck from './useIsMobileCheck';

const usePromoToastPositioning = () => {
  const {isOnMenuPage} = useStoreNextRouter();
  const isMobile = useIsMobileCheck();

  const calculateRewardsBannerPosition = useCallback(
    ({mobileViewCartVisible = false} = {}) => {
      if (isMobile) {
        return {
          right: MOBILE_RIGHT_POSITION,
          bottom:
            isOnMenuPage && mobileViewCartVisible
              ? MOBILE_MENU_BOTTOM_POSITION
              : MOBILE_BOTTOM_POSITION,
        };
      }

      return {
        right: isOnMenuPage
          ? DESKTOP_TABLET_RIGHT_POSITION_MENU_PAGE + DESKTOP_TABLET_RIGHT_POSITION
          : DESKTOP_TABLET_RIGHT_POSITION,
        bottom: DESKTOP_TABLET_BOTTOM_POSITION,
      };
    },
    [isMobile, isOnMenuPage],
  );

  const [bannerPosition, setBannerPosition] = useState(calculateRewardsBannerPosition());

  useEffect(() => {
    setBannerPosition(calculateRewardsBannerPosition());
  }, [isMobile, calculateRewardsBannerPosition]);

  useEffect(() => {
    const handleMessage = (event: {data: {message: string}}) => {
      const {message} = event.data;
      if (message) {
        switch (message) {
          case MOBILE_VIEW_CART_BUTTON_SHOWN:
            setBannerPosition(calculateRewardsBannerPosition({mobileViewCartVisible: true}));
            break;
          case MOBILE_VIEW_CART_BUTTON_HIDDEN:
            setBannerPosition(calculateRewardsBannerPosition());
            break;
        }
      }
    };

    window.addEventListener('message', handleMessage, false);
    return () => window.removeEventListener('message', handleMessage, false);
  }, [calculateRewardsBannerPosition]);

  return {bannerPosition};
};

export default usePromoToastPositioning;
