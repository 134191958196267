import type React from 'react';
import {createContext, useEffect, useMemo} from 'react';
import {datadogRum} from '@datadog/browser-rum';
import {createTracker, type ITrackedProperty} from '@ezcater/tracker-js';
import getConfig from 'next/config';

import {eventDictionary, experimentEventDictionary} from '@/events';
import FeatureFlags from '@/FeatureFlags';
import useFeatureFlag from '@/hooks/useFeatureFlag';
import useIdentity from '@/hooks/useIdentity';
import {gtm} from '@/utils';
import {getTrackConversionPayload} from '@/utils/rudderstack/conversionEvents';

type DeepWriteable<T> = {-readonly [P in keyof T]: DeepWriteable<T[P]>};
type AtLeastOne<T, U = {[K in keyof T]: Pick<T, K>}> = Partial<T> & U[keyof U];

type EventDictionary = DeepWriteable<typeof eventDictionary>;
export type TrackingEventName = keyof EventDictionary['events'];
type TrackerOptions = Parameters<typeof createTracker>[1];
type TrackerTypeAtom = 'string' | 'number' | 'boolean' | 'null';
type TrackerType = TrackerTypeAtom | TrackerTypeAtom[];
type TrackerTypeMap<T> = T extends 'string'
  ? string
  : T extends 'number'
    ? number
    : T extends 'boolean'
      ? boolean
      : T extends 'null'
        ? null
        : T;
type TrackingPropertiesValues<T extends {type: TrackerType}> = T['type'] extends TrackerType[]
  ? T['type'] extends (infer U)[]
    ? TrackerTypeMap<U>
    : never
  : TrackerTypeMap<T['type']>;
type TrackingProperties<T extends Record<string, {type: TrackerType; [key: string]: any}>> = {
  [K in keyof T]: TrackingPropertiesValues<T[K]>;
};
export type TrackingPropertiesFor<Event extends keyof EventDictionary['events']> =
  EventDictionary['events'][Event] extends {properties: object}
    ? EventDictionary['events'][Event]['properties'] extends AtLeastOne<
        EventDictionary['events'][Event]['properties']
      >
      ? TrackingProperties<EventDictionary['events'][Event]['properties']>
      : never
    : never;
export type TrackingFunction = <T extends TrackingEventName>(
  eventName: T,
  properties?: TrackingPropertiesFor<T>,
) => Promise<boolean>;

type TrackPageViewPageProperties = {
  path: string;
  url: string;
};

type RudderstackIntegrationOpts = {
  All?: boolean;
  [index: string]: boolean | undefined | RudderstackApiObject;
};

export type RudderstackApiObject = {
  [index: string]:
    | string
    | number
    | boolean
    | RudderstackApiObject
    | null
    | Date
    | (string | number | boolean | null | RudderstackApiObject)[]
    | undefined;
};

export type RudderstackApiOptions = {
  integrations?: RudderstackIntegrationOpts;
  anonymousId?: string;
  originalTimestamp?: string;
  [index: string]:
    | string
    | number
    | boolean
    | RudderstackApiObject
    | null
    | Date
    | (string | number | boolean | RudderstackApiObject)[]
    | undefined;
};

const {
  trackingHost,
  rudderstackDataPlaneUrl,
  rudderstackWriteKey,
  rudderstackConfigUrl,
  rudderstackSDKBaseUrl,
} = getConfig()[typeof window === 'undefined' ? 'serverRuntimeConfig' : 'publicRuntimeConfig'];

export type TrackerContextType = {
  track: TrackingFunction;
  trackExposure: (experiment: string, variant: string) => void;
  trackClick: (
    elementName: string,
    overrides?: Partial<TrackingPropertiesFor<'ui element clicked'>>,
  ) => void;
  trackPageView: (
    pageCategory: string,
    pageName: string,
    pageProperties?: TrackPageViewPageProperties,
  ) => void;
  trackIdentify: (userId: string) => void;
  rudderTrack: (
    eventName: string,
    properties?: RudderstackApiObject,
    apiOptions?: RudderstackApiOptions,
  ) => void;
  rudderTrackConversion: (
    conversionName: string,
    properties: RudderstackApiObject,
    traits: RudderstackApiOptions,
  ) => void;
  trackerOptions: TrackerOptions;
};

export const TrackingContext = createContext<TrackerContextType | null>(null);

const TrackingProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const {data} = useIdentity();
  const rudderFFEnabled = true; // useFeatureFlag(FeatureFlags.RudderstackEnabled);
  const rudderstackFFDisabledForExperiments = useFeatureFlag(
    FeatureFlags.RudderstackDisabledForExperiments,
  );
  const trackerOptions = useMemo<TrackerOptions>(
    () => ({
      apiUrl: trackingHost,
      ssr: typeof window === 'undefined',
      rudderstackDataPlaneUrl,
      rudderstackWriteKey,
      rudderstackFFEnabled: rudderFFEnabled,
      rudderstackConfig: {
        configUrl: rudderstackConfigUrl,
        pluginsSDKBaseURL: `${rudderstackSDKBaseUrl}/v3/modern/plugins`,
        destSDKBaseURL: `${rudderstackSDKBaseUrl}/v3/modern/js-integrations`,
      },
    }),
    [rudderFFEnabled],
  );

  const client = useMemo(
    () => createTracker(eventDictionary as EventDictionary, trackerOptions),
    [trackerOptions],
  );
  const experimentsClient = useMemo(
    () =>
      createTracker(experimentEventDictionary, {
        ...trackerOptions,
        rudderstackFFEnabled: !rudderstackFFDisabledForExperiments,
      }),
    [trackerOptions, rudderstackFFDisabledForExperiments],
  );

  const providerValue = useMemo(
    () =>
      ({
        track(eventName, properties) {
          return client.track(eventName, properties as unknown as ITrackedProperty);
        },
        trackExposure(experiment, variant) {
          experimentsClient.track('exposure', {
            experiment_test: experiment,
            experiment_result: variant,
            user_agent: window.navigator.userAgent,
          });
          window.FS?.event?.('experiment-exposure', {experiment, variant});
          gtm.dataLayer({
            experiment: `${experiment} | ${variant}`,
          });
          datadogRum.addAction('experiment-exposure', {
            experiment,
            variant,
          });
        },
        trackClick(elementName, overrides = {}) {
          if (!elementName && __DEV__) {
            console.error('trackClick called without elementName provided');
          }

          client.track('ui element clicked', {
            url: window.location.href,
            page: window.location.pathname,
            ui_element_name: elementName,
            user_agent: window.navigator.userAgent,
            misc_json: null,
            ...overrides,
          });
        },
        trackPageView(pageCategory, pageName, pageProperties) {
          client.trackPageView(pageCategory, pageName, pageProperties);
        },
        trackIdentify(userId) {
          client.trackIdentify(userId);
        },
        rudderTrack(eventName, properties, apiOptions) {
          client.rudderTrack(eventName, properties, apiOptions);
        },
        rudderTrackConversion(conversionName, properties, traits) {
          client.rudderTrack(...getTrackConversionPayload(conversionName, properties, traits));
        },
        trackerOptions,
      }) satisfies TrackerContextType,
    [client, experimentsClient, trackerOptions],
  );

  useEffect(() => {
    if (data?.currentUserTrackingId) {
      client.setTrackingId(data.currentUserTrackingId);
      experimentsClient.setTrackingId(data.currentUserTrackingId);
      client.trackIdentify(data.currentUserTrackingId);
    }
  }, [client, experimentsClient, data?.currentUserTrackingId]);

  return <TrackingContext.Provider value={providerValue}>{children}</TrackingContext.Provider>;
};

export default TrackingProvider;
