import {createContext, useEffect, useMemo} from 'react';
import {useRouter} from 'next/router';

import {useSearchPageOrderQuery} from '@/graphql/types';
import {type SearchPageObject} from '@/pageUtils/caterer-search/cateringSearch';
import {transformSearchDataIntoSearchPageObject} from '@/pageUtils/caterer-search/cateringSearch';
import {extractRedirectableError} from '@/utils/errors';

export const SearchPageContext = createContext<SearchPageObject | null>(null);

const SearchPageProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const router = useRouter();
  const orderId = router.query.orderId as string;
  const trackOrderUsage = router.query.trackOrderUsage === '0' ? {trackOrderUsage: false} : {};

  const {data, loading, error, refetch} = useSearchPageOrderQuery({
    variables: {orderId, ...trackOrderUsage},
    errorPolicy: 'all',
  });

  const providerValue = useMemo(
    () => transformSearchDataIntoSearchPageObject({data, refetch}),
    [data, refetch],
  );

  const redirectableError = extractRedirectableError({error});

  useEffect(() => {
    if (redirectableError) window.location.href = redirectableError.path;
  }, [redirectableError]);

  if (redirectableError) {
    return null;
  }

  if (loading) return <div>Loading...</div>;

  return <SearchPageContext.Provider value={providerValue}>{children}</SearchPageContext.Provider>;
};

export default SearchPageProvider;
