import {createContext, useContext, useMemo} from 'react';
import {useRouter} from 'next/router';

export type StoreNextRouter = {
  isOnMenuPage: boolean;
  isOnSearchPage: boolean;
  isOnLoggedOutLandingPage: boolean;
};

export const StoreNextRouterContext = createContext<StoreNextRouter>({
  isOnMenuPage: false,
  isOnSearchPage: false,
  isOnLoggedOutLandingPage: false,
});

export const useStoreNextRouter = () => useContext(StoreNextRouterContext);

const menuPagePathnames = new Set([
  '/catering/[catererUrlId]/[[...date]]',
  '/catering/eater/[catererUrlId]/[[...date]]',
  '/catering/pvt/[catererUrlId]/[[...date]]',
]);

const StoreNextProvider: React.FC<React.PropsWithChildren<unknown>> = ({children}) => {
  const router = useRouter();

  return (
    <StoreNextRouterContext.Provider
      value={useMemo(
        () => ({
          isOnMenuPage: menuPagePathnames.has(router.pathname),
          isOnSearchPage: router.pathname === '/catering/search/[orderId]',
          isOnLoggedOutLandingPage: router.pathname === '/',
        }),
        [router.pathname],
      )}
    >
      {children}
    </StoreNextRouterContext.Provider>
  );
};

export default StoreNextProvider;
